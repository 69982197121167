import PropTypes from "prop-types";

export const AtomIcon = ({ width = 16, height = 18, fill = "#8392AB" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5795 4.1475C15.1445 3.46875 14.102 2.77725 11.6045 3.336C11.414 3.37875 11.2182 3.42975 11.0202 3.48525C10.3992 1.434 9.38525 0 8 0C6.7205 0 5.66525 1.239 4.9835 3.492C3.01775 2.9385 1.19675 2.934 0.421249 4.1475C-0.0137514 4.827 -0.206501 6.06375 1.3475 8.097C1.57625 8.39625 1.83725 8.69775 2.11775 9C1.83725 9.30225 1.57625 9.60375 1.3475 9.90375C-0.206501 11.937 -0.0137514 13.1738 0.421249 13.8533C0.854749 14.5305 1.652 14.8718 2.7485 14.8718C3.39275 14.8718 4.14275 14.751 4.98125 14.5148C5.57225 16.4775 6.55025 18 8 18C9.38525 18 10.3992 16.566 11.021 14.514C11.219 14.5695 11.414 14.6205 11.6052 14.6633C13.0212 14.9805 14.801 15.0712 15.5795 13.8517C16.3242 12.6892 15.7047 10.986 13.865 9.02025C15.4437 7.33575 16.4367 5.48625 15.5795 4.1475ZM14.3165 4.956C14.6472 5.47275 13.8717 7.101 11.75 8.9865C11.7492 7.5975 11.624 6.204 11.378 4.94325C12.0747 4.7445 13.9272 4.34925 14.3165 4.956ZM4.2635 9C5.87375 7.5735 7.95725 6.261 9.941 5.44725C10.3407 7.61775 10.3647 10.2495 9.941 12.5527C7.95875 11.7397 5.87525 10.4272 4.2635 9ZM8 1.5C8.492 1.5 9.122 2.4075 9.59 3.96975C9.07025 4.17525 8.537 4.41825 8 4.689C7.48475 4.43025 6.947 4.188 6.4025 3.97425C6.9395 2.18025 7.631 1.5 8 1.5ZM1.68425 4.956C2.00675 4.4505 3.8045 4.46325 6.39125 5.58825C5.213 6.309 4.16825 7.074 3.1895 7.95075C2.633 7.34625 1.25 5.6325 1.68425 4.956ZM1.6835 13.044C1.25075 12.3675 2.6315 10.6553 3.1895 10.0493C3.5495 10.3718 3.9335 10.6898 4.33775 11.001C4.39775 11.6813 4.4915 12.375 4.62425 13.0522C2.82425 13.5675 1.88225 13.3523 1.6835 13.044ZM8 16.5C7.3865 16.5 6.43175 14.9685 5.9885 12.1642C7.1915 12.9337 8.42225 13.569 9.59 14.0303C9.122 15.5925 8.492 16.5 8 16.5ZM14.3157 13.044C13.9287 13.653 12.0725 13.2547 11.378 13.0575C11.5055 12.4027 11.597 11.7113 11.6592 11.0048C12.0432 10.7085 12.4242 10.3943 12.7917 10.065C14.2655 11.649 14.5347 12.7028 14.3157 13.044Z"
        fill={fill}
      />
      <path
        d="M8 9.75C8.41421 9.75 8.75 9.41421 8.75 9C8.75 8.58579 8.41421 8.25 8 8.25C7.58579 8.25 7.25 8.58579 7.25 9C7.25 9.41421 7.58579 9.75 8 9.75Z"
        fill={fill}
      />
    </svg>
  );
};

AtomIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
