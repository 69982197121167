import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createBanner: build.mutation({
      query: (arg) => ({
        url: "/private/banner/v1",
        method: "POST",
        body: arg,
      }),
    }),
    getBanners: build.query({
      query: (arg) => {
        const { page, size, sort, type, title } = arg;
        return {
          url: `/private/banner/v1/admin?page=${page}&size=${size}&sort=${sort}&type=${type}&title=${title}`,
        };
      },
    }),
    uploadBannerImage: build.mutation({
      query: (arg) => ({
        url: "/private/banner/v1/upload",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        body: arg,
        formData: true,
      }),
    }),
    updateBanner: build.mutation({
      query: (arg) => ({
        url: "/private/banner/v1",
        method: "PUT",
        body: arg,
      }),
    }),
    deleteBanner: build.mutation({
      query: (arg) => ({
        url: `/private/banner/v1/${arg.id}`,
        method: "DELETE",
      }),
    }),
    getBannerForTeacher: build.query({
      query: (arg) => {
        const { page, size } = arg;
        return {
          url: `/private/banner/v1?page${page}&size=${size}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateBannerMutation,
  useLazyGetBannersQuery,
  useUploadBannerImageMutation,
  useUpdateBannerMutation,
  useDeleteBannerMutation,
  useLazyGetBannerForTeacherQuery,
} = injectedRtkApi;
