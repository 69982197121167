export default () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6_136)">
        <path
          d="M15.9993 21.2019C17.7782 21.2019 19.4223 20.7183 20.8807 19.9266C24.9524 20.3677 28.1327 23.8196 28.1327 28.0019V29.4333L28.0471 29.4608L28.0458 29.4612C28.0451 29.4614 28.0441 29.4617 28.043 29.4621C27.8761 29.5144 23.7248 30.8019 15.9993 30.8019C8.27563 30.8019 4.12445 29.515 3.95578 29.4621L3.86602 29.4333V28.0019C3.86602 23.8196 7.04632 20.3677 11.118 19.9266C12.5764 20.7183 14.2205 21.2019 15.9993 21.2019Z"
          stroke="#ADB5BD"
          strokeWidth="2.4"
        />
        <path
          d="M22.8 8C22.8 9.85838 22.0368 11.932 20.7529 13.535C19.4701 15.1365 17.7849 16.1333 16 16.1333C14.2151 16.1333 12.5299 15.1365 11.2471 13.535C9.96316 11.932 9.2 9.85838 9.2 8C9.2 4.25074 12.2507 1.2 16 1.2C19.7493 1.2 22.8 4.25074 22.8 8Z"
          stroke="#ADB5BD"
          strokeWidth="2.4"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_136">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
