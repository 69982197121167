import * as React from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Button, styled } from "@mui/material";
import { toast } from "react-toastify";
import { useUploadBannerImageMutation } from "shared/redux/endpoints/banner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "min(90%, 80vh)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const placeholder =
  "https://s3-alpha-sig.figma.com/img/bdb6/ed6c/fd393c4a1fc3d21040c741a3f1a5da59?Expires=1714953600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UPNi4k6HJ8paw-LnQb8zDCpPrcWPBBPRE-sg11ZJjYpbHZVBgLfEmodPfSNcHlIaf0yHDHQ67XEpWBUwzSV5IgYrkOBI9B8KDPNeJZzdClc2JCJhkDyIdcQYg5M5Cs6aFMObXF0kmD~ffzJW8nGyEW9oxKmXg2xXo1S9y3IS8LU61FGqn6W0bSlDvN8yrv7RxDjMOkQ-EQJ3TiuKC9hhKYqaltwSxzwronMcnZJ6RRv5HI329l-vc9ylGPCONJkxZE3hxDXFgy7cJXzE6vKSQKkNR480zwbyky7-XZZ8VUJMMKrMFfKsmDh0B4A6Au7jExhSRfhdBCIaNNtXuchwqQ__";

export default function UploadImage({ open, handleClose, refetch, bannerId, imageUrl }) {
  const [preview, setPreview] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [uploadBanner, { loading }] = useUploadBannerImageMutation();

  const handleSetPreview = (e) => {
    const file = e.target.files[0];

    if (file.size > 2 * 1024 * 1024) {
      toast.error("Picture too large! Our system's on a diet, only 2MB allowed!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    setFile(file);
    const url = URL.createObjectURL(file);
    setPreview(url);
  };

  const handleSubmitUpload = async (file) => {
    if (file) {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("bannerId", bannerId);
      await uploadBanner(formData);

      toast.success("Upload image successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          fontFamily: "Lexend",
          fontSize: 14,
        },
      });

      await refetch();
      URL.revokeObjectURL(preview);
      formData.delete("file");
      setPreview("");
      handleClose();
    }
  };

  React.useEffect(() => {
    return () => setFile(null);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          URL.revokeObjectURL(preview);
          setPreview("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ p: 3, pb: 2, fontFamily: "Lexend, san-serif" }}
          >
            Change banner/annoucement
          </MDTypography>
          <MDBox
            sx={{
              width: "100%",
              height: "500px",
              maxHeight: "min(50vh, 80vw)",
              px: 3,
            }}
          >
            <MDBox
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                border: "1px solid #dddddd",
                borderRadius: "15px",
              }}
              component="img"
              src={preview || imageUrl || placeholder}
            ></MDBox>
          </MDBox>

          <MDBox display="flex" sx={{ p: 3, pt: 2, width: "100%" }}>
            <Button
              variant="contained"
              sx={{
                mr: 2,
                color: "#FFFFFF",
                backgroundColor: "#5E72E4",
                fontFamily: "Lexend, san-serif",
              }}
              component="label"
              role={undefined}
              tabIndex={-1}
            >
              Upload
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => handleSetPreview(e)}
                multiple={false}
              />
            </Button>
            <Button
              variant="contained"
              sx={{
                ml: "auto",
                color: "#FFFFFF",
                backgroundColor: "#5E72E4",
                fontFamily: "Lexend, san-serif",
              }}
              onClick={() => handleSubmitUpload(file)}
            >
              Confirm
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
    </>
  );
}

UploadImage.propTypes = {
  open: PropTypes.boolean,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  bannerId: PropTypes.string,
  imageUrl: PropTypes.string,
};
