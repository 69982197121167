import PropTypes from "prop-types";

export const CartIcon = ({ width = 23, height = 23, fill = "#FFFFFF" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_3060)">
        <path
          d="M21.7494 15.0299H3.43793L4.81129 13.1512H18.0871C18.4817 13.1512 18.8305 12.8928 18.9551 12.5086L21.7018 4.05423C21.7961 3.76772 21.7485 3.45303 21.5763 3.20785C21.4042 2.96267 21.1277 2.81801 20.8338 2.81801H4.73255L2.25409 0.275107C1.89611 -0.0921905 1.31746 -0.0921905 0.959474 0.275107C0.601485 0.642404 0.601485 1.23609 0.959474 1.60339L3.43793 4.14629V11.898L0.874326 15.4057C0.665575 15.6903 0.632615 16.0708 0.787346 16.3892C0.942994 16.7077 1.25978 16.9087 1.60678 16.9087H21.7494C22.2557 16.9087 22.665 16.4879 22.665 15.9693C22.665 15.4508 22.2557 15.0299 21.7494 15.0299Z"
          fill={fill}
        />
        <path
          d="M4.3537 22.5451C5.36501 22.5451 6.18484 21.704 6.18484 20.6664C6.18484 19.6287 5.36501 18.7876 4.3537 18.7876C3.34238 18.7876 2.52255 19.6287 2.52255 20.6664C2.52255 21.704 3.34238 22.5451 4.3537 22.5451Z"
          fill={fill}
        />
        <path
          d="M19.0029 22.5451C20.0142 22.5451 20.834 21.704 20.834 20.6664C20.834 19.6287 20.0142 18.7876 19.0029 18.7876C17.9916 18.7876 17.1717 19.6287 17.1717 20.6664C17.1717 21.704 17.9916 22.5451 19.0029 22.5451Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3060">
          <rect
            width={width - 1}
            height={height - 1}
            fill="white"
            transform="translate(0.691406)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

CartIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
