import { descriptionTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";

export const renderChangeStyle = ({ typography, breakpoints }, { value }) => ({
  flexGrow: 0,
  flexBasis: 0,
  display: "flex",
  alignItems: "center",
  ...descriptionTypography(breakpoints),
  lineHeight: "150%",
  color: value > 0 ? "#2dce89" : "#F56565",
});

export const containerStyle = ({ palette, breakpoints, functions: { pxToRem } }) => ({
  borderRadius: pxToRem(15),
  background: palette.white.main,
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
  padding: pxToRem(16),
  justifyContent: "space-between",
  flexDirection: "column",
  display: "flex",
  gap: pxToRem(16),

  [breakpoints.down(1600)]: {
    borderRadius: pxToRem(14),
    padding: pxToRem(16),
    gap: pxToRem(12),
  },
});

export const titleStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.37,
  letterSpacing: pxToRem(-0.8),
});

export const tagStyle = ({ typography, breakpoints, functions: { pxToRem } }, { active }) => ({
  borderRadius: pxToRem(6),
  background: "#eaeaea",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: `${pxToRem(4)} ${pxToRem(8)}`,
  color: "#1aae6f",
  textAlign: "center",
  fontFamily: typography.lexendFont,
  fontSize: 10,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: pxToRem(12),
  textTransform: "uppercase",
  cursor: "pointer",

  ...(active
    ? {
        background: "#b0eed3",
      }
    : {}),

  [breakpoints.down(1600)]: {
    borderRadius: pxToRem(3),
    padding: `${pxToRem(2)} ${pxToRem(4)}`,
    fontSize: 7,
  },
});

export const rankingContainerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  height: pxToRem(88),
  borderRadius: pxToRem(20),
  padding: pxToRem(16),
  background: "#1576bc",
  boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
  display: "flex",
  justifyContent: "space-between",

  [breakpoints.down(1600)]: {
    height: pxToRem(60),
    borderRadius: pxToRem(12),
    padding: pxToRem(12),
  },
});

export const rankingSubTitleStyle = ({ palette, breakpoints, typography }) => ({
  ...titleTypography(breakpoints),
  color: palette.white.main,
  lineHeight: 1.5,
  opacity: 0.7,
});

export const rankingStyle = ({ palette, breakpoints, typography }) => ({
  ...titleTypography(breakpoints),
  color: palette.white.main,
  lineHeight: 1.37,
  letterSpacing: "-0.8px",
});

export const rankingLogoStyle = ({ palette, breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(48),
  height: pxToRem(48),
  backgroundColor: palette.white.main,
  borderRadius: pxToRem(9999),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [breakpoints.down(1600)]: {
    width: pxToRem(34),
    height: pxToRem(34),
  },
});

export const rankingLogoImageStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(23),
  height: pxToRem(23),

  [breakpoints.down(1600)]: {
    width: pxToRem(16),
    height: pxToRem(16),
  },
});

export const rankingUserStyle = ({ breakpoints, functions: { pxToRem } }, { notLast }) => ({
  display: "flex",
  gap: pxToRem(16),
  alignItems: "center",
  height: pxToRem(24),
  marginBottom: notLast ? pxToRem(16) : pxToRem(0),

  [breakpoints.down(1600)]: {
    gap: pxToRem(12),
    marginBottom: notLast ? pxToRem(12) : pxToRem(0),
  },
});

export const rankStyle = ({ palette, typography, breakpoints, functions: { pxToRem } }) => ({
  flexGrow: 0,
  flexBasis: pxToRem(24),
  width: pxToRem(24),

  "& > img": {
    display: "block",
    width: pxToRem(24),
  },

  "& > span": {
    width: pxToRem(24),
    display: "block",
    color: palette.textColors.primary,
    fontFamily: typography.lexendFont,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "center",
  },

  [breakpoints.down(1600)]: {
    flexBasis: pxToRem(18),
    width: pxToRem(18),

    "& > img": {
      width: pxToRem(18),
    },

    "& > span": {
      width: pxToRem(18),
      fontSize: 10,
    },
  },
});

export const userInfoStyle = ({ functions: { pxToRem } }) => ({
  flexGrow: 1,
  flexBasis: 0,
  display: "flex",
  alignItems: "center",
  gap: pxToRem(8),
});

export const avatarContainerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(24),
  height: pxToRem(24),
  position: "relative",

  [breakpoints.down(1600)]: {
    width: pxToRem(18),
    height: pxToRem(18),
  },
});

export const statusStyle = ({ functions: { pxToRem } }, { online }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  border: `1px solid #20c997`,
  outline: `1px solid white`,
  width: pxToRem(8),
  height: pxToRem(8),
  borderRadius: "50%",
  zIndex: 99,
  background: online ? "#20c997" : "white",
});

export const avatarAltStyle = ({ typography, breakpoints, functions: { pxToRem } }, { bg }) => ({
  width: pxToRem(24),
  height: pxToRem(24),
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: bg,

  "& > span": {
    color: "#fff",
    fontFamily: typography.lexendFont,
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.67,
  },

  [breakpoints.down(1600)]: {
    width: pxToRem(18),
    height: pxToRem(18),

    "& > span": { fontSize: 6 },
  },
});

export const usernameStyle = ({ typography, breakpoints }) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: "150%",
});

export const pointStyle = ({ typography, breakpoints }) => ({
  ...titleTypography(breakpoints),
  lineHeight: "150%",
});
