import { noteTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";

export function cardStyles(theme) {
  const {
    breakpoints,
    palette,
    functions: { pxToRem },
  } = theme;

  return {
    cursor: "pointer",
    width: "100%",
    height: pxToRem(120),
    borderRadius: pxToRem(15),
    background: palette.white.main,
    boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
    padding: `${pxToRem(16)} ${pxToRem(24)}`,

    [breakpoints.down(1920)]: {
      padding: `${pxToRem(14)} ${pxToRem(20)}`,
      height: pxToRem(120),
    },

    [breakpoints.down(1600)]: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      height: pxToRem(100),
    },
  };
}

export const textContainerStyle = (theme) => {
  const { breakpoints } = theme;
  return {
    flexGrow: 1,
    maxWidth: "calc(100% - 48px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [breakpoints.down(1920)]: {
      maxWidth: "calc(100% - 40px)",
    },

    [breakpoints.down(1600)]: {
      maxWidth: "calc(100% - 36px)",
    },

    [breakpoints.down(375)]: {
      maxWidth: "calc(100% - 30px)",
    },
  };
};

export const titleStyle = ({ palette, typography, breakpoints, functions: { pxToRem } }) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: 1.25,
  letterSpacing: pxToRem(0.52),
  whiteSpace: "wrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "uppercase",
  fontWeight: 700,
});

export const progressStyle = ({ palette, typography, breakpoints }) => ({
  ...titleTypography(breakpoints),
  // color: "#FFFFFF",
});

export const logoContainerStyle = ({ breakpoints, functions: { pxToRem } }, { logoBg }) => ({
  width: pxToRem(48),
  height: pxToRem(48),
  borderRadius: pxToRem(9999),
  background: logoBg,
  flexBasis: pxToRem(48),
  flexGrow: 0,

  [breakpoints.down(1920)]: {
    width: pxToRem(40),
    height: pxToRem(40),
    flexBasis: pxToRem(40),
  },

  [breakpoints.down(1600)]: {
    width: pxToRem(36),
    height: pxToRem(36),
    flexBasis: pxToRem(36),
  },
});

export const logoStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(23),
  height: pxToRem(23),

  [breakpoints.down(1920)]: {
    width: pxToRem(20),
    height: pxToRem(20),
  },

  [breakpoints.down(1600)]: {
    width: pxToRem(16),
    height: pxToRem(16),
  },

  [breakpoints.down(577)]: {
    width: pxToRem(12),
    height: pxToRem(12),
  },
});

export const diffStyle = ({ typography, breakpoints }, { diff }) => ({
  ...noteTypography(breakpoints),
  color: diff > 0 ? "#2dce89" : "#e01e5a",

  [breakpoints.down(1920)]: {
    fontSize: 10,
  },

  [breakpoints.down(1600)]: {
    fontSize: 9,
  },
});

export const timeStyle = ({ palette, typography, breakpoints }) => ({
  ...noteTypography(breakpoints),

  [breakpoints.down(1920)]: {
    fontSize: 10,
  },

  [breakpoints.down(1600)]: {
    fontSize: 9,
  },
});
