import { PieChart } from "@mui/x-charts";
import { styled } from "@mui/material";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";

const StyledTopLabel = styled("text")(({ theme }) => ({
  fill: "#344767",
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.37,
  fontFamily: theme.typography.lexendFont,
}));

const StyledBottomLabel = styled("text")(({ theme }) => ({
  fill: "#7B809A",
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 12,
  fontWeight: 700,
  lineHeight: 1.5,
  fontFamily: theme.typography.lexendFont,
}));

// eslint-disable-next-line
function TopLabel({ children }) {
  return (
    <StyledTopLabel x={50} y={40}>
      {children}
    </StyledTopLabel>
  );
}

// eslint-disable-next-line
function BottomLabel({ children }) {
  return (
    <StyledBottomLabel x={50} y={57.5}>
      {children}
    </StyledBottomLabel>
  );
}

export const ReportPieChart = ({ data, label }) => {
  if (data.length === 0) return null;
  return (
    <MDBox
      sx={{
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <PieChart
        series={[
          {
            data: data,
            innerRadius: 45,
            outerRadius: 50,
            paddingAngle: 1,
            cx: 45,
            cy: 45,
          },
        ]}
        slotProps={{
          legend: { hidden: true },
          popper: {
            sx: {
              "*": {
                fontFamily: "Lexend",
                fontSize: 12,
                color: "black",
                fontWeight: "bold",
              },
            },
          },
        }}
        width={100}
        height={100}
      >
        {label ? (
          <>
            <TopLabel>{label}</TopLabel>
            <BottomLabel>finished</BottomLabel>
          </>
        ) : null}
      </PieChart>
    </MDBox>
  );
};

ReportPieChart.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
};
