export default () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#5E72E4" />
      <path
        d="M27.461 26.0229C26.424 26.6319 25.258 26.9999 24 26.9999C22.742 26.9999 21.576 26.6319 20.539 26.0229C16.894 26.2619 14 29.2959 14 33V34.7299L14.695 34.9529C14.829 34.9949 18.038 36 24 36C29.962 36 33.171 34.9949 33.305 34.9529L34 34.7299V33C34 29.2959 31.106 26.2619 27.461 26.0229Z"
        fill="white"
      />
      <path
        d="M24 25C27.379 25 30 21.237 30 18C30 14.691 27.309 12 24 12C20.691 12 18 14.691 18 18C18 21.237 20.621 25 24 25Z"
        fill="white"
      />
    </svg>
  );
};
