import { api } from "shared/redux/api";
import { objectToQueryString } from "shared/utils/common";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query({
      query: (arg) => {
        const { page, size, sort, type } = arg;
        return {
          url: `/private/notification/v1${
            type ? `/${type}` : ""
          }?page=${page}&size=${size}&sort=${sort}`,
        };
      },
    }),
    getNotificationCount: build.query({
      query: () => {
        return {
          url: "/private/notification/v1/count",
        };
      },
    }),
    changeStatusNotificationV1: build.mutation({
      query: (arg) => {
        return {
          url: "/private/notification/v1/status",
          method: "PUT",
          body: arg,
        };
      },
    }),
    changeStatusNotification: build.mutation({
      query: (arg) => {
        return {
          url: "/private/notification/v3/status",
          method: "PUT",
          body: arg,
        };
      },
    }),
    notificationSubscription: build.mutation({
      query: (arg) => {
        return {
          url: "/private/notification/v1/subscriptions/user",
          method: "POST",
          body: arg,
        };
      },
    }),
    createPushNotification: build.mutation({
      query: (arg) => {
        return {
          url: `/private/notification/v1/topics`,
          method: "POST",
          body: arg,
        };
      },
    }),
    uploadFileSendNoti: build.mutation({
      query: (arg) => {
        return {
          url: `/private/notification/v1/upload`,
          method: "POST",
          body: arg,
        };
      },
    }),
    createPushNotiSchedule: build.mutation({
      query: (arg) => {
        return {
          url: `/private/notification/v1/schedule`,
          method: "POST",
          body: arg,
        };
      },
    }),
    createPushNoti: build.mutation({
      query: (arg) => {
        return {
          url: `/private/notification/v1/topics`,
          method: "POST",
          body: arg,
        };
      },
    }),
    getScheduleNotificationsToday: build.query({
      query: (arg = {}) => ({
        url: `/private/notification/v1/queue/today?${objectToQueryString(arg)}`,
      }),
    }),

    getNotificationDetail: build.query({
      query: (arg) => {
        const { id } = arg;
        return {
          url: `/private/notification/v1/${id}`,
        };
      },
    }),

    getNotificationDetailV2: build.query({
      query: (arg) => {
        const { id } = arg;
        return {
          url: `/private/notification/v2/${id}`,
        };
      },
    }),

    getTemplateDetailNotification: build.query({
      query: (arg) => {
        const { id } = arg;
        return {
          url: `/private/notification/v1/template/${id}`,
        };
      },
    }),

    readAllNotification: build.mutation({
      query: () => {
        return {
          url: "/private/notification/v1/all/status",
          method: "PUT",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useGetNotificationCountQuery,
  useLazyGetNotificationCountQuery,
  useChangeStatusNotificationV1Mutation,
  useChangeStatusNotificationMutation,
  useNotificationSubscriptionMutation,
  useCreatePushNotificationMutation,
  useUploadFileSendNotiMutation,
  useCreatePushNotiScheduleMutation,
  useGetScheduleNotificationsTodayQuery,
  useLazyGetScheduleNotificationsTodayQuery,
  useCreatePushNotiMutation,
  useGetNotificationDetailQuery,
  useLazyGetNotificationDetailQuery,
  useLazyGetNotificationDetailV2Query,
  useGetTemplateDetailNotificationQuery,
  useLazyGetTemplateDetailNotificationQuery,
  useReadAllNotificationMutation,
} = injectedRtkApi;
