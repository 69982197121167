import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { AdminHeader } from "shared/components/common/AdminHeader";
import { useMaterialUIController } from "context";
import { containerStyle } from "./styles/index";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { NotificationDetail } from "./components/NotificationDetail";
import Header from "examples/Header";

export const Notification = () => {
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  return (
    <DashboardLayout>
      <Header />
      <MDBox sx={(theme) => containerStyle(theme, { miniSidenav })}>
        <NotificationDetail />
      </MDBox>
    </DashboardLayout>
  );
};
