import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  cardStyles,
  diffStyle,
  logoContainerStyle,
  logoStyle,
  progressStyle,
  textContainerStyle,
  timeStyle,
  titleStyle,
} from "./style";
import CartIcon from "components/icons/svgs/cart.svg";
import MarkIcon from "components/icons/svgs/mark.svg";
function Card({ title, progress, logoBg, logo, diff, time, isPercent }) {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={(theme) => cardStyles(theme)}
    >
      <MDBox display="flex" justifyContent="space-between" height="100%">
        <MDBox sx={(theme) => textContainerStyle(theme)}>
          <MDTypography fontWeight="bold" sx={(theme) => titleStyle(theme)}>
            {title}
          </MDTypography>
          <MDTypography fontWeight="bold" lineHeight="100%" sx={(theme) => progressStyle(theme)}>
            {+Number(progress).toFixed(2) + ""}
            {isPercent ? "%" : ""}
          </MDTypography>
          <MDBox display="flex" gap={1} alignItems="center">
            <MDTypography sx={(theme) => diffStyle(theme, { diff })}>
              {+Number(diff).toFixed(2) + ""}
              {isPercent ? "%" : ""}
            </MDTypography>
            <MDTypography sx={(theme) => timeStyle(theme)}>{time}</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={(theme) => logoContainerStyle(theme, { logoBg })}
        >
          <MDBox
            component="img"
            src={logo === "cart" ? CartIcon : MarkIcon}
            sx={(theme) => logoStyle(theme)}
          />
        </MDBox>
      </MDBox>
      {/* <MDBox>
        
      </MDBox> */}
    </MDBox>
  );
}
Card.defaultProps = {
  title: "",
  progress: 0,
  logoBg: "white",
  logo: () => <></>,
  time: "",
  isPercent: false,
};
Card.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.number,
  logoBg: PropTypes.string,
  logo: PropTypes.node,
  diff: PropTypes.number,
  time: PropTypes.string,
  isPercent: PropTypes.bool,
};
export default Card;
