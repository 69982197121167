import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
  collapseBagde,
  collapseBadgeText,
} from "examples/Sidenav/styles/sidenavCollapse";
import { useMaterialUIController } from "context";
import { Typography } from "@mui/material";
import { SVGIcon } from "components/icons";

function SidenavCollapse({
  icon,
  iconActive,
  name,
  children,
  active,
  noCollapse,
  open,
  onClick,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode, miniSidenav })
          }
        >
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              flexBasis: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ListItemIcon
              sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
            >
              {typeof icon === "string" ? (
                <SVGIcon name={icon} stroke={active ? "#1576BC" : "#ADB5BD"} />
              ) : active ? (
                iconActive ?? icon
              ) : (
                icon
              )}
            </ListItemIcon>

            <ListItemText
              primary={name}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  active,
                })
              }
            />
          </MDBox>

          {children && children.length > 0 ? (
            <Icon
              sx={(theme) =>
                collapseArrow(theme, {
                  noCollapse,
                  transparentSidenav,
                  whiteSidenav,
                  miniSidenav,
                  open,
                  active,
                  darkMode,
                })
              }
              onClick={(e) => {
                e.preventDefault();
                onClick?.();
              }}
            >
              expand_less
            </Icon>
          ) : null}

          {/* {(name === "Home page" || name === "My Assistant") && (
            <MDBox
              sx={(theme) =>
                collapseBagde(theme, {
                  noCollapse,
                  transparentSidenav,
                  whiteSidenav,
                  miniSidenav,
                  open,
                  active,
                  darkMode,
                })
              }
            >
              <Typography variant="span" sx={(theme) => collapseBadgeText(theme)}>
                1
              </Typography>
            </MDBox>
          )} */}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse
          in={open}
          unmountOnExit
          sx={({ typography, functions: { pxToRem } }) => ({
            "& li": { paddingLeft: pxToRem(8) },
            "& li span": { fontSize: 11, fontFamily: typography.lexendFont, fontWeight: 600 },
          })}
        >
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  iconActive: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SidenavCollapse;
