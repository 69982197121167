import { titleTypography } from "shared/styles/style";
import advertiseImage from "./assets/bg-advertise.png";

export const containerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  borderRadius: pxToRem(16),
  position: "relative",
  overflow: "hidden",
  height: "100%",

  [breakpoints.down(1600)]: {
    borderRadius: pxToRem(12),
  },
});

export const backgroundStyle = ({}, { bgImage }) => ({
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  backgroundImage: `url(${bgImage})`,
});

export const streamStyle = ({ breakpoints, palette, functions: { pxToRem } }) => ({
  width: pxToRem(300),
  maxWidth: `calc(100% - ${pxToRem(36)})`,
  height: `${pxToRem(44)} !important`,
  padding: `${pxToRem(0)} ${pxToRem(11)}`,
  cursor: "pointer",
  background: palette.white.main,
  borderRadius: pxToRem(8),
  position: "absolute",
  top: pxToRem(16),
  left: pxToRem(18),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: 99,

  [breakpoints.down(1600)]: {
    width: pxToRem(200),
    height: `${pxToRem(30)} !important`,
    padding: `${pxToRem(0)} ${pxToRem(8)}`,
  },
});

export const streamNameStyle = ({ breakpoints, typography }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1,
  letterSpacing: "-0.8px",
});

export const redDotStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(20),
  height: pxToRem(20),

  [breakpoints.down(1600)]: {
    width: pxToRem(14),
    height: pxToRem(14),
  },
});
