import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClassSession: build.query({
      query: (arg) => {
        const { page, size, sort, start, end, type } = arg;
        return {
          url: `/private/classSession/v2/scheduleByDate?page=${page}&size=${size}&sort=${sort}&start=${start}&end=${end}&type=${type}`,
        };
      },
    }),
    countLegend: build.query({
      query: (arg = {}) => {
        const { start, end } = arg;
        return {
          url: `/private/classSession/v1/count?start=${start}&end=${end}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetClassSessionQuery, useLazyGetClassSessionQuery, useLazyCountLegendQuery } =
  injectedRtkApi;
