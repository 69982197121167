export const actions = [
  {
    "Offtrack duration": 1,
    "Offtrack category": "Engagement",
    Action: "Balance talktime and encourage student complete more H5P questions in class",
    PIC: "Online Teacher",
  },
  {
    "Offtrack duration": 1,
    "Offtrack category": "Home Learning",
    Action: "Remind students to complete home learning",
    PIC: "Online Teacher\nOffline Teacher",
  },
  {
    "Offtrack duration": 1,
    "Offtrack category": "Performance Offtrack",
    Action: "Observe if student struggles and provide support during class time",
    PIC: "Online Teacher\nOffline Teacher",
  },
  {
    "Offtrack duration": 2,
    "Offtrack category": "Engagement",
    Action: "Balance talktime and encourage student complete more H5P questions in class",
    PIC: "Online Teacher",
  },
  {
    "Offtrack duration": 2,
    "Offtrack category": "Performance Offtrack",
    Action: "Observe if student struggles and provide support during class time",
    PIC: "Online Teacher\nOffline Teacher",
  },
  {
    "Offtrack duration": 2,
    "Offtrack category": "Home Learning",
    Action: "Remind students to complete home learning",
    PIC: "Online Teacher\nOffline Teacher",
  },
  {
    "Offtrack duration": 4,
    "Offtrack category": "Engagement",
    Action: "Balance talktime and encourage student complete more H5P questions in class",
    PIC: "Online Teacher",
  },
  {
    "Offtrack duration": 4,
    "Offtrack category": "Performance Offtrack. Offer Tutoring",
    Action: "Observe if student struggles and provide support during class time",
    PIC: "Online Teacher\nOffline Teacher",
  },
  {
    "Offtrack duration": 4,
    "Offtrack category": "Home Learning",
    Action: "Remind students to complete home learning",
    PIC: "Online Teacher\nOffline Teacher",
  },
  {
    "Offtrack duration": 8,
    "Offtrack category": "Engagement",
    Action: "Balance talktime and encourage student complete more H5P questions in class",
    PIC: "Online Teacher",
  },
  {
    "Offtrack duration": 8,
    "Offtrack category": "Performance Offtrack",
    Action: "Observe if student struggles and provide support during class time. Offer Tutoring",
    PIC: "Online Teacher\nOffline Teacher",
  },
  {
    "Offtrack duration": 8,
    "Offtrack category": "Home Learning",
    Action: "Remind students to complete home learning",
    PIC: "Online Teacher\nOffline Teacher",
  },
];
