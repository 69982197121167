import PropTypes from "prop-types";

export const ArrowRightIcon = ({ width = 34, height = 23, fill = "#868686" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M33.0796 12.0265C33.6542 11.4221 33.6366 10.4725 33.0402 9.90559L23.3218 0.666723C22.7254 0.0997796 21.7762 0.130168 21.2016 0.734596C20.627 1.33902 20.6446 2.28861 21.241 2.85555L29.8796 11.0679L21.5563 19.8232C20.9817 20.4276 20.9993 21.3772 21.5957 21.9441C22.1921 22.5111 23.1413 22.4807 23.7159 21.8763L33.0796 12.0265ZM0.789589 13.4997L32.0277 12.4997L31.9719 9.50026L0.733849 10.5003L0.789589 13.4997Z"
        fill={fill}
      />
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
