import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";

const initialState = {
  banners: [],
  announcements: [],
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getBannerForTeacher.matchFulfilled, (state, { payload }) => {
      state.banners = payload.data.data.filter((item) => item.type === "BANNER");
      state.announcements = payload.data.data.filter((item) => item.type === "ANNOUNCEMENT");
    });
  },
});

export default bannerSlice.reducer;

export const selectBanner = (state) => state.banner;
