import PropTypes from "prop-types";

export const BooksIcon = ({ width = 18, height = 16, fill = "#8392AB" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 0.5H0.75C0.336 0.5 0 0.83525 0 1.25V14.75C0 15.1648 0.336 15.5 0.75 15.5H3.75C4.164 15.5 4.5 15.1648 4.5 14.75V1.25C4.5 0.83525 4.164 0.5 3.75 0.5Z"
        fill={fill}
      />
      <path
        d="M9.75 0.5H6.75C6.336 0.5 6 0.83525 6 1.25V14.75C6 15.1648 6.336 15.5 6.75 15.5H9.75C10.164 15.5 10.5 15.1648 10.5 14.75V1.25C10.5 0.83525 10.164 0.5 9.75 0.5Z"
        fill={fill}
      />
      <path
        d="M17.979 13.8676L14.802 0.7471C14.7045 0.34435 14.2958 0.0946004 13.8968 0.1951L10.9808 0.9001C10.7873 0.9466 10.6208 1.06885 10.5173 1.23835C10.4138 1.40785 10.3815 1.61185 10.428 1.80535L13.6043 14.9266C13.6508 15.1201 13.773 15.2866 13.9425 15.3901C14.0625 15.4629 14.1975 15.5004 14.334 15.5004C14.3933 15.5004 14.4525 15.4929 14.5103 15.4794L17.4263 14.7736C17.829 14.6754 18.0765 14.2704 17.979 13.8676Z"
        fill={fill}
      />
    </svg>
  );
};

BooksIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
