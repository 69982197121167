import { useEffect, useState } from "react";
import { useLazyGetStudentAtRiskQuery } from "shared/redux/endpoints/teacher";
import moment from "moment";
import { useLazyGetStudentAtRiskClassSuggessionQuery } from "shared/redux/endpoints/teacher";

export const useStudentAtRisk = () => {
  const [classCode, setClassCode] = useState("");
  const [isFirst, setFirst] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [trigger, { data, isFetching }] = useLazyGetStudentAtRiskQuery();
  const [status, setStatus] = useState([]);
  const [criteria, setCriteria] = useState([]);

  const [listClass, setListClass] = useState([]);

  const [getDataClassCode, { data: dataClass, isLoading }] =
    useLazyGetStudentAtRiskClassSuggessionQuery();

  const [start, setStart] = useState(moment().add(-1, "year").startOf("year"));
  const [end, setEnd] = useState(moment().add(1, "year").endOf("year"));

  const [offtrackDuration, setOfftrackDuration] = useState([]);
  const [durations, setDurations] = useState("");

  useEffect(() => {
    if ((start && end) || (!start && !end)) {
      getDataClassCode({
        start: moment(start).format("DD-MM-YYYY"),
        end: moment(end).format("DD-MM-YYYY"),
      }).then((response) => {
        if (response.isSuccess && response.data.status) {
          setListClass(response.data.data);
        }
      });
    }
  }, [start, end]);

  useEffect(() => {
    const fetchStudentAtRisk = async () => {
      const res = await trigger({
        page,
        size: 10,
        classCode,
        start: moment(start).format("DD-MM-YYYY"),
        end: moment(end).format("DD-MM-YYYY"),
        sort: "continuousStatus%2Cdesc",
        homeLearning: offtrackDuration.includes("HOME LEARNING"),
        performance: offtrackDuration.includes("PERFORMANCE"),
        engagement: offtrackDuration.includes("ENGAGEMENT"),
        durations: durations ? durations.replace("S", "") : "",
      });

      if (res.data.status && isFirst) {
        console.log(res.data.data.data);
        setStatus(
          (res.data.data.data ?? [])
            ?.map((item) => item.continuousStatus)
            ?.filter((item, index, self) => {
              return self.indexOf(item) === index;
            })
        );
        let _criteria = [];
        res.data.data.data.forEach((item) => {
          if (item.engagementStatus === "OFFTRACK" && !_criteria.includes("ENGAGEMENT")) {
            _criteria.push("ENGAGEMENT");
          }
          if (item.homeLearningStatus === "OFFTRACK" && !_criteria.includes("HOME LEARNING")) {
            _criteria.push("HOME LEARNING");
          }
          if (item.performanceStatus === "OFFTRACK" && !_criteria.includes("PERFORMANCE")) {
            _criteria.push("PERFORMANCE");
          }
        });

        setCriteria(_criteria);
        setFirst(false);
      }
    };
    fetchStudentAtRisk();
  }, [page, classCode, start, end, offtrackDuration, durations, isFirst]);

  return {
    classCode,
    data,
    setClassCode,
    dataClass,
    listClass,
    page,
    setPage,
    totalPage,
    setTotalPage,
    isLoading: isFetching,
    isEmpty: data?.data?.data.length === 0,
    setStart,
    setEnd,
    offtrackDuration,
    setOfftrackDuration,
    durations,
    setDurations,
    status,
    criteria,
  };
};
