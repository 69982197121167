import avatar from "assets/images/apollo-english/avatar-1.png";

const leaderboards = [
  {
    header: "CENTRE LEADERBOARD",
    tags: ["Learning Experience", "BUSINESS", "SERVICE"],
    subtitle: "Your Centre Ranking",
    ranking: 21,
    users: [
      {
        ranking: 1,
        avatar: false,
        avatarAlt: "CT1",
        avatarAltBg: "#63B3ED",
        name: "Center Name 1",
        change: +1,
      },
      {
        ranking: 2,
        avatar: false,
        avatarAlt: "CT2",
        avatarAltBg: "#CED4DA",
        name: "Center Name 2",
        change: -1,
      },
      {
        ranking: 3,
        avatar: false,
        avatarAlt: "CT3",
        avatarAltBg: "linear-gradient(338deg, #2DCEC6 27.53%, #2DCE94 61.45%)",
        name: "Center Name 2",
        change: 0,
      },
      {
        ranking: 4,
        avatar: false,
        avatarAlt: "CT4",
        avatarAltBg: "linear-gradient(92deg, #FBB140 1.1%, #FB6340 99.77%)",
        name: "Center Name 4",
        change: +3,
      },
      {
        ranking: 5,
        avatar: false,
        avatarAlt: "CT5",
        avatarAltBg: "linear-gradient(338deg, #2DCEC6 27.53%, #2DCE94 61.45%)",
        name: "Center Name 5",
        change: -1,
      },
    ],
  },
  {
    header: "TEACHER LEADER BOARD",
    tags: ["ONLINE", "OFFLINE"],
    subtitle: "Yourself Ranking",
    ranking: 10,
    users: [
      {
        ranking: 1,
        avatar: avatar,
        name: "Lizzy Ann",
        online: false,
        change: +3,
      },
      {
        ranking: 2,
        avatar: avatar,
        name: "Johnson Sonn",
        online: false,
        change: -3,
      },
      {
        ranking: 3,
        avatar: avatar,

        name: "Bobby Bob",
        online: true,
        change: 0,
      },
      {
        ranking: 4,
        avatar: avatar,

        name: "Mattty Matt",
        online: false,
        change: +1,
      },
      {
        ranking: 5,
        avatar: avatar,
        online: false,
        name: "Lessy  Lyle",
        change: -1,
      },
    ],
  },
];

export default leaderboards;
