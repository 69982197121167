const cards = [
  {
    title: "This month renewal rate",
    progress: 80,
    logoBg: "#E01E5A",
    logo: "mark",
    diff: 5,
    time: "than last month",
    isPercent: true,
  },
  {
    title: "Welearn course progress",
    progress: 50,
    logoBg: "#E01E5A",
    logo: "mark",
    diff: 5,
    time: "than last week",
    isPercent: true,
  },
  {
    title: "Teaching hour",
    progress: 85,
    logoBg: "#E01E5A",
    logo: "mark",
    diff: 5,
    time: "than last month",
    isPercent: false,
  },
  {
    title: "Conversion rate ",
    progress: 65,
    logoBg: "#E01E5A",
    logo: "mark",
    diff: +2,
    time: "than last month",
    isPercent: true,
  },
];

export { cards };
