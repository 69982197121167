import * as Yup from "yup";

export const form = {
  formId: "change-password-form",
  formField: {
    currentPassword: {
      name: "currentPassword",
      label: "Current Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    newPassword: {
      name: "newPassword",
      label: "New Password",
      type: "password",
      errorMsg: "Password is required.",
      sameMsg: "New password must not same as old password",
      invalidMsg:
        "Password must contain at least 8 characters, at most 20 characters, one uppercase, one lowercase and one number",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm New Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
  },
};

const {
  formField: { currentPassword, newPassword, confirmPassword },
} = form;

export const initialValues = {
  [currentPassword.name]: "",
  [newPassword.name]: "",
  [confirmPassword.name]: "",
};

export const validations = Yup.object().shape({
  [currentPassword.name]: Yup.string().required(currentPassword.errorMsg),
  [newPassword.name]: Yup.string()
    .required(newPassword.errorMsg)
    .notOneOf([Yup.ref("currentPassword"), null], newPassword.sameMsg)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/, newPassword.invalidMsg),
  [confirmPassword.name]: Yup.string()
    .required(confirmPassword.errorMsg)
    .oneOf([Yup.ref("newPassword"), null], confirmPassword.invalidMsg),
});
