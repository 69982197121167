import { descriptionTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";

export const headerStyle = (theme) => {
  const {
    palette,
    breakpoints,
    functions: { pxToRem },
  } = theme;

  return {
    borderRadius: pxToRem(16),
    background: palette.white.main,
    padding: `${pxToRem(24)}`,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    columnSpacing: pxToRem(24),

    [breakpoints.down("xxl")]: {
      rowGap: 2.5,
      flexDirection: "column",
    },

    [breakpoints.down("md")]: {
      rowGap: 2,
    },
  };
};

export const containerStyle = (theme) => {
  const {
    functions: { pxToRem },
  } = theme;
  return {
    display: "flex",
    alignItems: "flex-start",
    gap: pxToRem(36),
    width: "100%",
    flexDirection: "column",
  };
};
