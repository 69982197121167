import { Button, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { descriptionTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";
import ModalIcon from "assets/images/modal-training.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
  padding: 6,
  maxWidth: "100%",
};

export const TrainingModal = ({ data, isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <MDBox sx={{ ...style }}>
        <MDBox display="flex" gap={1} alignItems="center" mb={3}>
          <MDBox
            sx={{
              width: 48,
              height: 48,
              background: "#5E72E4",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <MDBox sx={{ width: 29 }} component="img" src={ModalIcon}></MDBox>
          </MDBox>
          <MDBox>
            <MDTypography
              sx={({ breakpoints }) => ({
                ...titleTypography(breakpoints),
                lineHeight: 1.37,
              })}
            >
              Apollo Workshop 1
            </MDTypography>
            <MDTypography
              sx={({ breakpoints }) => ({
                ...noteTypography(breakpoints),
                lineHeight: 1.5,
              })}
            >
              Type: Training/Workshop
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 1 }}
        >
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
              lineHeight: 1.5,
            })}
          >
            <span style={{ fontWeight: 700 }}>DATE:</span> 26 October 2024
          </MDTypography>
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
              lineHeight: 1.5,
            })}
          >
            <span style={{ fontWeight: 700 }}>TIME:</span> 13:45 - 14:45
          </MDTypography>
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
              lineHeight: 1.5,
            })}
          >
            <span style={{ fontWeight: 700 }}>LOCATION:</span> 58 Pham Ngoc Thach Street, Vo Thi Sau
            Ward, District 3, Ho Chi Minh City
          </MDTypography>
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
              lineHeight: 1.5,
            })}
          >
            <span style={{ fontWeight: 700 }}>TRAINER:</span> Mark Johnson
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            color: "#FFFFFF",
            fontFamily: "Lexend, san-serif",
            textTransform: "unset",
            width: "350px",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
          component="label"
          role={undefined}
          tabIndex={-1}
        >
          <Button
            variant="contained"
            sx={{
              mt: 3,
              color: "#5E72E4",
              border: "1px solid #5E72E4",
              background: "#ffffff",
              fontFamily: "Lexend, san-serif",
              textTransform: "unset",
              width: "50%",
              boxShadow: "0px 2px 6px 0px #00000040",
              "&:hover": {
                background: "#FFFFFF",
              },
            }}
            component="label"
            role={undefined}
            tabIndex={-1}
            onClick={() => {
              onClose();
            }}
          >
            OK
          </Button>
          <Button
            variant="contained"
            sx={{
              mt: 3,
              color: "#FFFFFF",
              backgroundColor: "#5E72E4",
              fontFamily: "Lexend, san-serif",
              textTransform: "unset",
              width: "50%",
              boxShadow: "0px 2px 6px 0px #00000040",
            }}
            component="label"
            role={undefined}
            tabIndex={-1}
            onClick={async () => {
              onClose();
            }}
          >
            GO TO WELEARN
          </Button>
        </MDBox>
      </MDBox>
    </Modal>
  );
};
