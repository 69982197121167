/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Loading } from "shared/components/ui/Loading";
import { WarningAmber } from "@mui/icons-material";
import { descriptionTypography, noteTypography, titleTypography } from "shared/styles/style";
import { useHorizontalSroll } from "shared/hooks/useHorizontalScroll";
import NotFound from "assets/images/apollo-english/not-found.png";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  currentPage,
  totalPage,
  handleNext,
  handlePrev,
  setCurrentPage,
  isLoading,
  isEmpty,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = Array.from({ length: totalPage }, (_, i) => i + 1).map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => setCurrentPage(option - 1)}
      active={currentPage === option - 1}
    >
      {option}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const contentRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.target.scrollTop > 0) {
        setIsScrolling(true);

        if (contentRef) {
          clearTimeout(contentRef?.current?.timeout);

          contentRef.current.timeout = setTimeout(() => {
            setIsScrolling(false);
          }, 500);
        }
      }
    };

    const content = contentRef.current;
    content?.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      content?.removeEventListener("scroll", handleScroll, { passive: true });
    };
  }, []);

  useEffect(() => {
    const events = document.querySelectorAll(`[data-horizontal-scroll]`);

    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;
    events.forEach((event) => {
      event.addEventListener("mousedown", (e) => {
        isDown = true;
        event.classList.add("active");
        startX = e.pageX - event.offsetLeft;
        scrollLeft = event.scrollLeft;
      });

      event.addEventListener("mouseleave", () => {
        isDown = false;
        event.classList.remove("active");
      });
      event.addEventListener("mouseup", () => {
        isDown = false;
        event.classList.remove("active");
      });
      event.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - event.offsetLeft;

        const walk = (x - startX) * 1;

        event.scrollLeft = scrollLeft - walk;
      });
    });
  }, []);

  useHorizontalSroll();

  return (
    <>
      <TableContainer
        data-horizontal-scroll
        ref={contentRef}
        sx={({ breakpoints, palette }) => ({
          boxShadow: "none",
          background: "inherit",
          height: "100%",
          mb: 3,
          overflowY: "auto",
          overflowX: "scroll",
          position: "relative",

          "&::-webkit-scrollbar": {
            width: 6,
            height: 6,
          },

          "&::-webkit-scrollbar-thumb": {
            background: "#8898aa",
            borderRadius: 3,
          },

          [breakpoints.down(1600)]: {
            "*": {
              fontSize: `12px!important`,
            },
          },
        })}
      >
        {isLoading ? (
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              height: 715,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              [breakpoints.down(1600)]: {
                height: 550,
              },
            })}
          >
            <Loading style={{ width: 300, height: 200 }} />
          </MDBox>
        ) : isEmpty ? (
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              height: 715,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 48,
              objectFit: "contain",

              [breakpoints.down(1600)]: {
                height: 550,
              },
            })}
            component="img"
            src={NotFound}
          >
            {/* <WarningAmber fontSize="inherit" style={{ color: "#9999" }} />
            <MDTypography sx={{ fontSize: 16, color: "#999999", mt: 2 }}>
              No data found
            </MDTypography> */}
          </MDBox>
        ) : (
          <Table {...getTableProps()} sx={{ width: 1400 }}>
            <MDBox component="thead">
              {headerGroups.map((headerGroup, key) => (
                <TableRow
                  key={key}
                  {...headerGroup.getHeaderGroupProps()}
                  sx={{ "& .MuiBox-root": { color: "#000000!important", fontSize: 14 } }}
                >
                  {headerGroup.headers.map((column, idx) => (
                    <DataTableHeadCell
                      key={idx}
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </MDBox>
            <TableBody
              {...getTableBodyProps()}
              sx={{ height: 500, "& *": { color: "#000000!important" } }}
            >
              {page.map((row, key) => {
                prepareRow(row);
                return (
                  <TableRow key={key} {...row.getRowProps()} sx={{ height: 50 }}>
                    {row.cells.map((cell, idx) => (
                      <DataTableBodyCell
                        key={idx}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })}
              <MDBox sx={{ height: 1, width: 1 }}></MDBox>
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="flex-end"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 1 : 3}
      >
        <MDPagination
          variant={pagination.variant ? pagination.variant : "gradient"}
          color={pagination.color ? pagination.color : "info"}
        >
          {currentPage !== 0 && (
            <MDPagination
              item
              onClick={() => {
                if (!(currentPage === 0)) {
                  handlePrev();
                }
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
          )}
          {renderPagination}
          {currentPage !== totalPage - 1 && totalPage !== 0 ? (
            <MDPagination
              item
              onClick={() => {
                if (currentPage !== totalPage - 1 && totalPage !== 0) {
                  handleNext();
                }
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          ) : null}
        </MDPagination>
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  currentPage: PropTypes.number,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  totalPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

export default DataTable;
