export const titleStyle = ({ breakpoints, palette, typography, functions: { pxToRem } }) => ({
  fontSize: 16,
  fontWeight: 700,
  fontFamily: typography.lexendFont,
  color: palette.textColors.primary,
  marginBottom: pxToRem(12),

  [breakpoints.down(1367)]: {
    fontSize: 13,
  },
});

export const titleInputStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  width: "100%",
  mb: 2,
  "& input": {
    fontFamily: typography.lexendFont,
  },

  [breakpoints.down(1367)]: {
    "& input": {
      padding: `${pxToRem(8)} ${pxToRem(10)}`,
    },
  },
});
