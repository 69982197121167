import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";

const initialState = {
  accessToken: "",
  refreshToken: "",
  user: {},
  role: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: () => initialState,
    setTokens: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
    setUser: (state, { payload }) => {
      state.user = payload.user;
    },
    setEmpCode: (state, { payload }) => {
      state.user.empCode = payload.empCode;
    },
    setRole: (state, { payload }) => {
      state.role = payload.role;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.loginV2.matchFulfilled, (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.user = {
        ...payload.user,
        avatar: payload.user.avatar ?? "https://demo-lesson.sgp1.digitaloceanspaces.com/ava.png",
      };
      switch (payload.role) {
        case "ROLE_CSO": {
          state.role = "CSO";
          break;
        }
        case "ROLE_ADMIN": {
          state.role = "Admin";
          break;
        }
        case "ROLE_TEACHER": {
          state.role = "Teacher";
          break;
        }
        default: {
          break;
        }
      }
    });
  },
});

export default authSlice.reducer;

export const { setTokens, logOut, setUser, setEmpCode, setRole } = authSlice.actions;

export const selectAuth = (state) => state.auth;
