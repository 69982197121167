import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { containerStyle, headerStyle, nameStyle, roleStyle } from "../styles/ProfileHeader";
import { useState } from "react";
import UploadAvatar from "./UploadAvatar";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";

export const ProfileHeader = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const auth = useSelector(selectAuth);
  return (
    <MDBox sx={(theme) => headerStyle(theme)}>
      <MDBox sx={(theme) => containerStyle(theme)}>
        <MDBox
          component="img"
          src={auth?.user?.avatar}
          onClick={handleOpen}
          width={74}
          height={74}
        ></MDBox>
        <MDBox>
          <MDTypography sx={(theme) => nameStyle(theme)}>{auth?.user?.fullName}</MDTypography>
          <MDTypography sx={(theme) => roleStyle(theme)}>{auth?.role}</MDTypography>
        </MDBox>
      </MDBox>
      <UploadAvatar open={open} handleOpen={handleOpen} handleClose={handleClose} />
    </MDBox>
  );
};
