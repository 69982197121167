import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ProfileHeader } from "./ProfileHeader";
import { BasicInfo } from "./BasicInfo";
import { ChangePassword } from "./ChangePassword";

import { sideNavItemStyles, sideNavLabelStyles } from "../styles/myself";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SVGIcon } from "components/icons";

export const sideNavMenu = [
  {
    icon: <SVGIcon name="rocket" />,
    activeIcon: <SVGIcon name="rocket" fill="white" />,
    title: "Profile",
    elementId: "profile",
  },
  {
    icon: <SVGIcon name="books" />,
    activeIcon: <SVGIcon name="books" fill="white" />,
    title: "Basic Info",
    elementId: "basic-info",
  },
  {
    icon: <SVGIcon name="atom" />,
    activeIcon: <SVGIcon name="atom" fill="white" />,
    title: "Change Password",
    elementId: "change-password",
  },
  {
    icon: <SVGIcon name="visa" />,
    activeIcon: <SVGIcon name="visa" fill="white" />,
    title: "Visa/Work permit",
    elementId: "visa-work-permit",
  },
];

const ids = sideNavMenu.map((item) => item.elementId);

export const PersonalInfomation = () => {
  const [menuId, setMenuId] = useState(ids[0]);
  const location = useLocation();

  const hashParam = location.hash.slice(1);

  const findMinimumGreaterId = (data, position) => {
    const sortedData = [...data].sort((a, b) => a.position - b.position);
    for (const element of sortedData) {
      if (element.position > position - 1) {
        return element.id;
      }
    }
    return sortedData[sortedData.length - 1].id;
  };

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleLoad = () => {
      if (hashParam) {
        scrollTo(hashParam);
      }
    };
    setTimeout(() => {
      handleLoad();
    }, 200);
  }, [hashParam]);

  useEffect(() => {
    let timeoutId = null;
    const y = ids
      .map((id) => {
        const element = document.getElementById(id);

        if (element) {
          const position = element.getBoundingClientRect().top + window.scrollY;

          return { id, position };
        }
        return null;
      })
      .filter((item) => item);

    const onScroll = (e) => {
      const id = findMinimumGreaterId(y, e.target.documentElement.scrollTop);
      setMenuId(id);
    };
    window.addEventListener(
      "scroll",
      (e) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          onScroll(e);
        }, 50);
      },
      {}
    );

    return () => window.removeEventListener("scroll", onScroll);
  }, [ids]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      columnSpacing={3}
    >
      <Grid
        item
        xs={12}
        md="auto"
        sx={({ breakpoints }) => ({
          position: "sticky",
          top: 0,
          pt: 3,
          [breakpoints.down(768)]: {
            position: "static",
          },
        })}
      >
        <MDBox
          sx={({ palette, breakpoints, functions: { pxToRem } }) => ({
            background: palette.white.main,
            borderRadius: pxToRem(15),
            display: "flex",
            flexDirection: "column",
            marginBottom: pxToRem(24),
            overflow: "hidden",
            width: 200,

            [breakpoints.down(768)]: {
              width: "100%",
            },
          })}
        >
          {sideNavMenu.map((item, key) => (
            <MDBox
              key={key}
              sx={(theme) => sideNavItemStyles(theme, { active: menuId === item.elementId })}
              onClick={() => scrollTo(item.elementId)}
            >
              <MDBox>{menuId === item.elementId ? item.activeIcon : item.icon}</MDBox>
              <MDTypography
                sx={(theme) =>
                  sideNavLabelStyles(theme, {
                    active: menuId === item.elementId,
                  })
                }
              >
                {item.title}
              </MDTypography>
            </MDBox>
          ))}
        </MDBox>
      </Grid>
      <Grid item xs>
        <MDBox className="content-section" id="profile" pt={3}>
          <ProfileHeader />
        </MDBox>
        <MDBox className="content-section" id="basic-info" pt={3}>
          <BasicInfo />
        </MDBox>
        <MDBox className="content-section" id="change-password" pt={3}>
          <ChangePassword />
        </MDBox>
      </Grid>
    </Grid>
  );
};
