import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ProfileHeader } from "./ProfileHeader";

import { sideNavItemStyles, sideNavLabelStyles } from "../styles/myself";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { SVGIcon } from "components/icons";
import { ContractItem } from "./ContractItem";
import { useGetLabourInformationMutation } from "../../../shared/redux/endpoints/employee";
import { useSelector } from "react-redux";
import { selectAuth, setEmpCode } from "../../../shared/redux/slices/authSlice";
import { Loading } from "../../../shared/components/ui/Loading";
import { titleTypography } from "../../../shared/styles/style";
import { WarningAmberOutlined } from "@mui/icons-material";
import NotFound from "assets/images/apollo-english/not-found.png";

export const _ = [
  {
    icon: <SVGIcon name="rocket" />,
    activeIcon: <SVGIcon name="rocket" fill="white" />,
    title: "Profile",
    elementId: "profile",
  },
];

export const LabourInfomation = () => {
  const [getLabourInformation, { isLoading }] = useGetLabourInformationMutation();
  const [data, setData] = useState([]);

  const auth = useSelector(selectAuth);

  useEffect(() => {
    if (auth.user.empCode) {
      const fetchLabourInformation = async () => {
        const response = await getLabourInformation({ empCode: auth.user.empCode });
        if (response?.data) {
          const result = response.data.data.Result;
          setData(result);
        } else {
          setData([]);
        }
      };
      fetchLabourInformation();
    } else {
      setData([]);
    }
  }, [auth]);

  const sideNavMenu = useMemo(
    () => [
      ..._,
      ...(data ?? []).map((item, index) => {
        return {
          icon: <SVGIcon name="rocket" />,
          activeIcon: <SVGIcon name="rocket" fill="white" />,
          title: item?.ContractNo || "--",
          elementId: `contract-${item?.ContractNo}-${index}`,
        };
      }),
    ],
    [data]
  );

  const ids = useMemo(() => sideNavMenu.map((item) => item.elementId), [sideNavMenu]);

  function findMinimumGreaterId(data, position) {
    const sortedData = [...data].sort((a, b) => a.position - b.position);
    for (const element of sortedData) {
      if (element.position > position - 1) {
        return element.id;
      }
    }
    return null;
  }

  const [menuId, setMenuId] = useState(ids[0]);
  const location = useLocation();

  const hashParam = location.hash.slice(1);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleLoad = () => {
      if (hashParam) {
        scrollTo(hashParam);
      }
    };
    setTimeout(() => {
      handleLoad();
    }, 200);
  }, [hashParam]);

  useEffect(() => {
    let timeoutId = null;
    const y = ids
      .map((id) => {
        const element = document.getElementById(id);
        if (element) {
          const position = element.getBoundingClientRect().top + window.scrollY;

          return { id, position };
        }
        return null;
      })
      .filter((item) => item);
    const onScroll = (e) => {
      const id = findMinimumGreaterId(y, e.target.documentElement.scrollTop);
      setMenuId(id);
    };
    window.addEventListener("scroll", (e) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        onScroll(e);
      }, 50);
    });

    return () => window.removeEventListener("scroll", onScroll);
  }, [data]);

  const renderItems = useMemo(() => {
    return (data ?? []).map((item, index) => (
      <MDBox
        key={index}
        className="content-section"
        id={`contract-${item.ContractNo}-${index}`}
        pb={3}
      >
        <ContractItem data={item} />
      </MDBox>
    ));
  }, [data]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      columnSpacing={3}
    >
      {/*<Grid*/}
      {/*  item*/}
      {/*  xs={12}*/}
      {/*  md="auto"*/}
      {/*  sx={({ breakpoints }) => ({*/}
      {/*    position: "sticky",*/}
      {/*    top: 0,*/}
      {/*    pt: 3,*/}
      {/*    [breakpoints.down(768)]: {*/}
      {/*      position: "static",*/}
      {/*    },*/}
      {/*  })}*/}
      {/*>*/}
      {/*  <MDBox*/}
      {/*    sx={({ palette, breakpoints, functions: { pxToRem } }) => ({*/}
      {/*      background: palette.white.main,*/}
      {/*      borderRadius: pxToRem(15),*/}
      {/*      display: "flex",*/}
      {/*      flexDirection: "column",*/}
      {/*      marginBottom: pxToRem(24),*/}
      {/*      overflow: "hidden",*/}
      {/*      width: 200,*/}

      {/*      [breakpoints.down(768)]: {*/}
      {/*        width: "100%",*/}
      {/*      },*/}
      {/*    })}*/}
      {/*  >*/}
      {/*    {sideNavMenu.map((item, key) => {*/}
      {/*      return (*/}
      {/*        <MDBox*/}
      {/*          key={key}*/}
      {/*          sx={(theme) => sideNavItemStyles(theme, { active: menuId === item.elementId })}*/}
      {/*          onClick={() => scrollTo(item.elementId)}*/}
      {/*        >*/}
      {/*          <MDBox>{menuId === item.elementId ? item.activeIcon : item.icon}</MDBox>*/}
      {/*          <MDTypography*/}
      {/*            sx={(theme) => sideNavLabelStyles(theme, { active: menuId === item.elementId })}*/}
      {/*          >*/}
      {/*            {item.title}*/}
      {/*          </MDTypography>*/}
      {/*        </MDBox>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </MDBox>*/}
      {/*</Grid>*/}
      <Grid item xs>
        <MDBox className="content-section" id="profile" py={3}>
          <ProfileHeader />
        </MDBox>
        {isLoading ? (
          <MDBox
            sx={{
              width: "100%",
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading style={{ height: 200, width: 300 }} />
          </MDBox>
        ) : data.length === 0 ? (
          <MDBox sx={{ minHeight: "calc(100vh - 24px)" }}>
            <MDBox
              sx={{
                height: 400,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                fontSize: "24px",

                objectFit: "contain",
              }}
              component="img"
              src={NotFound}
            >
              {/* <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
                Data not found
              </MDTypography> */}
            </MDBox>
          </MDBox>
        ) : (
          renderItems
        )}
      </Grid>
    </Grid>
  );
};
