// const timezones = [
//   { value: "America/Los_Angeles", label: "-12:00" }, // Example city
//   { value: "Pacific/Samoa", label: "-11:00" }, // Example city
//   { value: "Pacific/Honolulu", label: "-10:00" }, // Example city
//   { value: "America/Anchorage", label: "-9:00" }, // Example city
//   { value: "America/Los_Angeles", label: "-8:00" }, // Example city
//   { value: "America/Mexico_City", label: "-7:00" }, // Example city
//   { value: "America/Chicago", label: "-6:00" }, // Example city
//   { value: "America/New_York", label: "-5:00" }, // Example city
//   { value: "America/Caracas", label: "-4:30" }, // Example city
//   { value: "America/Halifax", label: "-4:00" }, // Example city
//   { value: "America/St_Johns", label: "-3:30" }, // Example city
//   { value: "America/Argentina/Buenos_Aires", label: "-3:00" }, // Example city
//   { value: "America/Sao_Paulo", label: "-3:00" }, // Example city
//   { value: "America/Godthab", label: "-2:00" }, // Example city
//   { value: "Atlantic/Cape_Verde", label: "-1:00" }, // Example city
//   { value: "Europe/London", label: "+0:00" }, // Example city
//   { value: "Europe/Berlin", label: "+1:00" }, // Example city
//   { value: "Africa/Cairo", label: "+2:00" }, // Example city
//   { value: "Europe/Moscow", label: "+3:00" }, // Example city
//   { value: "Asia/Tehran", label: "+3:30" }, // Example city
//   { value: "Asia/Dubai", label: "+4:00" }, // Example city
//   { value: "Asia/Kabul", label: "+4:30" }, // Example city
//   { value: "Asia/Karachi", label: "+5:00" }, // Example city
//   { value: "Asia/Kolkata", label: "+5:30" }, // Example city
//   { value: "Asia/Almaty", label: "+6:00" }, // Example city
//   { value: "Asia/Rangoon", label: "+6:30" }, // Example city
//   { value: "Asia/Bangkok", label: "+7:00" }, // Example city
//   { value: "Asia/Beijing", label: "+8:00" }, // Example city
//   { value: "Asia/Tokyo", label: "+9:00" }, // Example city
//   { value: "Australia/Darwin", label: "+9:30" }, // Example city
//   { value: "Australia/Brisbane", label: "+10:00" }, // Example city
//   { value: "Asia/Vladivostok", label: "+11:00" }, // Example city
//   { value: "Pacific/Auckland", label: "+12:00" }, // Example city
//   { value: "Pacific/Fiji", label: "+13:00" }, // Example city
// ];

const timezones = [
  { value: -12, label: "-12:00" }, // Example city
  { value: -11, label: "-11:00" }, // Example city
  { value: -10, label: "-10:00" }, // Example city
  { value: -9, label: "-9:00" }, // Example city
  { value: -8, label: "-8:00" }, // Example city
  { value: -7, label: "-7:00" }, // Example city
  { value: -6, label: "-6:00" }, // Example city
  { value: -5, label: "-5:00" }, // Example city
  { value: -4.5, label: "-4:30" }, // Example city
  { value: -4, label: "-4:00" }, // Example city
  { value: -3.5, label: "-3:30" }, // Example city
  { value: -3, label: "-3:00" }, // Example city
  { value: -2, label: "-2:00" }, // Example city
  { value: -1, label: "-1:00" }, // Example city
  { value: 0, label: "+0:00" }, // Example city
  { value: 1, label: "+1:00" }, // Example city
  { value: 2, label: "+2:00" }, // Example city
  { value: 3, label: "+3:00" }, // Example city
  { value: 3.5, label: "+3:30" }, // Example city
  { value: 4, label: "+4:00" }, // Example city
  { value: 4.5, label: "+4:30" }, // Example city
  { value: 5, label: "+5:00" }, // Example city
  { value: 5.5, label: "+5:30" }, // Example city
  { value: 6, label: "+6:00" }, // Example city
  { value: 6.5, label: "+6:30" }, // Example city
  { value: 7, label: "+7:00" }, // Example city
  { value: 8, label: "+8:00" }, // Example city
  { value: 9, label: "+9:00" }, // Example city
  { value: 9.5, label: "+9:30" }, // Example city
  { value: 10, label: "+10:00" }, // Example city
  { value: 11, label: "+11:00" }, // Example city
  { value: 12, label: "+12:00" }, // Example city
  { value: 13, label: "+13:00" }, // Example city
];

export default timezones;
