import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useChangeStatusNotificationV1Mutation } from "shared/redux/endpoints/notifications";
import { useReadAllNotificationMutation } from "shared/redux/endpoints/notifications";
import { useChangeStatusNotificationMutation } from "shared/redux/endpoints/notifications";
import { useLazyGetNotificationCountQuery } from "shared/redux/endpoints/notifications";
import { useGetNotificationCountQuery } from "shared/redux/endpoints/notifications";
import { useLazyGetNotificationsQuery } from "shared/redux/endpoints/notifications";
import { selectAuth } from "shared/redux/slices/authSlice";
import { changeStatusNotification } from "shared/redux/slices/notificationSlice";
import { increasePageToday } from "shared/redux/slices/notificationSlice";
import { addNewNotification } from "shared/redux/slices/notificationSlice";
import { readAllNotifications } from "shared/redux/slices/notificationSlice";
import { setNotificationDetail } from "shared/redux/slices/notificationSlice";
import { updateNotificationsToday } from "shared/redux/slices/notificationSlice";
import { updateNotifications } from "shared/redux/slices/notificationSlice";
import { increasePage } from "shared/redux/slices/notificationSlice";
import { selectNotifcation } from "shared/redux/slices/notificationSlice";

export const useNotification = () => {
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreToday, setHasMoreToday] = useState(true);

  const [trigger] = useLazyGetNotificationsQuery();
  const auth = useSelector(selectAuth);

  const isLogged = useMemo(() => !!auth.accessToken && !!auth.refreshToken, [auth]);

  const { currentPageToday, currentPage, notifications, notificationsToday } =
    useSelector(selectNotifcation);

  const [notificationCount, setNotificationCount] = useState({});

  const [triggerCount] = useLazyGetNotificationCountQuery();

  const [triggerReadAll] = useReadAllNotificationMutation();

  const [changeStatus] = useChangeStatusNotificationMutation();
  const [changeStatusV1] = useChangeStatusNotificationV1Mutation();

  const dispatch = useDispatch();

  const fetchMore = useCallback(
    async (currentPage) => {
      const response = await trigger({
        page: currentPage,
        size: 10,
        sort: "created,desc",
        type: "older",
      });

      if (response && response?.data?.status && hasMore) {
        if (response.data.data.data.length === 0) {
          setHasMore(false);
        } else {
          dispatch(
            updateNotifications({
              data: response.data.data.data,
            })
          );
          dispatch(increasePage(currentPage + 1));
        }
      }
    },
    [hasMore]
  );

  const fetchMoreToday = useCallback(
    async (currentPage) => {
      const responseToday = await trigger({
        page: currentPage,
        size: 10,
        sort: "created,desc",
        type: "today",
      });

      if (responseToday && responseToday?.data?.status && hasMoreToday) {
        if (responseToday.data.data.data.length === 0) {
          setHasMoreToday(false);
        } else {
          dispatch(
            updateNotificationsToday({
              data: responseToday.data.data.data,
            })
          );
          dispatch(increasePageToday(currentPage + 1));
        }
      }
    },
    [hasMoreToday]
  );

  useEffect(() => {
    const initNoti = async () => {
      if (isLogged) {
        const count = await triggerCount();
        if (count.isSuccess) setNotificationCount(count.data);
        fetchMore(0);
        fetchMoreToday(0);
      }
    };
    initNoti();
  }, [isLogged, auth.user.email]);

  const fetchAll = async (data) => {
    triggerCount();
    dispatch(
      addNewNotification({
        newNotification: data,
      })
    );
  };

  const handleChangeStatus = async (notificationId, uniqueContentId, read, type) => {
    dispatch(
      changeStatusNotification({
        notificationId,
        uniqueContentId,
        read,
        type,
      })
    );
    if (uniqueContentId) {
      await changeStatus({
        uniqueContentId,
        read,
      });
    } else {
      await changeStatusV1({
        notificationId,
        read,
      });
    }

    await triggerCount();
  };

  const handleSetNotificationDetail = (notification) => {
    dispatch(
      setNotificationDetail({
        notificationDetail: notification,
      })
    );
  };

  const handlerReadAllNotification = async () => {
    dispatch(readAllNotifications());
    await triggerReadAll();
    setTimeout(async () => {
      await triggerCount();
      //await trigger();
    }, 500);
  };

  return {
    currentPage,
    currentPageToday,
    fetchAll,
    fetchMore,
    fetchMoreToday,
    handleChangeStatus,
    handleSetNotificationDetail,
    hasMore,
    hasMoreToday,
    notifications,
    notificationsToday,
    notificationCount,
    handlerReadAllNotification,
  };
};
