import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEmployeeInformation: build.mutation({
      query: (arg) => {
        const { email } = arg;
        return {
          url: "/private/fpt/v1/information",
          method: "POST",
          body: {
            Authenkey: "jQmQqFYxrKXVkV8F+ajWQYpKorroUdtLPyRdovxewEs=",
            MaNhanVien: "",
            TuNgayVaoLam: "",
            DenNgayVaoLam: "",
            MaTinhTrangNhanVien: "",
            MaLoaiNhanVien: "",
            MaCongTy: "",
            MaDonViCap1: "",
            MaDonViCap2: "",
            MaDonViCap3: "",
            MaDonViCap4: "",
            MaHLevel1: "",
            MaHLevel2: "",
            MaHLevel3: "",
            MaHLevel4: "",
            MaNoiLamViec: "",
            TuNgayThoiViec: "",
            DenNgayThoiViec: "",
            EmailCongTy: email,
          },
        };
      },
    }),
    getLabourInformation: build.mutation({
      query: (arg) => {
        const { empCode } = arg;
        return {
          url: "/private/fpt/v1/contract",
          method: "POST",
          body: {
            Authenkey: "jQmQqFYxrKXVkV8F+ajWQYpKorroUdtLPyRdovxewEs=",
            EmpCode: empCode,
            EffectiveDate: "",
            ToDate: "",
            HopDongMoiNhat: "0",
          },
        };
      },
    }),
    getLeaveInformation: build.mutation({
      query: (arg) => {
        const { empCode, start, end } = arg;
        return {
          url: "/private/fpt/v1/dayOff",
          method: "POST",
          body: {
            Authenkey: "jQmQqFYxrKXVkV8F+ajWQYpKorroUdtLPyRdovxewEs=",
            FromDate: start,
            ToDate: end,
            EmpCode: empCode,
          },
        };
      },
    }),
    getRemainingLeave: build.mutation({
      query(arg) {
        const { type, empCode } = arg;
        return {
          url: "/private/fpt/v1/leaveDay",
          method: "POST",
          body: {
            Authenkey: "jQmQqFYxrKXVkV8F+ajWQYpKorroUdtLPyRdovxewEs=",
            EmpCode: empCode,
            Type: type,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEmployeeInformationMutation,
  useGetLabourInformationMutation,
  useGetLeaveInformationMutation,
  useGetRemainingLeaveMutation,
} = injectedRtkApi;
