import colors from "assets/theme/base/colors";

export const titleTypography = (breakpoints) => ({
  fontSize: `${16}px !important`,
  fontWeight: `${700} !important`,
  color: colors.textColors.primary,
  textTransform: "uppercase",

  ...(breakpoints && {
    [breakpoints.down(1600)]: {
      fontSize: `${13}px !important`,
    },
  }),
});

export const descriptionTypography = (breakpoints) => ({
  fontSize: `${14}px !important`,
  fontWeight: `${400} !important`,
  color: colors.textColors.primary,

  [breakpoints.down(1600)]: {
    fontSize: `${12}px !important`,
  },
});

export const noteTypography = (breakpoints) => ({
  fontSize: `${12}px !important`,
  fontWeight: `${400} !important`,
  color: colors.textColors.secondary,

  [breakpoints.down(1600)]: {
    fontSize: `${10}px !important`,
  },
});
