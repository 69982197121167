import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DateSelect } from "../../DateSelect/single";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/account/components/FormField";
import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";
import ChevronDownIcon from "assets/images/apollo-english/chevron-down.svg";
import moment from "moment";
import DataTable from "./table";
import { useState, useEffect, useMemo } from "react";
import { titleTypography } from "shared/styles/style";
import { useLazyGetDashboardClassQuery } from "shared/redux/endpoints/sso";
import { useSearchParams } from "react-router-dom";
import { useLazyGetClassQuery } from "shared/redux/endpoints/assistant";
import { useLazyGetClassByCodeQuery } from "shared/redux/endpoints/assistant";
import { useLazyGetSuggestionsQuery } from "shared/redux/endpoints/assistant";
import { useLazyGetClassSuggessionQuery } from "shared/redux/endpoints/assistant";

const customStyle = (disable) => ({
  minWidth: "150px",
  ...(disable && { backgroundColor: "#FAFAFA!important" }),
  borderRadius: "0.375rem",
  "& *": {
    fontFamily: "'Lexend', san-serif !important",
  },
  "& .MuiOutlinedInput-root": {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  "& input, & input::placeholder": {
    fontSize: "12px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: typography.lexendFont,
  },

  "& input": {
    paddingLeft: `${pxToRem(14)} !important`,
  },
});

function Classes() {
  const [trigger] = useLazyGetDashboardClassQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const groupKOC = searchParams.get("group-koc");
  const koc = searchParams.get("koc");
  const centre = searchParams.get("center");
  const className = searchParams.get("class");
  const valueClassName = (className && className?.split(",")) || [];
  const page = searchParams.get("page") || 0;
  const size = searchParams.get("size") || 10;
  const date =
    searchParams.get("date") || moment(new Date()).startOf("isoweek").format("MM-DD-YYYY");

  const [totalPage, setTotalPage] = useState(1);

  const handlePrev = () => {
    searchParams.set("page", (page ?? 0) - 1);
    setSearchParams(searchParams);
  };

  const handleNext = () => {
    searchParams.set("page", (page ?? 0) + 1);
    setSearchParams(searchParams);
  };

  const setCurrentPage = (page) => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
  };

  const setSize = (size) => {
    searchParams.set("size", size);
    setSearchParams(searchParams);
  };

  const [dataTable, setDataTable] = useState({
    columns: [
      { Header: "Center", accessor: "center", width: "10%" },
      { Header: "Class", accessor: "class", width: "20%" },
      { Header: "Group KOC", accessor: "groupKOC", width: "10%" },
      { Header: "KOC", accessor: "KOC", width: "10%" },
      { Header: "Level", accessor: "level", width: "10%" },
      { Header: "No. of student", accessor: "noOfStudents", width: "12.5%" },
      { Header: "No. of ontrack", accessor: "noOfOntrack", width: "12.5%" },
      { Header: "No. of outstanding", accessor: "noOfOutstanding", width: "12.5%" },
      { Header: "No. of offtrack", accessor: "noOfOfftrack", width: "12.5%" },
      {
        Header: "Offtrack percentage",
        accessor: "offtrackPercentage",
        width: "15%",
      },
    ],

    rows: [],
  });

  const [classTable, setClassTable] = useState([]);
  const [suggestions, setSuggestions] = useState({
    centerName: [],
    groupKOC: [],
    koc: [],
  });
  const [classSuggestions, setClassSuggestions] = useState([]);

  const [step, setStep] = useState(className ? 4 : koc ? 3 : groupKOC ? 2 : centre ? 1 : 0);

  const queryParams = useMemo(
    () => ({
      page: page ?? 0,
      size: size ?? 0,
      sort: "",
      startDate: date ? moment(date).format("DD-MM-YYYY") : "",
      endDate: date ? moment(date).format("DD-MM-YYYY") : "",
      groupKOC: groupKOC ?? "",
      centerName: centre ?? "",
      KOC: koc ?? "",
      classCode: className,
    }),
    [page, size, date, groupKOC, centre, koc, className]
  );

  const [triggerGetClass, { isFetching: isFetchingClass }] = useLazyGetClassQuery();
  const [triggerSuggestions, { isFetching: isFetchingSuggestions }] = useLazyGetSuggestionsQuery();
  const [triggerGetClassSuggession, { isFetching: isFetchingClassSuggestions }] =
    useLazyGetClassSuggessionQuery();
  const [triggerGetClassByCode, { isFetching: isFetchingClassByCode }] =
    useLazyGetClassByCodeQuery();

  const handleFetchSuggestions = async (data) => {
    const response = await triggerSuggestions(data);
    if (response?.data?.status) {
      setSuggestions(response?.data?.data);
    }
  };

  const handleFetchClassSuggestions = async (data) => {
    const response = await triggerGetClassSuggession(data);
    if (response?.data?.status) {
      setClassSuggestions(response?.data?.data);
    }
  };

  useEffect(() => {
    const fetchClasses = async () => {
      if (queryParams.classCode) {
        const response = await triggerGetClassByCode({
          code: queryParams.classCode,
          page: queryParams.page,
          size: queryParams.size,
          sort: queryParams.sort,
          startDate: queryParams.startDate,
          endDate: queryParams.endDate,
        });
        if (response?.data?.status) {
          setClassTable(response?.data?.data?.data ?? []);
          setTotalPage(response?.data?.data?.totalPage ?? 1);
        }
      } else {
        const response = await triggerGetClass({
          ...queryParams,
        });

        if (response?.data?.status) {
          setClassTable(response?.data?.data?.data ?? []);
          setTotalPage(response?.data?.data?.totalPage ?? 1);
        }
      }
    };
    void fetchClasses();
  }, [queryParams]);

  useEffect(() => {
    handleFetchSuggestions({
      centerName: centre || "",
      groupKOC: groupKOC || "",
    });
  }, []);

  useEffect(() => {
    if (centre && groupKOC && koc && date) {
      handleFetchClassSuggestions({
        startDate: date ? moment(date).format("DD-MM-YYYY") : "",
        endDate: date ? moment(date).format("DD-MM-YYYY") : "",
        groupKOC: groupKOC || "",
        KOC: koc || "",
        centerName: centre,
      });
    }
  }, [centre, groupKOC, koc, date]);

  // useEffect(() => {
  //   if (!date) {
  //     searchParams.set("date", moment(new Date()).startOf("isoweek").format("MM-DD-YYYY"));
  //     setSearchParams(searchParams);
  //   }
  //   if (!size) {
  //     searchParams.set("size", 10);
  //     setSearchParams(searchParams);
  //   }
  //   if (!page) {
  //     searchParams.set("page", 0);
  //     setSearchParams(searchParams);
  //   }
  // }, [date, size, page]);

  const isFetching = useMemo(
    () =>
      isFetchingClass ||
      isFetchingSuggestions ||
      isFetchingClassSuggestions ||
      isFetchingClassByCode,
    [isFetchingClass, isFetchingSuggestions, isFetchingClassSuggestions, isFetchingClassByCode]
  );

  useEffect(() => {
    if (classTable) {
      const customDataRow = classTable.map((item, index) => {
        let offtrackPercentage;

        if (!item?.ontrack && !item?.outstanding && !item?.offtrack) {
          offtrackPercentage = 0;
        } else {
          offtrackPercentage =
            (item?.offtrack /
              ((item?.ontrack ?? 0) + (item?.outstanding ?? 0) + (item.offtrack ?? 0))) *
            100;
        }
        return {
          center: item?.centerName,
          class: item?.className,
          groupKOC: item?.courseGroupName,
          classCode: item?.classCode,
          KOC: item?.courseName,
          level: item?.level_name,
          noOfStudents: item?.totalStudent,
          noOfOntrack: item?.ontrack,
          noOfOutstanding: item?.outstanding,
          noOfOfftrack: item?.offtrack,
          offtrackPercentage: `${offtrackPercentage.toFixed(2)}%`,
          LMS: (
            <MDBox
              sx={{
                backgroundColor: "#2576B9",
                color: "#FFFFFF !important",
                padding: "5px 10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={async (e) => {
                e.stopPropagation();
                if (item?.classCode) {
                  const res = await trigger({
                    classCode: item.classCode,
                  });
                  if (res) {
                    window.open(res.data.data.url, "_blank");
                  }
                }
              }}
            >
              Go to LMS
            </MDBox>
          ),
        };
      });
      setDataTable({
        columns: [
          { Header: "Center", accessor: "center", width: "10%" },
          { Header: "Class", accessor: "class", width: "15%" },
          { Header: "Group KOC", accessor: "groupKOC", width: "10%" },
          { Header: "KOC", accessor: "KOC", width: "10%" },
          { Header: "Level", accessor: "level", width: "10%" },
          { Header: "Total", accessor: "noOfStudents", width: "10%" },
          { Header: "Offtrack", accessor: "noOfOfftrack", width: "10%" },
          { Header: "Ontrack", accessor: "noOfOntrack", width: "10%" },
          { Header: "Outstanding", accessor: "noOfOutstanding", width: "10%" },
          { Header: "Offtrack percentage", accessor: "offtrackPercentage", width: "10%" },
          { Header: "LMS", accessor: "LMS", width: "10%" },
        ],
        rows: customDataRow || [],
      });
    }
  }, [classTable]);

  return (
    <MDBox mb={3}>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
            Class list
          </MDTypography>
        </MDBox>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          gap={2.25}
          width="100%"
          height="100%"
          px={3}
          pb={3}
          sx={({ breakpoints }) => ({
            [breakpoints.down("lg")]: {
              justifyContent: "flex-start",
            },
          })}
        >
          <Grid
            item
            sx={({ breakpoints }) => ({
              [breakpoints.down("md")]: { width: "100%", height: "100%" },
            })}
          >
            <DateSelect
              onChangeDate={(e) => {
                searchParams.set("date", moment(e).format("MM-DD-YYYY"));
                setSearchParams(searchParams);
              }}
              defaultDate={
                date
                  ? moment(date).format("MM-DD-YYYY")
                  : moment(new Date()).startOf("isoweek").format("MM-DD-YYYY")
              }
              disable={isFetching}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              value={centre || ""}
              options={suggestions.centerName || []}
              onChange={(e, v) => {
                searchParams.set("center", v ?? "");
                searchParams.delete("group-koc");
                searchParams.delete("koc");
                searchParams.delete("class");
                setSearchParams(searchParams);
                handleFetchSuggestions({
                  centerName: v || "",
                  groupKOC: "",
                });
                if (v) {
                  setStep(1);
                } else {
                  setStep(0);
                }
              }}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              disabled={isFetching}
              renderInput={(params) => (
                <FormField
                  {...params}
                  variant="outlined"
                  label="Centre"
                  customStyle={customStyle(isFetching)}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              value={groupKOC || ""}
              options={suggestions?.groupKOC || []}
              onChange={(e, v) => {
                searchParams.set("group-koc", v ?? "");
                searchParams.delete("koc");
                searchParams.delete("class");
                setSearchParams(searchParams);
                handleFetchSuggestions({
                  centerName: centre || "",
                  groupKOC: v || "",
                });
                if (v) {
                  setStep(2);
                } else {
                  setStep(1);
                }
              }}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              disabled={suggestions?.groupKOC?.length === 0 || isFetching || step < 1}
              renderInput={(params) => (
                <FormField
                  {...params}
                  variant="outlined"
                  label="Group KOC"
                  customStyle={customStyle(
                    suggestions?.groupKOC?.length === 0 || isFetching || step < 1
                  )}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              options={suggestions?.koc || []}
              onChange={(e, v) => {
                searchParams.set("koc", v ?? "");
                searchParams.delete("class");
                setSearchParams(searchParams);
                if (v) {
                  setStep(3);
                } else {
                  setStep(2);
                }
              }}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              value={koc}
              disabled={suggestions?.koc?.length === 0 || isFetching || step < 2}
              renderInput={(params) => (
                <FormField
                  {...params}
                  variant="outlined"
                  label="KOC"
                  customStyle={customStyle(
                    suggestions?.koc?.length === 0 || isFetching || step < 2
                  )}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              multiple
              value={valueClassName}
              options={classSuggestions.map((item) => item.value)}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              disabled={classSuggestions?.length === 0 || isFetching || step < 3}
              onChange={(_, v) => {
                searchParams.set("class", v ?? "");
                setSearchParams(searchParams);
                if (v.length > 0) {
                  setStep(4);
                } else {
                  setStep(3);
                }
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Class"
                  customStyle={customStyle(
                    classSuggestions?.length === 0 || isFetching || step < 3
                  )}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>

        <DataTable
          table={dataTable}
          isSorted={false}
          currentPage={parseInt(queryParams.page || 0)}
          handlePrev={handlePrev}
          handleNext={handleNext}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          isLoading={isFetching}
          isEmpty={classTable.length === 0}
          classPageSize={parseInt(queryParams.size || 10)}
          setClassPageSize={setSize}
          startDateClass={
            queryParams.startDate || moment(new Date()).startOf("isoweek").format("MM-DD-YYYY")
          }
        />
      </Card>
    </MDBox>
  );
}

export { Classes };
