import React from "react";

import { titleStyle } from "./style";
import { defaultProps, propTypes } from "./propsType";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const TitleBoxComponent = (props) => {
  const { title } = props;
  return (
    <MDBox display="flex" width="100%" alignItems="center" justifyContent="space-between">
      <MDBox
        display="flex"
        sx={() => ({
          flexDirection: "column",
          alignItems: "flex-start",
        })}
      >
        <MDTypography sx={(theme) => titleStyle(theme)}>{title}</MDTypography>
      </MDBox>
    </MDBox>
  );
};

TitleBoxComponent.propTypes = propTypes;
TitleBoxComponent.defaultProps = defaultProps;

export default TitleBoxComponent;
