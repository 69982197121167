import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import routes, { adminRoutes, commonRoutes } from "routes";
import { AuthWrapper } from "shared/router/authWrapper";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";
import { generateToken } from "shared/libs/firebase";
import { onMessage } from "firebase/messaging";
import { messaging } from "shared/libs/firebase";
import { useNotificationSubscriptionMutation } from "shared/redux/endpoints/notifications";
import { useNotification } from "examples/Header/hooks/useNotification";
import { detect } from "detect-browser";
import { useMediaQuery } from "@mui/material";
import { useBanners } from "pages/home/hooks/useBanners";
import { useLazyGetBannerForTeacherQuery } from "shared/redux/endpoints/banner";

const browser = detect();

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const auth = useSelector(selectAuth);
  const { accessToken, refreshToken } = auth;
  const [triggerBanners] = useLazyGetBannerForTeacherQuery();

  const isAuthenticated = useMemo(
    () => !!accessToken && !!refreshToken,
    [accessToken, refreshToken]
  );

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const hideSidenavMatch = useMediaQuery("max-width: 1200px");

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    // if (miniSidenav && !onMouseEnter) {
    //   setMiniSidenav(dispatch, false);
    //   setOnMouseEnter(true);
    // }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const csat = document?.getElementById("csat-trigger");
    if (csat) {
      csat.style.display = "block";
    }
  }, [pathname]);

  const [subscribe] = useNotificationSubscriptionMutation();

  const { fetchAll } = useNotification();

  useEffect(() => {
    if (isAuthenticated) {
      triggerBanners({
        size: 10,
        page: 0,
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const initNotification = async () => {
      const token = await generateToken();

      await subscribe({
        token,
        device: "WEB",
        browser: browser
          ? browser.name === "edge-chromium"
            ? "EDGE"
            : browser.name.toUpperCase()
          : "CHROME",
      });
      onMessage(messaging, (payload) => {
        const data = payload.data.body;
        const parsedData = JSON.parse(data);
        fetchAll(parsedData);
      });
    };
    if (isAuthenticated) {
      initNotification();
    }
  }, [isAuthenticated]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return (
          <>
            <Route
              exact
              path={route.route}
              element={
                route.isPrivate ? (
                  <AuthWrapper isAllowed={isAuthenticated} redirectPath={route.redirectPath}>
                    {route.component}
                  </AuthWrapper>
                ) : (
                  route.component
                )
              }
              key={route.key}
            />
            {getRoutes(route.collapse)}
          </>
        );
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.isPrivate ? (
                <AuthWrapper isAllowed={isAuthenticated} redirectPath={route.redirectPath}>
                  {route.component}
                </AuthWrapper>
              ) : (
                route.component
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        handleConfiguratorOpen();
      }}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {isAuthenticated && layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Material Dashboard PRO"
            routes={auth?.role === "Admin" ? adminRoutes : routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      <Routes>
        {auth?.role === "Admin" ? getRoutes(adminRoutes) : getRoutes(routes)}
        {getRoutes(commonRoutes)}
        <Route
          path="*"
          element={
            <Navigate to={auth?.role === "Admin" ? "/user-management/admin-management" : "/"} />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
