import CategoryBoxIcon from "components/icons/CategoryBoxIcon";
import CategoryPhoneIcon from "components/icons/CategoryPhoneIcon";
import CategoryTagIcon from "components/icons/CategoryTagIcon";

import DriveImage from "assets/images/apollo-english/drive.png";
import HubImage from "assets/images/apollo-english/HUB.png";
import LMSImage from "assets/images/apollo-english/LMS.png";
import SlackImage from "assets/images/apollo-english/slack.png";

const categories = [
  {
    header: "WORK STATION",
    items: [
      {
        logo: <CategoryBoxIcon />,
        title: "HUB 24",
        subtitle: "CRM, SIS, Ticket",
        link: "http://hub24.apollo.vn:10004/",
      },
      {
        logo: <CategoryBoxIcon />,
        title: "LMS",
        subtitle: "LMS",
        link: "https://lms.apollo.edu.vn/login",
      },
      {
        logo: <CategoryBoxIcon />,
        title: "WeLearn",
        subtitle: "We learn",
        link: "https://welearn.apollo.vn/login/index.php",
      },
      // {
      //   logo: SlackImage,

      //   title: "Apollo Connect",
      //   subtitle: "Communication Channel",
      //   link: "https://sites.google.com/apollo.edu.vn/apollo-connect",
      // },
      // {
      //   logo: DriveImage,
      //   title: "Teacher Drive ",
      //   subtitle: "Teaching Materials",
      //   link: "https://drive.google.com/drive/u/0/folders/0AGpuZYv6pmTTUk9PVA?pli=1",
      // },
    ],
  },
  {
    header: "LINKS",
    items: [
      {
        logo: <CategoryBoxIcon />,
        title: "Teacher Drive ",
        subtitle: "Teaching Materials",
        link: "https://drive.google.com/drive/u/0/folders/0AGpuZYv6pmTTUk9PVA?pli=1",
      },
      {
        logo: <CategoryBoxIcon />,
        title: "Apollo Connect",
        subtitle: "Communication Channel",
        link: "https://sites.google.com/apollo.edu.vn/apollo-connect",
      },
      {
        logo: <CategoryBoxIcon />,
        title: "Active Online Teacher Portal",
        subtitle: "Active Online Teacher Portal",
        link: "https://sites.google.com/apollo.edu.vn/active-teacher-portal/active-training?authuser=0",
      },
      {
        logo: <CategoryBoxIcon />,
        title: "IELTS Portal",
        subtitle: "IELTS Portal",
        link: "https://sites.google.com/apollo.edu.vn/apollo-ielts/home",
      },
      {
        logo: <CategoryBoxIcon />,
        title: "My Apollo Support Link",
        subtitle: "My Apollo Support Link",
        link: "https://docs.google.com/spreadsheets/d/1t_31p8T6QOtd4XrECpncVfYAHcCG-DCR8Yx7aCjPXJE/edit?usp=sharing",
      },
    ],
  },
];

export default categories;
