import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { containerStyle, headerStyle } from "../styles/ContractItem";
import { Grid } from "@mui/material";
import typography from "assets/theme/base/typography";
import palette from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import FormField from "layouts/pages/account/components/FormField";
import { titleTypography } from "shared/styles/style";
import { CustomDatePicker } from "shared/components/ui/CustomDatePicker";
import dayjs from "dayjs";
import { descriptionTypography } from "shared/styles/style";

const customStyle = {
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    padding: `${pxToRem(9.5)} ${pxToRem(14)}`,
  },

  "& input, & input::placeholder": {
    fontSize: "16px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: typography.lexendFont,
  },

  "& input": {
    paddingLeft: `${pxToRem(14)} !important`,
  },

  "& label": {
    fontSize: "18px !important",
    fontWeight: 700,
    fontFamily: typography.lexendFont,
    color: palette.textColors.primary,
    top: "-2px",
  },

  "& legend": {
    fontSize: "16px !important",
  },
};

// eslint-disable-next-line
export const ContractItem = ({ last, data = {} }) => {
  const content = (
    <MDBox sx={(theme) => headerStyle(theme)}>
      <MDBox sx={(theme) => containerStyle(theme)}>
        <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
          Contract No: {data?.ContractNo || "--"}
        </MDTypography>
        <Grid container sx={{ width: "100%" }}>
          <Grid item container xs={12} md={6} rowGap={4}>
            <Grid item xs={12}>
              <MDBox
                sx={({ breakpoints }) => ({
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                })}
              >
                <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
                  Contract name
                </MDTypography>
                <MDTypography sx={({ breakpoints }) => ({ ...descriptionTypography(breakpoints) })}>
                  {data.ContractTypeName || "--"}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                sx={({ breakpoints }) => ({
                  display: "flex",
                  flexDirection: "column",
                  width: 300,

                  [breakpoints.down(768)]: {
                    width: "100%",
                  },
                })}
              >
                <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
                  Sign date
                </MDTypography>
                <MDTypography sx={({ breakpoints }) => ({ ...descriptionTypography(breakpoints) })}>
                  {data?.SignDate || "--"}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} rowGap={4}>
            <Grid item xs={12}>
              <MDBox
                sx={({ breakpoints }) => ({
                  display: "flex",
                  flexDirection: "column",
                  width: 300,

                  [breakpoints.down(768)]: {
                    width: "100%",
                  },
                })}
              >
                <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
                  Effective date
                </MDTypography>
                <MDTypography sx={({ breakpoints }) => ({ ...descriptionTypography(breakpoints) })}>
                  {data?.EffectiveDate || "--"}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                sx={({ breakpoints }) => ({
                  display: "flex",
                  flexDirection: "column",
                  width: 300,

                  [breakpoints.down(768)]: {
                    width: "100%",
                  },
                })}
              >
                <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
                  To date
                </MDTypography>
                <MDTypography sx={({ breakpoints }) => ({ ...descriptionTypography(breakpoints) })}>
                  {data?.ToDate || "--"}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
  return last ? <MDBox sx={{ minHeight: "calc(100vh - 24px)" }}>{content}</MDBox> : content;
};
