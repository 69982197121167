import { useEffect } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useSelector } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const MyImpact = () => {
  const user = useSelector((state) => state.auth.user);
  const userId = user.userId;
  const fetchAccessToken = async () => {
    try {
      const body = {
        password: process.env.REACT_APP_PASSWORD_SUPPER_SET,
        provider: "db",
        refresh: true,
        username: process.env.REACT_APP_USERNAME_SUPPER_SET,
      };

      const response = await fetch(`https://impact.apollo.vn/api/v1/security/login`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonResponse = await response.json();
      return jsonResponse?.access_token;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchGuestToken = async () => {
    const token = await fetchAccessToken();
    try {
      const body = {
        user: {
          username: "embedded",
          first_name: "embedded",
          last_name: "embedded",
        },
        resources: [
          {
            type: "dashboard",
            id: "13d71176-cb6d-4971-b8a8-b15bcafc8666",
          },
        ],
        rls: [
          {
            clause: `teacherid=${userId}`,
          },
        ],
      };
      const response = await fetch(`https://impact.apollo.vn/api/v1/security/guest_token/`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const jsonResponse = await response.json();
      return jsonResponse?.token;
    } catch (error) {
      console.error(error);
    }
  };

  const initEmbedDashboard = () => {
    embedDashboard({
      id: "13d71176-cb6d-4971-b8a8-b15bcafc8666",
      supersetDomain: "https://impact.apollo.vn/",
      mountPoint: document.getElementById("sen-superset-container"),
      fetchGuestToken: () => fetchGuestToken(),
      dashboardUiConfig: {
        hideTitle: false,
        hideChartControls: true,
        hideTab: true,
        filters: {
          expanded: false,
        },
      },
    }).then(() => {
      document.getElementById("sen-superset-container").children[0].style.width = "100%";
      document.getElementById("sen-superset-container").children[0].style.height = "100vh";
      document.getElementById("sen-superset-container").children[0].style.border = "none";
    });
  };

  useEffect(() => {
    initEmbedDashboard();
  }, []);

  return (
    <DashboardLayout>
      <div id="sen-superset-container" style={{ width: "100%", height: "100%", padding: "20px" }} />
    </DashboardLayout>
  );
};

export default MyImpact;
