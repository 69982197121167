import * as React from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import MDInput from "components/MDInput";
import { useUpdateBannerMutation } from "shared/redux/endpoints/banner";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxWidth: "min(90%, 80vh)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
};

export default function EditLink({ open, handleClose, bannerId, linkUrl, refetch }) {
  const [link, setLink] = React.useState(linkUrl);
  const [updateBanner, { isLoading }] = useUpdateBannerMutation();

  React.useEffect(() => {
    setLink(linkUrl);
  }, [linkUrl]);

  React.useEffect(() => {
    return () => setLink("");
  }, []);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox component="form" sx={style}>
        <MDTypography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ p: 3, pb: 2, fontFamily: "Lexend, san-serif" }}
        >
          Link
        </MDTypography>
        <MDBox sx={{ px: 3, pb: 2, display: "flex", alignItems: "center", gap: 2 }}>
          <MDInput
            sx={{ width: "400px", maxWidth: "100%" }}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" sx={{ p: 3, pt: 2, width: "100%" }}>
          <Button
            variant="outlined"
            sx={({ palette, functions: { pxToRem } }) => ({
              mr: 2,
              color: palette.textColors.primary,
              borderColor: `${palette.textColors.primary}!important`,
              fontFamily: "Lexend, san-serif",
              height: pxToRem(30),
              minHeight: pxToRem(30),
            })}
            role={undefined}
            tabIndex={-1}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={({ functions: { pxToRem } }) => ({
              color: "#FFFFFF",
              backgroundColor: "#5E72E4",
              fontFamily: "Lexend, san-serif",
              height: pxToRem(30),
              minHeight: pxToRem(30),
            })}
            onClick={async () => {
              const response = await updateBanner({
                id: bannerId,
                linkUrl: link,
              });
              if (response?.data?.status) {
                handleClose();
                toast.success("Update banner link successfully", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  style: {
                    fontFamily: "Lexend",
                    fontSize: 14,
                  },
                });
                refetch?.();
              }
            }}
          >
            Submit
          </Button>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

EditLink.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  bannerId: PropTypes.string,
  linkUrl: PropTypes.string,
  refetch: PropTypes.func,
};
