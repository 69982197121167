import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { useState, useEffect, useMemo } from "react";
import { Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import moment from "moment";
import MDTypography from "components/MDTypography";
import { noteTypography } from "shared/styles/style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: 24,
  outline: 0,
  maxWidth: 400,
};

export const DateSelect = ({
  // eslint-disable-next-line
  onChangeStartDate,
  // eslint-disable-next-line
  onChangeEndDate,
  // eslint-disable-next-line
  disable,
  // eslint-disable-next-line
  defaultEndDate,
  // eslint-disable-next-line
  defaultStartDate,
}) => {
  const [value, onChange] = useState([
    defaultStartDate ?? moment().format("DD-MM-YYYY"),
    defaultEndDate ?? moment().format("DD-MM-YYYY"),
  ]);

  useEffect(() => {
    onChangeStartDate?.(value[0]);
    onChangeEndDate?.(value[1]);
  }, [value]);

  const [state, setState] = useState([
    {
      startDate: moment(value[0]).toDate(),
      endDate: moment(value[1]).toDate(),
      key: "selection",
    },
  ]);

  const [isOpen, setOpen] = useState(false);
  const title = useMemo(() => {
    return `${moment(state[0].startDate).format("MMM DD, YYYY")} - ${moment(
      state[0].endDate
    ).format("MMM DD, YYYY")}`;
  }, [state]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={({ breakpoints, functions: { pxToRem } }) => ({
          borderRadius: pxToRem(12),
          padding: pxToRem(4),
          background: "#F6F9FC",
          width: 200,
          maxWidth: "100%",
          [breakpoints.down(1600)]: {
            width: 160,
          },
          [breakpoints.down("md")]: {
            alignSelf: "center",
          },
        })}
      >
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={({ breakpoints, functions: { pxToRem } }) => ({
            height: pxToRem(35),
            borderRadius: pxToRem(8),
            background: "white",
            boxShadow: "0px 2px 6px 0px #00000040",
            cursor: "pointer",

            [breakpoints.down(768)]: {
              width: "100%",
            },
          })}
          onClick={() => {
            if (!disable) setOpen(true);
          }}
        >
          <MDTypography
            sx={({ breakpoints }) => ({
              ...noteTypography(breakpoints),
              lineHeight: 1.25,

              textAlign: "center",
            })}
          >
            {title}
          </MDTypography>
        </Grid>
      </Grid>
      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        <MDBox sx={{ ...style }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setState([item.selection]);
              onChange([moment(item.selection.startDate), moment(item.selection.endDate)]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
            retainEndDateOnFirstSelection
            minDate={moment().add(-1, "year").startOf("year").toDate()}
            maxDate={moment().add(1, "year").endOf("year").toDate()}
          />
        </MDBox>
      </Modal>
    </>
  );
};
