import PropTypes from "prop-types";

export const FileIcon = ({ width = 14, height = 16, fill = "#8392AB" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M2.17578 13.5195C0.5625 11.8516 0.589844 9.19922 2.20312 7.55859L7.94531 1.67969C9.14844 0.449219 11.1172 0.449219 12.3477 1.67969C13.5234 2.91016 13.5508 4.90625 12.3477 6.13672L7.34375 11.25C6.52344 12.0703 5.18359 12.0703 4.39062 11.2227C3.59766 10.4023 3.625 9.08984 4.41797 8.29688L8.35547 4.27734C8.51953 4.11328 8.79297 4.08594 8.98438 4.27734L9.58594 4.87891C9.77734 5.04297 9.77734 5.31641 9.61328 5.50781L5.67578 9.5C5.53906 9.63672 5.53906 9.88281 5.64844 10.0195C5.78516 10.1289 5.97656 10.1289 6.08594 10.0195L11.0898 4.90625C11.6094 4.35938 11.6094 3.45703 11.0898 2.91016C10.5703 2.39062 9.72266 2.39062 9.20312 2.91016L3.46094 8.78906C2.50391 9.74609 2.50391 11.332 3.43359 12.2891C4.36328 13.2461 5.86719 13.2461 6.79688 12.2891L11.5 7.47656C11.6641 7.3125 11.9375 7.3125 12.1016 7.47656L12.7305 8.10547C12.9219 8.26953 12.9219 8.54297 12.7578 8.70703L8.05469 13.5195C6.41406 15.1875 3.78906 15.1602 2.17578 13.5195Z"
        fill={fill}
      />
    </svg>
  );
};

FileIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
