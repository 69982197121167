import PropTypes from "prop-types";

export const RocketIcon = ({ width = 18, height = 18, fill = "#8392AB" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6848 0.318174C17.6105 0.243369 17.5212 0.185068 17.4229 0.147074C17.3245 0.10908 17.2193 0.0922475 17.114 0.0976738C6.59301 0.646674 2.70651 10.0187 2.66901 10.1132C2.61412 10.2495 2.6005 10.399 2.62984 10.543C2.65917 10.687 2.73017 10.8192 2.83401 10.9232L7.07676 15.1659C7.18124 15.2703 7.31419 15.3414 7.45895 15.3705C7.60371 15.3996 7.75383 15.3853 7.89051 15.3294C7.98426 15.2912 17.306 11.3612 17.9015 0.889674C17.9075 0.784536 17.8913 0.679308 17.854 0.580842C17.8166 0.482375 17.759 0.392877 17.6848 0.318174V0.318174ZM11.5603 8.56067C11.3505 8.7705 11.0832 8.9134 10.7922 8.97132C10.5013 9.02923 10.1996 8.99955 9.92552 8.88604C9.6514 8.77252 9.41711 8.58026 9.25226 8.33358C9.08742 8.08689 8.99944 7.79687 8.99944 7.50017C8.99944 7.20348 9.08742 6.91345 9.25226 6.66677C9.41711 6.42009 9.6514 6.22783 9.92552 6.11431C10.1996 6.00079 10.5013 5.97111 10.7922 6.02903C11.0832 6.08694 11.3505 6.22985 11.5603 6.43967C11.8415 6.72097 11.9994 7.10243 11.9994 7.50017C11.9994 7.89792 11.8415 8.27938 11.5603 8.56067V8.56067Z"
        fill={fill}
      />
      <path
        d="M0.83475 14.1328C1.03257 13.9336 1.26776 13.7755 1.52684 13.6674C1.78592 13.5593 2.06379 13.5034 2.34451 13.5029C2.62523 13.5025 2.90328 13.5574 3.16273 13.6646C3.42217 13.7718 3.6579 13.9292 3.8564 14.1277C4.0549 14.3262 4.21227 14.5619 4.31947 14.8213C4.42668 15.0808 4.48162 15.3588 4.48114 15.6396C4.48066 15.9203 4.42477 16.1982 4.31668 16.4572C4.20859 16.7163 4.05043 16.9515 3.85125 17.1493C3.018 17.9826 0 17.9998 0 17.9998C0 17.9998 0 14.9653 0.83475 14.1328Z"
        fill={fill}
      />
      <path
        d="M7.76751 1.75523C6.71042 1.57125 5.62457 1.64449 4.60176 1.96876C3.57895 2.29303 2.64925 2.8588 1.89126 3.61823C1.49016 4.02305 1.14143 4.47659 0.853257 4.96823C0.768872 5.11152 0.734499 5.27879 0.755542 5.44374C0.776586 5.60869 0.851851 5.76197 0.969507 5.87948L2.46126 7.37198C3.84742 5.1721 5.64992 3.26413 7.76751 1.75523V1.75523Z"
        fill={fill}
      />
      <path
        d="M16.2442 10.2324C16.4282 11.2895 16.3549 12.3754 16.0307 13.3982C15.7064 14.421 15.1406 15.3507 14.3812 16.1087C13.9764 16.5098 13.5228 16.8585 13.0312 17.1467C12.8879 17.2311 12.7206 17.2654 12.5557 17.2444C12.3907 17.2233 12.2375 17.1481 12.1199 17.0304L10.6274 15.5387C12.8273 14.1525 14.7353 12.35 16.2442 10.2324Z"
        fill={fill}
      />
    </svg>
  );
};

RocketIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
