import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

export const StatusList = ({ list }) => (
  <MDBox
    sx={{
      flexGrow: 1,
    }}
  >
    {list.map((item, index) => (
      <Grid
        key={index}
        container
        sx={{ width: "100%", borderBottom: "1px solid #DEE2E6" }}
        alignItems="center"
        p={0.5}
      >
        <Grid xs="auto" item>
          <MDBox
            sx={({ breakpoints, functions: { pxToRem } }) => ({
              width: pxToRem(24),
              height: pxToRem(24),
              borderRadius: pxToRem(8),
              background: item.color,

              [breakpoints.down(1600)]: {
                width: pxToRem(20),
                height: pxToRem(20),
                borderRadius: pxToRem(4),
              },
            })}
          ></MDBox>
        </Grid>
        <Grid xs item ml={{ xs: 1, xxxl: 2 }}>
          <MDTypography
            sx={({ breakpoints, typography }) => ({
              fontFamily: typography.lexendFont,
              fontSize: 16,
              lineHeight: 1.625,
              fontWeight: 600,
              letterSpacing: "-0.8px",

              [breakpoints.down(1600)]: {
                fontSize: 12,
              },
            })}
          >
            {item.label}
          </MDTypography>
        </Grid>
        <Grid xs="auto" item>
          <MDTypography
            sx={({ typography }) => ({
              fontFamily: typography.lexendFont,
              fontSize: 12,
              lineHeight: 1.25,
              fontWeight: 400,
              letterSpacing: "-0.8px",
            })}
          >
            {item.percent} %
          </MDTypography>
        </Grid>
      </Grid>
    ))}
  </MDBox>
);

StatusList.propTypes = {
  list: PropTypes.array.isRequired,
};
