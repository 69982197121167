import { Autocomplete, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DateSelect } from "../../DateSelect/temp3";
import dayjs from "dayjs";
import { useLazyGetEppQuery } from "shared/redux/endpoints/assistant";
import { useEffect, useState } from "react";
import moment from "moment";
import FormField from "layouts/pages/account/components/FormField";
import { useGetEppCenterQuery } from "shared/redux/endpoints/assistant";
import ChevronDownIcon from "assets/images/apollo-english/chevron-down.svg";
import typography from "assets/theme/base/typography";

import DataTable from "./table";
import { titleTypography } from "shared/styles/style";

const customStyle = {
  minWidth: "160px",
  "& *": {
    fontFamily: "'Lexend', san-serif !important",
  },
  "& .MuiOutlinedInput-root": {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  "& input, & input::placeholder": {
    fontSize: "12px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: typography.lexendFont,
  },

  "& input": {
    paddingLeft: `14px !important`,
  },
};

function EPP() {
  const startOfMonth = moment().startOf("month").format("MM-DD-YYYY");
  const endOfMonth = moment().endOf("month").format("MM-DD-YYYY");
  const [centre, setCentre] = useState();
  const [listCentre, setListCentre] = useState([]);
  const [trigger, { isFetching }] = useLazyGetEppQuery();
  const { data } = useGetEppCenterQuery();
  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(0);

  const [start, setStart] = useState(startOfMonth);
  const [end, setEnd] = useState(endOfMonth);
  const [dataTable, setDataTable] = useState({
    columns: [
      { Header: "Student Code", accessor: "studentCode", width: "15%" },
      { Header: "Student Name", accessor: "studentName", width: "20%" },
      { Header: "Nickname", accessor: "nickname", width: "5%" },
      { Header: "Class", accessor: "class", width: "12.5%" },
      { Header: "Group KOC", accessor: "groupKoc", width: "12.5%" },
      { Header: "KOC", accessor: "koc", width: "12.5%" },
      { Header: "Level", accessor: "level", width: "12.5%" },
      { Header: "Last lesson", accessor: "lastDate", width: "15%" },
      { Header: "Renewal status", accessor: "returnOrNot", width: "15%" },
    ],

    rows: [],
  });

  useEffect(() => {
    const fetchEpp = async () => {
      if ((end && start) || (!end && !start)) {
        const response = await trigger({
          page,
          size: 10,
          start: moment(start).format("DD-MM-YYYY"),
          end: moment(end).format("DD-MM-YYYY"),
          centerCode: centre?.centerCode ?? "",
        });
        if (response.isSuccess && response.data.status) {
          setTotalPage(response.data.data.totalPage);
          setList(response.data.data.data);
        }
      }
    };
    fetchEpp();
  }, [page, start, end, centre]);

  useEffect(() => {
    if (data?.status) {
      setListCentre(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (list) {
      const customDataRow = list.map((item, index) => {
        return {
          studentCode: item?.studentCode,
          studentName: item?.studentName,
          nickname: item?.nickName ?? "",
          class: item?.className,
          groupKoc: item?.groupKOC,
          koc: item?.koc,
          level: item?.level,
          lastDate: moment(item.lastLessonDate).format("DD-MM-YYYY"),
          returnOrNot: item.returnDate ? "Yes" : "No",
        };
      });
      setDataTable((data) => ({
        ...data,
        rows: customDataRow || [],
      }));
    }
  }, [list]);

  return (
    <MDBox mb={3}>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
            EPP list
          </MDTypography>
        </MDBox>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          gap={2.25}
          width="100%"
          height="100%"
          px={3}
          pb={3}
          sx={({ breakpoints }) => ({
            [breakpoints.down("lg")]: {
              justifyContent: "flex-start",
            },
          })}
        >
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              value={centre}
              options={listCentre}
              onChange={(e, v) => {
                setCentre(v);
              }}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              disabled={isFetching}
              getOptionLabel={(item) => item.centerName}
              renderInput={(params) => (
                <FormField
                  {...params}
                  variant="outlined"
                  label="Centre"
                  customStyle={customStyle}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} xxl="auto">
            <DateSelect
              onChangeStartDate={(e) => {
                setStart(e);
              }}
              onChangeEndDate={(e) => {
                setEnd(e);
              }}
              defaultEndDate={dayjs(new Date()).endOf("month").format("MM-DD-YYYY")}
              defaultStartDate={dayjs(new Date()).startOf("month").format("MM-DD-YYYY")}
              disable={isFetching}
            />
          </Grid>
        </Grid>

        <DataTable
          table={dataTable}
          isSorted={false}
          currentPage={page}
          handlePrev={() => setPage((page) => page - 1)}
          handleNext={() => setPage((page) => page + 1)}
          totalPage={totalPage}
          setCurrentPage={setPage}
          isLoading={isFetching}
          isEmpty={list.length === 0}
        />
      </Card>
    </MDBox>
  );
}

export { EPP };
