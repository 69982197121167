import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import { notificationDetailStyle, avatarStyle, usernameStyle } from "./style";
import MDTypography from "components/MDTypography";
import { timeStyle } from "./style";
import { contentStyle } from "./style";
import {
  useLazyGetNotificationDetailQuery,
  useLazyGetNotificationDetailV2Query,
} from "shared/redux/endpoints/notifications";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

function NotificationDetail() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const [getNotiDetail, { data, isFetching }] = useLazyGetNotificationDetailQuery();
  const [getNotiDetailV2, { dataV2 }] = useLazyGetNotificationDetailV2Query();

  useEffect(() => {
    if (type === "unique") {
      getNotiDetailV2({ id });
    } else {
      getNotiDetail({ id });
    }
  }, [id]);

  const notificationDetail = type === "unique" ? dataV2?.data : data?.data || {};

  const timeNoti = moment(notificationDetail?.created).add(7, "hour").fromNow();

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      columnSpacing={3}
      sx={(theme) => notificationDetailStyle(theme)}
    >
      <MDBox display="flex" gap={1.25} alignItems="center" mb={3}>
        <MDBox
          component="img"
          sx={(theme) => avatarStyle(theme)}
          src={
            notificationDetail?.notificationContentEntity?.senderAvatar ||
            "https://demo-lesson.sgp1.digitaloceanspaces.com/ava.png"
          }
        ></MDBox>
        <MDBox display="flex" flexDirection="column">
          <MDTypography sx={(theme) => usernameStyle(theme)}>
            {notificationDetail?.notificationContentEntity?.senderName}
          </MDTypography>
          <MDTypography sx={(theme) => timeStyle(theme)}>{timeNoti}</MDTypography>
        </MDBox>
      </MDBox>

      <MDBox sx={{ maxWidth: "100%" }}>
        <MDBox
          sx={(theme) => contentStyle(theme)}
          dangerouslySetInnerHTML={{
            __html: notificationDetail?.notificationContentEntity?.contentEn.replace(/\n/g, "<br>"),
          }}
        />
      </MDBox>
    </MDBox>
  );
}

export { NotificationDetail };
