import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PropTypes from "prop-types";

import DatabaseIcon from "components/icons/svgs/db.svg";
import IncreaseIcon from "components/icons/IncreaseIcon";
import DecreaseIcon from "components/icons/DecreaseIcon";

import BronzeImage from "assets/images/apollo-english/bronze.jpg";
import SilverImage from "assets/images/apollo-english/silver.jpg";
import GoldImage from "assets/images/apollo-english/gold.jpg";
import NotFound from "assets/images/apollo-english/not-found.png";
import {
  avatarAltStyle,
  avatarContainerStyle,
  containerStyle,
  rankStyle,
  rankingContainerStyle,
  rankingLogoImageStyle,
  rankingLogoStyle,
  rankingStyle,
  rankingSubTitleStyle,
  rankingUserStyle,
  renderChangeStyle,
  tagStyle,
  titleStyle,
  userInfoStyle,
  usernameStyle,
  pointStyle,
} from "./style";
import { useMemo, useState } from "react";
import { descriptionTypography } from "../../../../shared/styles/style";
import { WarningAmberOutlined } from "@mui/icons-material";

function Leaderboard({ header, tags, subtitle, ranking, users, data, id }) {
  const renderRanking = (ranking) => {
    switch (ranking) {
      case 1:
        return <MDBox component="img" src={GoldImage} />;
      case 2:
        return <MDBox component="img" src={SilverImage} />;
      case 3:
        return <MDBox component="img" src={BronzeImage} />;
      default:
        return <span>#{ranking}</span>;
    }
  };

  const mapBg = (rank) => {
    switch (rank) {
      case 1:
        return "#63B3ED";
      case 2:
        return "#CED4DA";
      case 3:
        return "linear-gradient(338deg, #2DCEC6 27.53%, #2DCE94 61.45%)";
      case 4:
        return "linear-gradient(92deg, #FBB140 1.1%, #FB6340 99.77%)";
      case 5:
        return "linear-gradient(338deg, #2DCEC6 27.53%, #2DCE94 61.45%)";
      default:
        return "#000000";
    }
  };

  const findRank = (array, number) => {
    // Create a set to store unique numbers in the order of their first appearance
    const uniqueNumbers = [...new Set(array)];

    // Create a map to store ranks of unique numbers
    const rankMap = new Map();

    // Assign ranks based on the order of first appearance
    uniqueNumbers.forEach((num, index) => {
      rankMap.set(num, index + 1); // Assigning 1-based rank
    });

    // Return the rank of the specified number
    return rankMap.get(number);
  };

  const renderChange = (value) => {
    if (value === 0) return;
    return (
      <MDBox sx={(theme) => renderChangeStyle(theme, { value })}>
        {value > 0 && "+"}
        {value} {value > 0 ? <IncreaseIcon /> : <DecreaseIcon />}
      </MDBox>
    );
  };

  const [activeTab, setActiveTab] = useState(0);

  const leaderboard = useMemo(() => {
    if (header.includes("TEACHER")) {
      return activeTab === 0 ? data?.["topKpiOnline"] : data?.["topKpiOffline"];
    } else {
      return activeTab === 0
        ? data?.["topKpiCenterAcademic"]
        : activeTab === 1
        ? data?.["topKpiCenterBusiness"]
        : data?.["topKpiCenterService"];
      // return data?.["topKpiCenter"];
    }
  }, [activeTab, header, data]);

  return (
    <MDBox width="100%" height="100%" sx={(theme) => containerStyle(theme)}>
      <MDBox>
        <MDBox
          sx={({ breakpoints }) => ({
            mb: 3.5,

            [breakpoints.down(1600)]: {
              mb: 2,
            },
          })}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <MDTypography variant="h1" sx={(theme) => titleStyle(theme)}>
                {header}
              </MDTypography>
            </Grid>
            <Grid item>
              {tags ? (
                <MDBox display="flex" gap={0.4} mr="-12px">
                  {tags.map((tag, index) => (
                    <MDBox
                      key={index}
                      sx={(theme) => tagStyle(theme, { active: activeTab === index })}
                      onClick={() => {
                        setActiveTab(index);
                      }}
                    >
                      {tag}
                    </MDBox>
                  ))}
                </MDBox>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={(theme) => rankingContainerStyle(theme)}>
          <MDBox sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <MDTypography variant="p" sx={(theme) => rankingSubTitleStyle(theme)}>
              {subtitle}
            </MDTypography>

            <MDTypography variant="p" sx={(theme) => rankingStyle(theme)}>
              #
              {/* {id === 1
                ? activeTab === (data?.online ? 0 : 1)
                  ? data?.teacherRank
                  : ""} */}
              {activeTab === 0
                ? `${data?.centerAcademicRank} (${data?.centerAcademicName})`
                : activeTab === 1
                ? `${data?.centerBusinessRank} (${data?.centerBusinessName})`
                : `${data?.centerServiceRank} (${data?.centerServiceName})`}
            </MDTypography>
          </MDBox>

          <MDBox sx={(theme) => rankingLogoStyle(theme)}>
            <MDBox
              component="img"
              src={DatabaseIcon}
              sx={(theme) => rankingLogoImageStyle(theme)}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        sx={({ breakpoints, functions: { pxToRem } }) => ({
          maxHeight: 184,
          height: 184,
          overflowY: "scroll",
          pr: 2,

          "&::-webkit-scrollbar": {
            width: pxToRem(6),
            height: 0,
          },

          "&::-webkit-scrollbar-thumb": {
            background: "#D3D3D3",
            borderRadius: pxToRem(3),
          },
          [breakpoints.down(1600)]: {
            maxHeight: 168,
            height: 168,
          },
        })}
      >
        {leaderboard?.length === 0 ? (
          <MDBox
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              fontSize: "24px",

              objectFit: "contain",
            }}
            component="img"
            src={NotFound}
          >
            {/* <WarningAmberOutlined sx={{ color: "#999999" }} fontSize="inherit" />
            <MDTypography
              sx={({ breakpoints }) => ({
                ...descriptionTypography(breakpoints),
              })}
            >
              Data not found
            </MDTypography> */}
          </MDBox>
        ) : null}
        {(leaderboard ?? []).map((user, index, self) => {
          const scores = self.map((item) => item.totalKpi);
          const rank = findRank(scores, user.totalKpi);
          const username = user.fullname || "";
          const centerName = user.centerName || "";
          return (
            <MDBox
              key={index}
              sx={(theme) => rankingUserStyle(theme, { notLast: index !== leaderboard.length - 1 })}
            >
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={(theme) => rankStyle(theme)}
              >
                {renderRanking(rank)}
              </MDBox>

              <MDBox sx={(theme) => userInfoStyle(theme)}>
                {user.avatar ? (
                  <MDBox sx={(theme) => avatarContainerStyle(theme)}>
                    <MDBox
                      component="img"
                      src={user.avatar}
                      width="100%"
                      height="100%"
                      borderRadius="50%"
                      display="block"
                    />
                    {/*<MDBox sx={(theme) => statusStyle(theme, { userOnline: user.online })}></MDBox>*/}
                  </MDBox>
                ) : user.centerId && id === 0 ? (
                  <MDBox sx={(theme) => avatarAltStyle(theme, { bg: mapBg(rank) })}>
                    <span>{user.centerId}</span>
                  </MDBox>
                ) : null}

                <MDTypography sx={(theme) => usernameStyle(theme)}>
                  {id === 0 ? centerName : username}
                </MDTypography>
              </MDBox>
              <MDTypography sx={(theme) => pointStyle(theme)}>{user?.totalKpi}</MDTypography>
              {/*{renderChange(user.change)}*/}
            </MDBox>
          );
        })}
      </MDBox>
    </MDBox>
  );
}

Leaderboard.defaultProps = {
  badges: false,
  subtitle: "",
  users: [],
};

Leaderboard.propTypes = {
  header: PropTypes.string.isRequired,
  tags: PropTypes.any,
  subtitle: PropTypes.string,
  ranking: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.any,
  id: PropTypes.number.isRequired,
};

export default Leaderboard;
