/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { active, transparentSidenav, whiteSidenav, darkMode, miniSidenav } = ownerState;

  const { white, transparent, dark, grey } = palette;
  const { md } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem, rgba } = functions;

  return {
    borderLeft: active ? "4px solid #1576BC" : "4px solid transparent",
    color: (transparentSidenav && !darkMode) || whiteSidenav ? dark.main : white.main,
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: miniSidenav ? pxToRem(36) : pxToRem(74),
    padding: `${pxToRem(8)} 0`,
    margin: `${pxToRem(0)} ${pxToRem(miniSidenav ? 8 : 24)}`,
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: active && !whiteSidenav && !darkMode && !transparentSidenav ? md : "none",
    [breakpoints.up("xl")]: {
      transition: transitions.create(["box-shadow", "background-color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },

    "&:hover, &:focus": {
      borderLeft: "4px solid #1576BC",
    },

    [breakpoints.down(1600)]: {
      padding: `${pxToRem(6)} 0`,
      margin: `${pxToRem(0)} ${pxToRem(miniSidenav ? 6 : 18)}`,
    },
    [breakpoints.down(768)]: {
      height: miniSidenav ? pxToRem(30) : pxToRem(64),
      margin: `${pxToRem(0)} ${pxToRem(miniSidenav ? 8 : 20)}`,
    },
  };
}

function collapseIconBox(theme, ownerState) {
  const { palette, transitions, borders, functions, breakpoints } = theme;
  const { transparentSidenav, whiteSidenav, darkMode } = ownerState;

  const { white, dark } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    color: (transparentSidenav && !darkMode) || whiteSidenav ? dark.main : white.main,
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    "& svg, svg g": {
      color: transparentSidenav || whiteSidenav ? dark.main : white.main,
    },

    [breakpoints.down(1600)]: {
      transform: "scale(0.75)",
    },
  };
}

const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
  color: active ? white.main : gradients.dark.state,
});

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav, transparentSidenav, active } = ownerState;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(0),

    [breakpoints.up("xl")]: {
      display: miniSidenav || (miniSidenav && transparentSidenav) ? "none" : "block",
      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: 0,
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      color: active ? "#1576BC" : "#ADB5BD",
      fontFamily: typography.lexendFont,
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "162.5%",
      letterSpacing: -0.8,
    },

    [breakpoints.down(1600)]: {
      "& span": {
        fontSize: 12,
      },
    },
  };
}

function collapseArrow(theme, ownerState) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const { noCollapse, transparentSidenav, whiteSidenav, miniSidenav, open, active, darkMode } =
    ownerState;

  const { white, dark } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? "translateY(-50%) rotate(0)" : "translateY(-50%) rotate(-180deg)",
    color: () => {
      let colorValue;

      if (transparentSidenav && darkMode) {
        colorValue = open || active ? white.main : rgba(white.main, 0.25);
      } else if (transparentSidenav || whiteSidenav) {
        colorValue = open || active ? dark.main : rgba(dark.main, 0.25);
      } else {
        colorValue = open || active ? white.main : rgba(white.main, 0.5);
      }

      return colorValue;
    },

    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    position: "absolute",
    right: pxToRem(30),
    top: "50%",

    [breakpoints.up("xl")]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? "none !important"
          : "block !important",
    },
  };
}

function collapseBagde(theme, ownerState) {
  const { palette, functions } = theme;

  const { white } = palette;
  const { pxToRem } = functions;
  const { miniSidenav } = ownerState;

  return {
    position: "absolute",
    height: pxToRem(18),
    width: pxToRem(18),
    backgroundColor: "#F56565",
    border: `2px solid ${white.main}`,
    borderRadius: "50%",
    top: pxToRem(8),
    right: miniSidenav ? pxToRem(24) : pxToRem(76),

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
}

function collapseBadgeText(theme) {
  const { palette, typography } = theme;

  const { white } = palette;

  return {
    color: white.main,
    fontFeatureSettings: `'clig' off, 'liga' off`,
    fontFamily: typography.lexendFont,
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "125%",
  };
}

export {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
  collapseBagde,
  collapseBadgeText,
};
