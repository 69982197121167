import * as Yup from "yup";

export const form = {
  formId: "reset-password-validate-form",
  formField: {
    email: {
      name: "email",
      label: "Your Apollo Email",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
      apolloInvalidMsg: "Please use your Apollo email",
    },
  },
};

const {
  formField: { email },
} = form;

export const initialValues = {
  [email.name]: "",
};

export const validations = Yup.object().shape({
  [email.name]: Yup.string()
    .required(email.errorMsg)
    .email(email.invalidMsg)
    .test("isApolloEmail", email.apolloInvalidMsg, function (value) {
      return value.endsWith("@apollo.edu.vn");
    }),
});
