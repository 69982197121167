import { Table, TableBody, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HubImage from "assets/images/apollo-english/HUB.png";
import PropTypes from "prop-types";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";

// eslint-disable-next-line
function Offtrack({ data }) {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={3}
      sx={({ breakpoints, functions: { pxToRem } }) => ({
        borderRadius: pxToRem(16),
        background: "#fff",
        padding: `${pxToRem(36)} 0 0`,

        [breakpoints.down("xxl")]: {
          rowGap: 2.5,
          flexDirection: "column",
        },

        [breakpoints.down("md")]: {
          rowGap: 2,
        },
      })}
    >
      <MDBox
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        sx={({ breakpoints }) => ({
          rowGap: 2,
          [breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        })}
      >
        <MDTypography
          sx={({ breakpoints }) => ({
            ...titleTypography(breakpoints),
            lineHeight: 1.625,
            letterSpacing: "-0.8px",
            paddingLeft: "12px",
            color: "#2D3748",
          })}
        >
          Offtrack record
        </MDTypography>
      </MDBox>

      <MDBox width="100%">
        <MDBox
          data-horizontal-scroll
          sx={({ breakpoints, functions: { pxToRem } }) => ({
            overflowX: "scroll",
            maxHeight: pxToRem(530),
            width: "100%",
            background: "#fff",

            "&::-webkit-scrollbar": {
              width: pxToRem(6),
              height: 0,
            },

            "&::-webkit-scrollbar-thumb": {
              background: "#8898aa",
              borderRadius: pxToRem(3),
            },

            [breakpoints.down("md")]: {
              "&::-webkit-scrollbar": {
                display: "block",
              },
            },
          })}
        >
          <Table
            sx={({ breakpoints, typography, functions: { pxToRem } }) => ({
              color: "#333",
              height: "100%",
              width: "800px",
              minWidth: "100%",
              fontFamily: typography.lexendFont,
              fontSize: 14,
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: pxToRem(0.42),
              "& *": {
                ...descriptionTypography(breakpoints),
              },
            })}
          >
            <MDBox component="thead" sx={{ borderBottom: "1px solid #DEE2E6" }}>
              <TableRow
                sx={({ breakpoints }) => ({
                  height: "56px",
                  textAlign: "left",
                  fontWeight: 700,
                  fontSize: 12,
                  lineHeight: 1.5,
                  "& *": {
                    textTransform: "uppercase",
                    ...descriptionTypography(breakpoints),
                  },
                })}
              >
                <MDBox
                  component="th"
                  sx={() => ({
                    paddingLeft: 2,
                  })}
                >
                  Center
                </MDBox>
                <MDBox
                  component="th"
                  sx={() => ({
                    paddingLeft: 1,
                  })}
                >
                  Level
                </MDBox>
                <MDBox
                  component="th"
                  sx={() => ({
                    paddingLeft: 1,
                  })}
                >
                  Classcode
                </MDBox>
                <MDBox
                  component="th"
                  sx={() => ({
                    paddingLeft: 1,
                  })}
                >
                  Classname
                </MDBox>
                <MDBox
                  component="th"
                  sx={() => ({
                    paddingLeft: 1,
                  })}
                >
                  Offtrack category
                </MDBox>
                <MDBox
                  component="th"
                  sx={() => ({
                    paddingLeft: 1,
                  })}
                >
                  Duration
                </MDBox>
                <MDBox
                  component="th"
                  sx={() => ({
                    paddingLeft: 1,
                  })}
                >
                  Start-End
                </MDBox>
              </TableRow>
            </MDBox>

            <TableBody
              sx={({ breakpoints, functions: { pxToRem } }) => ({
                maxHeight: pxToRem(454),
                height: "auto",
                overflowY: "scroll",
                "& *": {
                  ...descriptionTypography(breakpoints),
                },
              })}
            >
              {data.map((item, index) => (
                <TableRow
                  key={index}
                  sx={({ typography }) => ({
                    height: "50px",
                    textAlign: "left",
                    fontFamily: typography.lexendFont,
                    fontWeight: 700,
                    fontSize: 12,
                    lineHeight: 1.5,
                    color: "#8392AB",
                    borderBottom: "1px solid #DEE2E6",
                  })}
                >
                  <MDBox
                    component="td"
                    sx={{
                      paddingLeft: 2,
                      height: "100%",
                    }}
                  >
                    <MDTypography
                      variant="span"
                      sx={({ breakpoints }) => ({
                        lineHeight: 1.625,
                        ...descriptionTypography(breakpoints),
                      })}
                    >
                      {item.centerName}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    component="td"
                    sx={() => ({
                      paddingLeft: 1,
                    })}
                  >
                    <MDTypography
                      variant="span"
                      sx={({ breakpoints }) => ({
                        lineHeight: 1.625,
                        ...descriptionTypography(breakpoints),
                      })}
                    >
                      {item.levelName}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    component="td"
                    sx={() => ({
                      paddingLeft: 1,
                    })}
                  >
                    <MDTypography
                      variant="span"
                      sx={({ breakpoints }) => ({
                        lineHeight: 1.625,
                        ...descriptionTypography(breakpoints),
                      })}
                    >
                      {item.classCode}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    component="td"
                    sx={() => ({
                      paddingLeft: 1,
                    })}
                  >
                    <MDTypography
                      variant="span"
                      sx={({ breakpoints }) => ({
                        lineHeight: 1.625,
                        ...descriptionTypography(breakpoints),
                      })}
                    >
                      {item.className}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    component="td"
                    sx={() => ({
                      paddingLeft: 1,
                    })}
                  >
                    <MDTypography
                      variant="span"
                      sx={({ breakpoints }) => ({
                        lineHeight: 1.625,
                        ...descriptionTypography(breakpoints),
                      })}
                    >
                      {item.engagementStatus === "OFFTRACK" ? "Engagement" : ""}
                      {item.engagementStatus === "OFFTRACK" &&
                      (item.performanceStatus === "OFFTRACK" ||
                        item.homeLearningStatus === "OFFTRACK")
                        ? " | "
                        : ""}
                      {item.performanceStatus === "OFFTRACK" ? "Performance" : ""}
                      {item.homeLearningStatus === "OFFTRACK" &&
                      (item.performanceStatus === "OFFTRACK" ||
                        item.engagementStatus === "OFFTRACK")
                        ? " | "
                        : ""}
                      {item.homeLearningStatus === "OFFTRACK" ? "Home Learning" : ""}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    component="td"
                    sx={() => ({
                      paddingLeft: 1,
                    })}
                  >
                    <MDTypography
                      variant="span"
                      sx={({ breakpoints }) => ({
                        lineHeight: 1.625,
                        ...descriptionTypography(breakpoints),
                      })}
                    >
                      {item.duration}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    component="td"
                    sx={() => ({
                      paddingLeft: 1,
                    })}
                  >
                    <MDTypography
                      variant="span"
                      sx={({ breakpoints }) => ({
                        lineHeight: 1.625,
                        ...descriptionTypography(breakpoints),
                      })}
                    >
                      {`${item.cutOffDate} ~ ${item.endDate}`}
                    </MDTypography>
                  </MDBox>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Offtrack.propTypes = {
  data: PropTypes.any,
};

export { Offtrack };
