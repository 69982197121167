import PropTypes from "prop-types";

export const ChevonRight = ({ width = 14, height = 16, fill = "#8392AB" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={23} viewBox="0 0 17 23" fill="none">
      <path
        d="M3.23177 1.75161C2.57656 1.34283 1.75312 1.32935 1.08464 1.71118C0.416146 2.09302 0 2.81177 0 3.59341V19.4059C0 20.1876 0.416146 20.9063 1.08464 21.2881C1.75312 21.67 2.57656 21.652 3.23177 21.2477L15.9818 13.3415C16.6148 12.9506 17 12.2543 17 11.4997C17 10.745 16.6148 10.0532 15.9818 9.65786L3.23177 1.75161Z"
        fill="#2DCE89"
      />
    </svg>
  );
};

ChevonRight.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
