import Card from "@mui/material/Card";
import { CircularProgress, Icon, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "shared/layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/apollo-english/Login.png";
import theme from "assets/theme";
import { useFormik } from "formik";
import { form, initialValues, validations } from "./form";
import ResetPasswordUserIcon from "components/icons/ResetPasswordUserIcon";
import { useState } from "react";
import typography from "assets/theme/base/typography";
import { useSendMailResetPasswordMutation } from "shared/redux/endpoints/auth";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxWidth: "min(90%, 80vh)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
  p: 3,
};

const inputStyle = {
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    height: "100%",
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    padding: "0 0 0 20px",
  },

  "& input::placeholder": {
    fontFamily: typography.lexendFont,
  },

  "& label": {
    fontSize: 14,
    lineHeight: "16px",
    fontFamily: typography.lexendFont,
    color: "#A0AEC0",
    left: 6,
  },

  "& legend": {
    marginLeft: 6,
  },
};

function ResetPasswordValidate() {
  const {
    typography: { lexendFont },
  } = theme;

  const { formId, formField } = form;
  const { email } = formField;

  const [sendMail, { isLoading }] = useSendMailResetPasswordMutation();

  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: async (values) => {
      const result = await sendMail({
        username: values.email,
      });

      if (result?.data?.status) {
        toast.success("Reset password email has been sent, please check your mailbox.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            fontFamily: "Lexend",
            fontSize: 14,
          },
        });
      } else {
        toast.error("Can't send email, please recheck your information.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            fontFamily: "Lexend",
            fontSize: 14,
          },
        });
      }
    },
  });

  const emailError = formik.touched.email && Boolean(formik.errors.email);

  const [openModal, setOpenModal] = useState(false);

  return (
    <CoverLayout image={bgImage}>
      <MDBox
        position="absolute"
        width="calc(100% - 48px)"
        left={24}
        zIndex={99}
        sx={({ breakpoints, functions: { pxToRem } }) => ({
          top: pxToRem(90),

          [breakpoints.down("xxl")]: {
            top: pxToRem(70),
          },

          [breakpoints.down("xl")]: {
            top: pxToRem(50),
          },
        })}
      >
        <MDTypography
          textAlign="center"
          color="white"
          sx={({ breakpoints }) => ({
            fontFamily: lexendFont,
            fontWeight: 700,
            fontSize: 48,
            lineHeight: 1.25,
            textSpacing: "-0.8px",

            [breakpoints.down("xl")]: {
              fontSize: 36,
            },

            [breakpoints.down("lg")]: {
              fontSize: 24,
            },
          })}
        >
          Reset password
        </MDTypography>
        <MDTypography
          textAlign="center"
          color="white"
          sx={({ breakpoints }) => ({
            fontFamily: lexendFont,
            fontWeight: 400,
            fontSize: 16,
            lineHeight: 1.5,
            textSpacing: "-0.8px",

            [breakpoints.down("xl")]: {
              fontSize: 12,
            },

            [breakpoints.down("lg")]: {
              fontSize: 10,
            },
          })}
        >
          You will receive an email in maximum 60 seconds (or more)
        </MDTypography>
      </MDBox>
      <Card>
        <MDBox p={{ xs: 2, lg: 3, xl: 4, xxl: 6 }}>
          <MDBox
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
            pb={{ xs: 2, lg: 3 }}
            gap={1}
          >
            <MDBox height={48}>
              <Icon component={ResetPasswordUserIcon} />
            </MDBox>
            <MDBox display="flex" flexDirection="column" justifyContent="space-between">
              <MDTypography
                sx={({ breakpoints }) => ({
                  fontFamily: lexendFont,
                  fontWeight: 600,
                  color: "#172B4D",
                  fontSize: 20,
                  lineHeight: 1.37,
                  letterSpacing: "-0.8px",

                  [breakpoints.down("lg")]: {
                    fontSize: 16,
                  },
                })}
              >
                Can&apos;t log in?
              </MDTypography>
              <MDTypography
                sx={({ breakpoints }) => ({
                  fontFamily: lexendFont,
                  fontWeight: 400,
                  color: "#8392AB",
                  fontSize: 14,
                  lineHeight: 1.5,

                  [breakpoints.down("lg")]: {
                    fontSize: 12,
                  },
                })}
              >
                Restore access to your account
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDTypography
            textAlign="left"
            sx={({ breakpoints, functions: { pxToRem } }) => ({
              color: "#172B4D",
              fontFamily: lexendFont,
              fontWeight: 400,
              fontSize: 14,
              lineHeight: 1.25,
              marginBottom: pxToRem(8),

              [breakpoints.down("xl")]: {
                fontSize: 13,
              },

              [breakpoints.down("lg")]: {
                fontSize: 11,
              },
            })}
          >
            We will send a recovery link to
          </MDTypography>
          <MDBox component="form" role="form" id={formId} onSubmit={formik.handleSubmit}>
            <MDBox mb={{ xs: 2, lg: 3 }}>
              <MDInput
                type={email.type}
                label={email.label}
                name={email.name}
                variant="outlined"
                fullWidth
                placeholder="************"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={emailError}
                sx={({ functions: { pxToRem } }) => ({
                  height: pxToRem(50),
                })}
                customStyle={inputStyle}
                disabled={isLoading}
              />
              {emailError ? (
                <MDTypography
                  sx={{
                    color: "#f44335",
                    fontFamily: lexendFont,
                    fontWeight: 400,
                    fontSize: 10,
                    lineHeight: 1.5,
                  }}
                >
                  {formik.errors.email}
                </MDTypography>
              ) : (
                <></>
              )}
            </MDBox>
            <MDBox mt={{ xs: 2, lg: 3 }} mb={1} display="flex" justifyContent="center">
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                sx={({ typography, functions: { pxToRem } }) => ({
                  height: pxToRem(40),
                  fontSize: 14,
                  lineHeight: 1.5,
                  fontWeight: 700,
                  textTransform: "unset",
                  background: "#5E72E4!important",
                  fontFamily: typography.lexendFont,
                  p: 1,
                })}
                disabled={isLoading}
              >
                {isLoading && (
                  <CircularProgress size={16} style={{ color: "#FFF", marginRight: "8px" }} />
                )}{" "}
                Reset Password
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography
                variant="button"
                sx={({ typography, breakpoints }) => ({
                  fontFamily: typography.lexendFont,
                  fontSize: 14,
                  lineHeight: 1.42,
                  fontWeight: 400,
                  color: "#8392AB",

                  [breakpoints.down("xxl")]: {
                    fontSize: 12,
                  },

                  [breakpoints.down("xl")]: {
                    fontSize: 10,
                  },
                })}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                I don&apos;t have access to my Email
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <MDBox sx={modalStyle}>
          <MDTypography
            sx={() => ({
              fontSize: 13,
              color: "#999999",
            })}
          >
            Please contact IT department for support
          </MDTypography>
        </MDBox>
      </Modal>
    </CoverLayout>
  );
}

export default ResetPasswordValidate;
