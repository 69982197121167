import { descriptionTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";

export const cardMediaStyle = () => ({
  margin: 0,
  borderRadius: 0,
});

export const headerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  padding: pxToRem(16),
  paddingBottom: 0,
  display: "flex",
  justifyContent: "space-between",
  [breakpoints.down(1367)]: {
    padding: pxToRem(12),
  },
});

export const nameStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  ...noteTypography(breakpoints),
  letterSpacing: pxToRem(-0.8),

  [breakpoints.down(1367)]: {
    fontSize: 12,
  },
});

export const tagStyle = ({ typography, breakpoints, functions: { pxToRem } }) => ({
  background: "#FFBDCA",
  borderRadius: pxToRem(4),
  color: "#F5365C",
  padding: `${pxToRem(2)} ${pxToRem(4)}`,
  fontSize: 12,
  fontFamily: typography.lexendFont,
  letterSpacing: pxToRem(-0.8),

  [breakpoints.down(1367)]: {
    fontSize: 9,
  },
});

export const descriptionContainerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  padding: `${pxToRem(8)} ${pxToRem(16)}`,
  [breakpoints.down(1367)]: {
    padding: `${pxToRem(6)} ${pxToRem(12)}`,
  },
});

export const descriptionStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  ...titleTypography(breakpoints),

  whiteSpace: "wrap",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  textOverflow: "ellipsis",
});

export const bottomTextStyle = ({ typography, breakpoints, functions: { pxToRem } }) => ({
  fontSize: 16,
  color: "#8898AA",
  fontFamily: typography.lexendFont,
  letterSpacing: pxToRem(-0.8),

  [breakpoints.down(1367)]: {
    fontSize: 12,
  },
});

export const viewCourseBtnStyle = ({ functions: { pxToRem } }, { disable }) => ({
  borderRadius: "8px",
  border: `1px solid  ${!disable ? "#5E72E4" : "transparent"}`,
  background: !disable ? "#A1A1A1" : "#D1FFEB",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
  display: "flex",
  height: "30px",
  padding: "6px 16px",
  alignItems: "center",
  gap: "4px",
  maxWidth: "120px",
  fontSize: 12,
  marginLeft: pxToRem(20),
  marginBottom: "10px",
  marginTop: "10px",
  fontWeight: 700,
  color: "#5E72E4",
});

export const access = ({ breakpoints }) => ({
  ...descriptionTypography(breakpoints),
  fontWeight: 700,
  backgroundImage: "linear-gradient(121.78deg, #11CDEF 9.06%, #1171EF 88.15%)",
  color: "#fff",
  lineHeight: 1.5,
  padding: "4px 12px !important",

  [breakpoints.down(1600)]: {
    fontSize: 8,
    minHeight: "32px!important",
  },
});
