import PropTypes from "prop-types";

export const VisaIcon = ({ width = 18, height = 18, fill = "#8392AB" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 5.25H7.5V1.5C7.5 0.67125 8.17125 0 9 0C9.82875 0 10.5 0.67125 10.5 1.5V5.25Z"
        fill={fill}
      />
      <path
        d="M17.25 3.75H12V6.75H6V3.75H0.75C0.336 3.75 0 4.086 0 4.5V17.25C0 17.664 0.336 18 0.75 18H17.25C17.664 18 18 17.664 18 17.25V4.5C18 4.086 17.664 3.75 17.25 3.75ZM6 9C6.82875 9 7.5 9.67125 7.5 10.5C7.5 11.3288 6.82875 12 6 12C5.17125 12 4.5 11.3288 4.5 10.5C4.5 9.67125 5.17125 9 6 9ZM3 15C3 13.7573 4.00725 12.75 5.25 12.75H6.75C7.99275 12.75 9 13.7573 9 15H3ZM15 14.25H10.5V12.75H15V14.25ZM15 11.25H10.5V9.75H15V11.25Z"
        fill={fill}
      />
    </svg>
  );
};

VisaIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
