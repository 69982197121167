import { Grid, Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DateSelect } from "../../DateSelect/temp3";
import BellIcon from "assets/images/apollo-english/bell.svg";
import MDButton from "components/MDButton";
import { useCallback, useEffect, useState, useRef } from "react";
import { TodoDetail } from "../../TodoDetail";
import {
  accessLMS,
  alertStyle,
  blockTitle,
  completionTitleStyle,
  myTasksContainerStyle,
  myTasksStyle,
  overdueTextStyle,
  toCompleteTextStyle,
} from "./style";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTasks } from "pages/assistant/hooks/useTasks";
import { ReportPieChart } from "./pieChart";
import { StatusList } from "./statusList";
import { ChevronLeft, ChevronRight, WarningAmber, WarningAmberOutlined } from "@mui/icons-material";
import { pagingItemStyle } from "admin/teacher/components/Table/style";
import moment from "moment";
import { colorsMap } from "pages/assistant/hooks/useTasks";
import { Loading } from "shared/components/ui/Loading";
import { noteTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import { capitalizeFirstLetter } from "pages/assistant/hooks/useTasks";
import { useSearchParams } from "react-router-dom";
import { useLazyGetOoolabPendingReportQuery } from "shared/redux/endpoints/sso";
import NotFound from "assets/images/apollo-english/not-found.png";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";

function MyTasks() {
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const start = searchParams.get("start");
  const end = searchParams.get("end");
  const [isOpenDetail, setOpenDetail] = useState(false);
  const [selectedType, setType] = useState("");
  const [dataFeedback, setDataFeedback] = useState({});
  const [reportId, setReportId] = useState("");

  const [trigger] = useLazyGetOoolabPendingReportQuery();

  const {
    loading,
    tasksPieChart,
    feedbacksPieChart,
    taskLabel,
    feedbackLabel,
    taskOverdue,
    taskOnCompleteToday,
    feedbackOverdue,
    feedbackOnCompleteToday,
    setStart,
    setEnd,
    list,
    totalPage,
    currentPage,
    tabs,
    tabIndex,
    setTabIndex,
    handleNext,
    handlePrev,
    setPage,
    refetch,
    minimumFetchingTime,
  } = useTasks();

  useEffect(() => {
    if (id && type && start && end && list.length > 0) {
      setOpenDetail(true);
      setType(type);
      if (type === "Feedback") {
        setDataFeedback(list[0]);
      } else {
        setReportId(id);
      }
    }
  }, [id, type, start, end, list]);

  useEffect(() => {
    if (list.length === 0) setOpenDetail(false);
  }, [list]);

  const mobileMatches = useMediaQuery("(max-width:768px)");

  const sortList = (list) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const getDeadlineStatus = (item) => {
      const deadline = new Date(item?.task?.deadlineDate ?? item?.feedback?.deadlineDate);
      deadline.setHours(0, 0, 0, 0);

      if (deadline < today) {
        return "passed";
      } else if (deadline.getTime() === today.getTime()) {
        return "today";
      } else {
        return "upcoming";
      }
    };

    list.sort((a, b) => {
      const [statusA, dateA] = [
        getDeadlineStatus(a),
        new Date(a?.task?.deadlineDate ?? a?.feedback?.deadlineDate).getTime(),
      ];
      const [statusB, dateB] = [
        getDeadlineStatus(b),
        new Date(b?.task?.deadlineDate ?? b?.feedback?.deadlineDate).getTime(),
      ];

      if (statusA !== statusB) {
        return statusA === "passed" ? -1 : 1; // Passed first
      }

      return dateB - dateA;
    });

    return list;
  };

  const pageRange = [];
  for (let i = 1; i <= totalPage; i++) {
    pageRange.push(i);
  }

  const filteredPages = pageRange.slice(
    Math.max(currentPage - 2, 0),
    Math.min(currentPage + 3, totalPage + 1)
  );

  const contentRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);

      if (contentRef) {
        clearTimeout(contentRef?.current?.timeout);

        contentRef.current.timeout = setTimeout(() => {
          setIsScrolling(false);
        }, 500);
      }
    };

    const content = contentRef.current;
    content?.addEventListener("scroll", handleScroll);

    return () => {
      content?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const auth = useSelector(selectAuth);
  const role = auth.role;

  return (
    <>
      {role !== "CSO" ? (
        <Grid
          container
          direction="row"
          alignItems="stretch"
          columnSpacing={{ xs: 1.5, xxl: 2, xxxl: 3 }}
          sx={myTasksStyle}
        >
          <Grid container item xs={12} lg={6} xxl={4} gap={1.5}>
            <MDBox sx={myTasksContainerStyle}>
              <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDBox>
                  <MDTypography sx={completionTitleStyle}>
                    Report <br /> Completion
                  </MDTypography>
                  {loading ? (
                    <Skeleton variant="rectangular" height={60} sx={{ width: "100%" }} />
                  ) : (
                    <ReportPieChart data={tasksPieChart} label={taskLabel} />
                  )}
                </MDBox>

                <MDBox
                  display="flex"
                  sx={({ breakpoints }) => ({
                    gap: 2.5,
                    [breakpoints.down(1600)]: {
                      gap: 0,
                    },
                  })}
                >
                  <MDBox>
                    <MDTypography sx={overdueTextStyle}>Overdue: {taskOverdue}</MDTypography>
                    <MDTypography sx={toCompleteTextStyle}>
                      To complete today: {taskOnCompleteToday}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" justifyContent="center" alignItems="center" sx={alertStyle}>
                    <MDBox component="img" src={BellIcon} />
                  </MDBox>
                </MDBox>
              </MDBox>
              {tasksPieChart.length > 0 ? (
                <>
                  <MDBox
                    display="flex"
                    gap={5}
                    mb={2}
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("md")]: {
                        flexDirection: "column",
                      },
                    })}
                  >
                    <StatusList list={tasksPieChart} />
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography
                      sx={({ breakpoints, functions: { pxToRem } }) => ({
                        ...descriptionTypography(breakpoints),
                        lineHeight: 1.625,
                        color: "#344767",
                        letterSpacing: "-0.8px",
                      })}
                      ml={1}
                    >
                      To complete your Report task, go to LMS
                    </MDTypography>
                    <MDButton
                      sx={accessLMS}
                      onClick={async () => {
                        const res = await trigger();
                        if (res?.data?.data?.url) {
                          window.open(res?.data?.data?.url, "_blank");
                        }
                      }}
                    >
                      ACCESS LMS
                    </MDButton>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  sx={({ breakpoints }) => ({
                    fontSize: 36,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "200px",
                    flexGrow: 1,
                    objectFit: "contain",

                    [breakpoints.down(1600)]: {
                      height: "160px",
                    },
                  })}
                  component="img"
                  src={NotFound}
                >
                  {/* <WarningAmberOutlined fontSize="inherit" sx={{ color: "#999999" }} />
                <MDTypography
                  sx={{
                    fontSize: 14,
                    color: "#999999",
                  }}
                >
                  Data is empty
                </MDTypography> */}
                </MDBox>
              )}
            </MDBox>
          </Grid>
          <Grid container item xs={12} lg={6} xxl={4} gap={1.5}>
            <MDBox sx={myTasksContainerStyle}>
              <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDBox>
                  <MDTypography sx={blockTitle}>
                    Feedback <br /> Completion
                  </MDTypography>
                  {loading ? (
                    <Skeleton variant="rectangular" height={60} sx={{ width: "100%" }} />
                  ) : (
                    <ReportPieChart data={feedbacksPieChart} label={feedbackLabel} />
                  )}
                </MDBox>

                <MDBox
                  display="flex"
                  sx={({ breakpoints }) => ({
                    gap: 2.5,
                    [breakpoints.down(1600)]: {
                      gap: 0,
                    },
                  })}
                >
                  <MDBox>
                    <MDTypography sx={overdueTextStyle}>Overdue: {feedbackOverdue}</MDTypography>
                    <MDTypography sx={toCompleteTextStyle}>
                      To complete today: {feedbackOnCompleteToday}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" justifyContent="center" alignItems="center" sx={alertStyle}>
                    <MDBox component="img" src={BellIcon} />
                  </MDBox>
                </MDBox>
              </MDBox>
              {feedbacksPieChart.length > 0 ? (
                <MDBox
                  display="flex"
                  gap={5}
                  mb={2}
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  })}
                >
                  <StatusList list={feedbacksPieChart} />
                </MDBox>
              ) : (
                <MDBox
                  sx={({ breakpoints }) => ({
                    fontSize: 36,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "200px",
                    flexGrow: 1,
                    objectFit: "contain",

                    [breakpoints.down(1600)]: {
                      height: "160px",
                    },
                  })}
                  component="img"
                  src={NotFound}
                >
                  {/* <WarningAmberOutlined fontSize="inherit" sx={{ color: "#999999" }} />
                <MDTypography
                  sx={({ breakpoints }) => ({
                    ...noteTypography(breakpoints),
                  })}
                >
                  Data is empty
                </MDTypography> */}
                </MDBox>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} xxl={4}>
            <MDBox sx={myTasksContainerStyle}>
              <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDBox>
                  <MDTypography
                    sx={({ breakpoints, functions: { pxToRem } }) => ({
                      ...titleTypography(breakpoints),
                      lineHeight: 1.625,
                      letterSpacing: "-0.8px",
                      marginBottom: pxToRem(16),
                    })}
                  >
                    Other task Completion
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      ) : null}

      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={3}
        sx={({ breakpoints, functions: { pxToRem } }) => ({
          borderRadius: pxToRem(16),
          background: "#fff",
          padding: `${pxToRem(24)}`,
          marginBottom: pxToRem(24),

          [breakpoints.down(1600)]: {
            rowGap: 2.5,
            flexDirection: "column",
            padding: pxToRem(18),
            borderRadius: pxToRem(8),
          },

          [breakpoints.down(1366)]: {
            rowGap: 2,
            padding: pxToRem(12),
          },
        })}
      >
        <MDBox
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          gap={2}
          sx={({ breakpoints }) => ({
            mb: 3,
            [breakpoints.down(1600)]: {
              mb: 2,
            },

            [breakpoints.down("md")]: {
              flexDirection: "column",
              rowGap: "16px",
              alignItems: "flex-start",
            },
          })}
        >
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap={{ xs: 2, md: 3 }}
            sx={({ breakpoints }) => ({
              [breakpoints.down("md")]: {
                justifyContent: "space-between!important",
                width: "100%",
              },
            })}
          >
            <MDTypography
              sx={({ breakpoints, functions: { pxToRem } }) => ({
                ...titleTypography(breakpoints),
                lineHeight: 1.37,
                letterSpacing: "-0.8px",
                paddingLeft: "12px",
                minWidth: 120,

                [breakpoints.down(768)]: {
                  minWidth: pxToRem(100),
                },
              })}
            >
              To Do List
            </MDTypography>
            <DateSelect
              onChangeStartDate={(e) => {
                setStart(e);
                searchParams.set("start", moment(e).format("MM-DD-YYYY"));
                setSearchParams(searchParams);
              }}
              onChangeEndDate={(e) => {
                setEnd(e);
                searchParams.set("end", moment(e).format("MM-DD-YYYY"));
                setSearchParams(searchParams);
              }}
              disable={minimumFetchingTime}
              defaultStartDate={start ?? moment().startOf("isoweek").format("MM-DD-YYYY")}
              defaultEndDate={end ?? moment().endOf("isoweek").format("MM-DD-YYYY")}
            />
          </MDBox>
          <Grid
            container
            alignItems="center"
            sx={({ breakpoints, functions: { pxToRem } }) => ({
              borderRadius: pxToRem(12),
              padding: pxToRem(4),
              background: "#F6F9FC",
              width: 600,
              maxWidth: "100%",
              [breakpoints.down(1600)]: {
                width: 400,
              },
              [breakpoints.down("md")]: {
                alignSelf: "center",
              },
            })}
          >
            {tabs.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  xxl={3}
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                  sx={({ breakpoints, functions: { pxToRem } }) => ({
                    height: pxToRem(35),
                    borderRadius: pxToRem(8),
                    background: tabIndex === index ? "white" : "inherite",
                    boxShadow: tabIndex === index ? "0px 2px 6px 0px #00000040" : "unset",
                    cursor: "pointer",
                    padding: `${pxToRem(0)} ${pxToRem(24)}`,

                    [breakpoints.down(768)]: {
                      width: "100%",
                    },
                  })}
                  onClick={() => {
                    if (!minimumFetchingTime) {
                      setTabIndex(index);
                      setOpenDetail(false);
                    }
                  }}
                >
                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...noteTypography(breakpoints),
                      lineHeight: 1.25,
                      ...(minimumFetchingTime && {
                        color: "#999999",
                      }),
                      textAlign: "center",
                    })}
                  >
                    {item}
                  </MDTypography>
                </Grid>
              );
            })}
          </Grid>
        </MDBox>

        <MDBox width="100%">
          <Grid
            container
            width="100%"
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ height: "fit-content" }}
          >
            <Grid item xs={isOpenDetail && !mobileMatches ? 3 : 12}>
              <MDBox
                sx={({ breakpoints, functions: { pxToRem } }) => ({
                  background: "#EDF2F7",
                  padding: pxToRem(24),
                  borderRadius: pxToRem(15),

                  [breakpoints.down(1600)]: {
                    padding: pxToRem(16),
                    borderRadius: pxToRem(8),
                  },
                })}
              >
                <MDTypography
                  sx={({ breakpoints, typography }) => ({
                    ...titleTypography(breakpoints),
                    lineHeight: 1.625,
                    letterSpacing: "-0.8px",
                    marginBottom: "20px",
                  })}
                >
                  Task list
                </MDTypography>
                <MDBox
                  ref={contentRef}
                  display="flex"
                  rowGap={2.5}
                  sx={({ breakpoints, functions: { pxToRem } }) => ({
                    flexDirection: "column",
                    maxHeight: 600,
                    height: 600,
                    overflowY: "scroll",

                    "&::-webkit-scrollbar": {
                      display: isScrolling ? "block" : "none",
                      width: pxToRem(6),
                    },

                    "&::-webkit-scrollbar-thumb": {
                      background: "#8898aa",
                      borderRadius: pxToRem(3),
                    },
                    [breakpoints.down(1600)]: {
                      maxHeight: 400,
                      height: 400,
                    },
                    [breakpoints.down(768)]: {
                      flexDirection: "row",
                      overflow: "scroll",
                      gap: 2.5,
                      height: "fit-content",

                      "&::-webkit-scrollbar": {
                        display: "none !important",
                      },
                    },
                  })}
                >
                  {minimumFetchingTime ? (
                    <MDBox
                      sx={({ breakpoints }) => ({
                        width: "100%",
                        height: 600,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        [breakpoints.down(1600)]: {
                          height: 400,
                        },
                      })}
                    >
                      <Loading style={{ width: 300, height: 200 }} />
                    </MDBox>
                  ) : list.length === 0 ? (
                    <MDBox
                      sx={({ breakpoints }) => ({
                        width: "100%",
                        height: 600,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 36,
                        objectFit: "contain",

                        [breakpoints.down(1600)]: {
                          height: 400,
                        },
                      })}
                      component="img"
                      src={NotFound}
                    >
                      {/* <WarningAmber fontSize="inherit" style={{ color: "#9999" }} />
                      <MDTypography sx={({ breakpoints }) => ({ ...noteTypography(breakpoints) })}>
                        No data found
                      </MDTypography> */}
                    </MDBox>
                  ) : (
                    sortList([...list]).map((item, index) => {
                      const type = item.type;

                      const deadline =
                        type === "Feedback"
                          ? item?.feedback?.deadlineDate
                          : type === "Daily Report"
                          ? item?.deadline
                          : type === "Progress Report"
                          ? item?.deadline
                          : type === "Student at risk"
                          ? item?.endTime
                          : type === "Gradebook Report"
                          ? item?.gradeBookDue
                          : "";

                      const id =
                        type === "Feedback"
                          ? item?.feedback?.feedBackId
                          : type === "Student at risk"
                          ? item?.id
                          : type === "Daily Report"
                          ? item?.dailyReportId
                          : type === "Progress Report"
                          ? item?.progressReportId
                          : type === "Gradebook Report"
                          ? item?.gradeBookReportId
                          : null;

                      const isCurrent =
                        type !== "Feedback"
                          ? reportId === id && selectedType === type
                          : dataFeedback?.feedback?.feedBackId === item?.feedback?.feedBackId &&
                            selectedType === "Feedback";

                      const status = capitalizeFirstLetter(
                        type === "Student at risk"
                          ? item?.continuousStatus
                          : type === "Daily Report"
                          ? item?.status
                          : type === "Progress Report"
                          ? item?.status
                          : item.status
                      );

                      return (
                        <MDBox
                          key={index}
                          sx={({ breakpoints, functions: { pxToRem } }) => ({
                            padding: pxToRem(24),
                            borderRadius: pxToRem(15),
                            borderBottom: pxToRem(20),
                            cursor: "pointer",
                            background: isOpenDetail && isCurrent ? "#C8D0FF" : "#FFFFFF",
                            minWidth: "100%",

                            [breakpoints.down(1600)]: {
                              padding: pxToRem(14),
                              borderRadius: pxToRem(8),
                            },
                          })}
                          onClick={() => {
                            setOpenDetail(true);
                            setType(type);
                            if (type === "Feedback") {
                              setDataFeedback(item);
                            } else {
                              setReportId(id);
                            }
                          }}
                        >
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            sx={({ breakpoints }) => ({
                              flexDirection: isOpenDetail ? "column" : "row",

                              [breakpoints.down(768)]: {
                                flexDirection: "column",
                              },
                            })}
                          >
                            <MDBox
                              sx={({ functions: { pxToRem } }) => ({
                                padding: `${pxToRem(4)} ${pxToRem(8)}`,
                                borderRadius: pxToRem(6),
                                marginBottom: pxToRem(8),
                                width: "fit-content",
                                background:
                                  colorsMap.find(
                                    (_item) => _item.status?.toLowerCase() === status.toLowerCase()
                                  )?.color ??
                                  "linear-gradient(121.78deg, #65EF11 9.06%, #B0D99C 88.15%)",
                              })}
                            >
                              <MDTypography
                                sx={({ breakpoints, typography }) => ({
                                  fontFamily: typography.lexendFont,
                                  fontSize: 12,
                                  lineHeight: 1,
                                  fontWeight: 400,
                                  color: "#FFFFFF",
                                  textTransform: "uppercase",

                                  [breakpoints.down(1600)]: {
                                    fontSize: 9,
                                  },
                                })}
                              >
                                {status === "Not complete" ? "In progress" : status}
                              </MDTypography>
                            </MDBox>
                            {(!isOpenDetail || mobileMatches) && deadline ? (
                              <MDTypography
                                sx={({ breakpoints }) => ({
                                  ...descriptionTypography(breakpoints),
                                })}
                              >
                                Due: {moment(deadline).format("MMM DD, YYYY")}
                              </MDTypography>
                            ) : null}
                          </MDBox>
                          <MDBox
                            sx={{
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              lineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                            }}
                          >
                            <MDTypography
                              sx={({ breakpoints, typography }) => ({
                                ...titleTypography(breakpoints),
                                display: "block",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                [breakpoints.down(1600)]: {
                                  fontSize: 13,
                                },
                              })}
                            >
                              {type === "Feedback"
                                ? item?.feedback?.feedBackSubject
                                : type === "Daily Report"
                                ? item?.className
                                : type === "Progress Report"
                                ? `${item?.className} (${item.items.length} report(s))`
                                : type === "Student at risk"
                                ? `${item?.studentName}${
                                    item.nickName ? ` (${item.nickName})` : ""
                                  }`
                                : type === "Gradebook Report"
                                ? `${item?.className} (${item.items.length} report(s))`
                                : ""}
                            </MDTypography>
                          </MDBox>

                          <MDTypography
                            sx={({ breakpoints }) => ({
                              ...noteTypography(breakpoints),
                              lineHeight: 1.625,
                            })}
                          >
                            Type: {type}
                          </MDTypography>
                        </MDBox>
                      );
                    })
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                sx={({}) => ({
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingTop: "16px",
                  gap: 1,
                })}
              >
                <MDBox
                  sx={(theme) =>
                    pagingItemStyle(theme, {
                      active: false,
                      isArrow: true,
                      disable: currentPage === 0,
                    })
                  }
                  onClick={() => {
                    if (!(currentPage === 0)) {
                      handlePrev();
                    }
                  }}
                >
                  <ChevronLeft sx={() => ({ color: "#8392AB" })} />
                </MDBox>

                {filteredPages.map((item, index) => (
                  <MDBox
                    key={index}
                    sx={(theme) =>
                      pagingItemStyle(theme, { active: item === currentPage + 1, isArrow: false })
                    }
                    onClick={() => setPage(item - 1)}
                  >
                    {item}
                  </MDBox>
                ))}
                <MDBox
                  sx={(theme) =>
                    pagingItemStyle(theme, {
                      active: false,
                      isArrow: true,
                      disable: currentPage === totalPage - 1 || currentPage === totalPage,
                    })
                  }
                  onClick={() => {
                    if (!(currentPage === totalPage - 1 || currentPage === totalPage)) {
                      handleNext();
                    }
                  }}
                >
                  <ChevronRight sx={() => ({ color: "#8392AB" })} />
                </MDBox>
              </MDBox>
            </Grid>

            {isOpenDetail ? (
              <TodoDetail
                data={dataFeedback}
                type={selectedType}
                onClose={() => {
                  setOpenDetail(false);
                  setSearchParams({});
                }}
                refetch={refetch}
              />
            ) : null}
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}

export { MyTasks };
