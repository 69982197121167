import { descriptionTypography } from "shared/styles/style";

export const notificationTabStyle = ({ palette, breakpoints, functions: { pxToRem } }) => ({
  position: "absolute",
  right: 0,
  top: pxToRem(88),
  background: palette.white.main,
  zIndex: 99,
  padding: "8px 14px 8px 6px",
  borderRadius: "16px 0px 0px 16px",
  gap: pxToRem(8),
  display: "flex",
  alignItems: "center",
  cursor: "pointer",

  [breakpoints.down("md")]: {
    top: pxToRem(40),
  },
});

export const dateStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  ...descriptionTypography(breakpoints),
  fontWeight: 600,
  lineHeight: 1.5,
  textAlign: "left",
  color: "#212529",
  p: pxToRem(1),
});

export const bottomStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  height: pxToRem(40),
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: pxToRem(17),

  [breakpoints.down(1367)]: {
    height: pxToRem(32),
    gap: pxToRem(10),
  },
});

export const notificationIconStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  width: pxToRem(40),
  height: pxToRem(40),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& .MuiBadge-badge": {
    display: "block",
    width: pxToRem(18),
    height: pxToRem(18),
    minWidth: "unset",
    background: "#f56565",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: 1.5,
    padding: 0,
    fontFamily: typography.lexendFont,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    color: "#32325d",
    top: pxToRem(11),
    right: pxToRem(11),
  },

  [breakpoints.down(1367)]: {
    width: pxToRem(32),
    height: pxToRem(32),

    "& .MuiBadge-badge": {
      width: pxToRem(14),
      height: pxToRem(14),
      fontSize: 9,
    },
  },
});

export const avatarStyle = ({ functions: { pxToRem } }) => ({
  width: pxToRem(21),
  height: pxToRem(16),
  cursor: "pointer",
});
