import SearchImage from "assets/images/apollo-english/search.jpg";
import { titleTypography } from "shared/styles/style";

export default function homeStyles(theme, ownerState) {
  const {
    breakpoints,
    functions: { pxToRem },
  } = theme;

  return {
    top: pxToRem(-20),
    margin: "0 auto",
    position: "relative",

    [breakpoints.up("xxxl")]: {
      width: `calc(100% - 150px)`,
    },

    [breakpoints.up("xxl")]: {
      width: `calc(100% - 120px)`,
    },

    [breakpoints.up("xl")]: {
      width: `calc(100% - 100px)`,
    },

    [breakpoints.up("lg")]: {
      width: `calc(100% - 80px)`,
    },

    [breakpoints.up("md")]: {
      width: `calc(100% - 60px)`,
    },

    [breakpoints.down("md")]: {
      width: `calc(100% - 40px)`,
    },
  };
}

export const homeContainer = ({ breakpoints }) => ({
  [breakpoints.down("xxxl")]: {
    rowGap: 2.5,
    justifyContent: "flex-start",
  },

  [breakpoints.down("xxl")]: {
    rowGap: 2,
  },

  [breakpoints.down("md")]: {
    rowGap: 1.5,
  },
});

export const studentAtRiskStyle = ({ breakpoints, palette, functions: { pxToRem } }) => ({
  boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
  filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25))",
  backgroundColor: palette.white.main,
  borderRadius: pxToRem(12),
  minHeight: pxToRem(500),
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",

  [breakpoints.down(1600)]: {
    minHeight: pxToRem(400),
  },
});

export const studentAtRiskTitleStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.37,
  letterSpacing: pxToRem(-0.8),
  minWidth: 200,
});

export const inputContainerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(400),
  maxWidth: "calc(100% - 27px)",
  paddingTop: pxToRem(27.25),
  paddingLeft: pxToRem(27),

  [breakpoints.down(1600)]: {
    width: pxToRem(240),
    maxWidth: "calc(100% - 18px)",
    paddingTop: pxToRem(18),
    paddingLeft: pxToRem(24),
  },
});

export const inputStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  width: "100%",
  height: pxToRem(40),

  "& > div": {
    overflow: pxToRem(4),
  },

  "& input": {
    borderRadius: pxToRem(4),
    // background: "#fff",
    width: "100%",
    height: pxToRem(48),
    padding: `0 ${pxToRem(16)} 0px ${pxToRem(14)}`,
    alignItems: "center",
    fontFamily: typography.lexendFont,
    outline: "none",
    background: `url(${SearchImage}) no-repeat scroll`,
    backgroundPosition: `right ${pxToRem(13)} center`,
  },

  "& input::-ms-input-placeholder, & input::placeholder": {
    fontFamily: typography.lexendFont,
    color: "#8a8a8a",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: pxToRem(0.175),
  },

  [breakpoints.down(1600)]: {
    height: pxToRem(28),

    "& input": {
      height: pxToRem(33),
      fontSize: 12,
    },

    "& input::-ms-input-placeholder, & input::placeholder": {
      fontSize: 12,
    },
  },
});

export const styleSelect = ({ breakpoints, functions: { pxToRem } }) => ({
  height: "100%",
  minWidth: "160px",
  "& *": {
    fontFamily: "'Lexend', san-serif !important",
  },
  "& .MuiOutlinedInput-root": {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  "& input, & input::placeholder": {
    fontSize: "12px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: "Lexend",
  },

  "& input": {
    paddingLeft: `14px !important`,
    padding: "8px",
  },

  "& input.MuiSelect-select": {
    padding: "10px !important",
  },
  "& .MuiInputBase-input": {
    paddingLeft: `14px !important`,
    padding: "10px !important",
    minHeight: "20.25px !important",
  },
});
