import React, { useEffect, useState } from "react";
import "./apo.css";

import { MyLottieAnimation } from "./MyLottieAnimation";
import MDBox from "components/MDBox";
import apo from "assets/images/apollo-english/apo.png";

const Apo = () => {
  const [imagePositions, setImagePositions] = useState([]);

  useEffect(() => {
    const generateImages = () => {
      const newImage = {
        x: Math.random() * (window.innerWidth - 200),
        y: Math.random() * (window.innerHeight - 200),
        duration: 3650,
      };
      setImagePositions((imagePositions) => [...imagePositions.slice(1), newImage]);
    };

    generateImages();

    const intervalId = setInterval(() => {
      generateImages();
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setImagePositions([]);
    }, 3000);

    return () => {
      clearInterval(intervalId);
      setImagePositions([]);
    };
  }, []);

  return (
    <div id="apo-container" className="fixed top-0 left-0 w-full h-full z-[9999]">
      <div id="overlay" className="top-0 left-0 w-full h-full bg-black z-[9999]"></div>
      <MDBox
        id="apo"
        sx={({ breakpoints }) => ({
          width: 400,
          height: 200,
          backgroundImage: `url(${apo})`,
          backgroundSize: "cover",
          position: "fixed",
          zIndex: 10000,

          [breakpoints.down(1600)]: {
            width: 300,
            height: 150,
          },
        })}
      />

      {imagePositions.map((image, index) => (
        <MyLottieAnimation
          key={index}
          style={{
            position: "absolute",
            top: `${image.y}px`,
            left: `${image.x}px`,
            animation: `fade ${image.duration}ms ease-in-out forwards`,
          }}
        />
      ))}
    </div>
  );
};

export default Apo;
