import { descriptionTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";

export const myTasksStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  marginBottom: pxToRem(30),
  [breakpoints.down("xxxl")]: {
    rowGap: 2.5,
    justifyContent: "flex-start",
  },

  [breakpoints.down("xxl")]: {
    marginBottom: pxToRem(24),
    rowGap: 2,
  },

  [breakpoints.down("md")]: {
    marginBottom: pxToRem(20),
    rowGap: 1.5,
  },
});

export const myTasksContainerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  background: "#fff",
  width: "100%",
  padding: `${pxToRem(20.5)} ${pxToRem(24)}`,
  borderRadius: pxToRem(12),
  display: "flex",
  flexDirection: "column",
  height: "100%",

  [breakpoints.down(1600)]: {
    padding: `${pxToRem(12)} ${pxToRem(16)}`,
    borderRadius: pxToRem(8),
  },
});

export const completionTitleStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.625,
  letterSpacing: "-0.8px",
  marginBottom: pxToRem(16),
});

export const overdueTextStyle = ({ breakpoints, typography }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.625,
  color: "#F5365C",
  letterSpacing: "-0.8px",
  textAlign: "right",
});

export const toCompleteTextStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: 1.5,
  color: "#F5365C",
  textAlign: "right",

  [breakpoints.down(1600)]: {
    fontSize: 11,
  },
});

export const alertStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(50),
  height: pxToRem(50),
  borderRadius: pxToRem(8),
  background: "#EDF2F7",

  [breakpoints.down(1600)]: {
    transform: "scale(0.7)",
    transformOrigin: "100% 0",
  },
});

export const accessLMS = ({ breakpoints, typography }) => ({
  backgroundImage: "linear-gradient(121.78deg, #11CDEF 9.06%, #1171EF 88.15%)",
  color: "#fff",
  fontFamily: typography.lexendFont,
  fontSize: 12,
  lineHeight: 1.5,
  fontWeight: 700,

  [breakpoints.down(1600)]: {
    fontSize: 8,
    padding: "4px 12px !important",
    minHeight: "32px!important",
  },
});

export const blockTitle = ({ breakpoints, functions: { pxToRem } }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.625,
  letterSpacing: "-0.8px",
  marginBottom: pxToRem(16),
});
