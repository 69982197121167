import React from "react";
import "./style.css";
import { useSelector } from "react-redux";
import Lottie from "react-lottie-player";
import loadingAnimation from "./loading.json";

const LoadingFullPage = (props) => {
  const loading = useSelector((state) => state.loading);

  return loading ? (
    <div className="loading">
      <Lottie animationData={loadingAnimation} {...props} play />
    </div>
  ) : (
    <></>
  );
};

export default LoadingFullPage;
