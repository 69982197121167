import { Button, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useCallback, useMemo, useState } from "react";
import { noteTypography } from "shared/styles/style";
import DataTable from "./table";
import useStateRef from "react-usestateref";

const tabs = [
  {
    title: "Available",
  },
  {
    title: "Registerd",
  },
  {
    title: "Attended",
  },
];

const mock = [
  {
    workshopName: "Apollo Workshop 1",
    duration: "3 hours and 15 minutes",
    description:
      "The workshop aims to create a platform for collaboration and exchange of ideas oneffective English language teaching methods. The program includes keynote speeches,interactive workshops and open discussion sessions, focusing on trends and best practices in teaching.",
  },
  {
    workshopName: "Apollo Workshop 2",
    duration: "3 hours and 15 minutes",
    description:
      "The workshop aims to create a platform for collaboration and exchange of ideas oneffective English language teaching methods. The program includes keynote speeches,interactive workshops and open discussion sessions, focusing on trends and best practices in teaching.",
  },
  {
    workshopName: "Apollo Workshop 3",
    duration: "3 hours and 15 minutes",
    description:
      "The workshop aims to create a platform for collaboration and exchange of ideas oneffective English language teaching methods. The program includes keynote speeches,interactive workshops and open discussion sessions, focusing on trends and best practices in teaching.",
  },
];

export const WorkShop = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(-1);

  const buttonSchedule = useCallback(
    ({ index }) => {
      return (
        <Button
          variant="contained"
          sx={{
            background: showDropdown === index ? "#FFFFFF!important" : "#2DCE89!important",
            color: showDropdown === index ? "#2DCE89!important" : "#FFFFFF!important",
            border: showDropdown === index ? "1px solid #2DCE89!important" : "none",
            textDecoration: "unset!important",
            boxShadow: "0px 2px 6px 0px #00000040",
            lineHeight: 1,
          }}
          onClick={() => {
            setShowDropdown(showDropdown === index ? -1 : index);
          }}
        >
          Check Schedule
        </Button>
      );
    },
    [showDropdown]
  );

  const table1 = {
    columns: [
      { Header: "WORKSHOP NAME", accessor: "workshopName", width: "20%" },
      { Header: "DURATION", accessor: "duration", width: "20%" },
      { Header: "DESCRIPTION", accessor: "description", width: "40%" },
      { Header: "ACTION", accessor: "action", width: "20%" },
    ],

    rows: [
      ...mock.map((item, index) => {
        return {
          workshopName: item.workshopName,
          duration: item.duration,
          description: item.description,
          action: buttonSchedule({ index }),
        };
      }),
    ],
  };

  const table3 = {
    columns: [
      { Header: "WORKSHOP NAME", accessor: "workshopName", width: "15%" },
      { Header: "DURATION", accessor: "duration", width: "10%" },
      { Header: "DESCRIPTION", accessor: "description", width: "22.5%" },
      { Header: "DATE & TIME", accessor: "dateTime", width: "15%" },
      { Header: "LOCATION", accessor: "location", width: "12.5%" },
      { Header: "TRAINER", accessor: "trainer", width: "10%" },
      { Header: "ACTION", accessor: "action", width: "15%" },
    ],

    rows: [
      {
        workshopName: "Apollo Workshop 1",
        duration: "3 hours and 15 minutes",
        description:
          "The workshop aims to create a platform for collaboration and exchange of ideas oneffective English language teaching methods. The program includes keynote speeches,interactive workshops and open discussion sessions, focusing on trends and best practices in teaching.",
        dateTime: "26 October 2024, at 13:45 PM",
        location: "58 Pham Ngoc Thach Street, Vo Thi Sau Ward, District 3, Ho Chi Minh City",
        trainer: "Mark Johnson",
        action: (
          <Button
            variant="contained"
            sx={{
              background: "#2DCE89!important",
              color: "#FFFFFF!important",
              textDecoration: "unset!important",
              boxShadow: "0px 2px 6px 0px #00000040",
              lineHeight: 1,
            }}
          >
            View Resource
          </Button>
        ),
      },
    ],
  };

  return (
    <MDBox
      sx={{
        borderRadius: "12px",
        background: "white",
        padding: "20px",
        cursor: "pointer",
      }}
      mt={2}
    >
      <Grid
        container
        alignItems="center"
        sx={({ functions: { pxToRem } }) => ({
          borderRadius: pxToRem(12),
          padding: pxToRem(4),
          background: "#F6F9FC",
          maxWidth: "420px",
        })}
      >
        {tabs.map((item, index) => {
          return (
            <Grid
              item
              xs={4}
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
              sx={({ functions: { pxToRem } }) => ({
                height: pxToRem(35),
                borderRadius: pxToRem(8),
                background: tabIndex === index ? "white" : "inherite",
                boxShadow: tabIndex === index ? "0px 2px 6px 0px #00000040" : "unset",
                cursor: "pointer",
              })}
              onClick={() => {
                setTabIndex(index);
              }}
            >
              <MDTypography
                sx={({ breakpoints, typography }) => ({
                  ...noteTypography(breakpoints),
                  lineHeight: 1.25,
                })}
              >
                {item.title}
              </MDTypography>
            </Grid>
          );
        })}
      </Grid>
      {tabIndex === 0 || tabIndex === 1 ? (
        <DataTable
          table={table1}
          isSorted={false}
          currentPage={0}
          handlePrev={() => {}}
          handleNext={() => {}}
          totalPage={() => {}}
          setCurrentPage={() => {}}
          isLoading={false}
          isEmpty={false}
          classPageSize={10}
          setClassPageSize={() => {}}
          hasSchedule={true}
          showDropdown={showDropdown}
        />
      ) : (
        <DataTable
          table={table3}
          isSorted={false}
          currentPage={0}
          handlePrev={() => {}}
          handleNext={() => {}}
          totalPage={() => {}}
          setCurrentPage={() => {}}
          isLoading={false}
          isEmpty={false}
          classPageSize={10}
          setClassPageSize={() => {}}
        />
      )}
    </MDBox>
  );
};
