import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { AdminHeader } from "shared/components/common/AdminHeader";
import { useMaterialUIController } from "context";
import { containerStyle } from "./styles/index";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { NotificationManagement } from "./components/Table";

export const AdminNotification = () => {
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  return (
    <DashboardLayout>
      <AdminHeader />
      <DashboardNavbar light></DashboardNavbar>
      <MDBox sx={(theme) => containerStyle(theme, { miniSidenav })}>
        <NotificationManagement />
      </MDBox>
    </DashboardLayout>
  );
};
