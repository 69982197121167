import { forwardRef, useState } from "react";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ChevronDownIcon from "assets/images/apollo-english/chevron-down.svg";

const MyPickersTextField = forwardRef((props, ref) => {
  // eslint-disable-next-line
  const { content, onClick, label, disabled } = props;

  return (
    <TextField
      {...props}
      ref={ref}
      label={label}
      size="small"
      value={content}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
    />
  );
});

// eslint-disable-next-line
export const CustomDatePicker = ({ label, disabled, defaultValue }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(defaultValue);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slotProps={{
          textField: {
            readOnly: true,
            content: `${dayjs(date).format("DD/MM/YYYY")}`,
            onClick: () => setOpen(!open),
            disabled,
          },
        }}
        //defaultValue={defaultValue}
        slots={{
          textField: MyPickersTextField,
          inputAdornment: () => null,
        }}
        onChange={(date) => {
          setDate(date.$d.toString());
        }}
        closeOnSelect
        sx={({ palette, typography, functions: { pxToRem } }) => ({
          width: "100%",

          "& .MuiInputBase-root": {
            width: "100%",
            cursor: "pointer",
            height: pxToRem(42),
            flexDirection: "row-reverse",
            padding: 0,
            fontFamily: typography.lexendFont,
            backgroundImage: `url(${ChevronDownIcon})`,
            backgroundSize: pxToRem(12),
            backgroundRepeat: "no-repeat",
            backgroundPosition: "95% 50%",
          },
          "& label": {
            fontSize: "18px !important",
            fontWeight: 700,
            fontFamily: typography.lexendFont,
            color: palette.textColors.primary,
            top: "-2px",
          },
          "& legend": {
            fontSize: "14px !important",
          },
          "& .MuiInputBase-root .MuiInputBase-input": {
            cursor: "pointer",
            fontSize: "16px !important",
            lineHeight: "1.5!important",
            fontWeight: 400,
            fontFamily: typography.lexendFont,
            paddingLeft: pxToRem(14),
          },
        })}
      />
    </LocalizationProvider>
  );
};
