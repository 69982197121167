export default () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.53398 16.132V14.532H9.46732V16.132H6.53398Z" stroke="#ADB5BD" strokeWidth="2.4" />
      <path d="M14.534 16.132V14.532H17.4673V16.132H14.534Z" stroke="#ADB5BD" strokeWidth="2.4" />
      <path d="M6.53398 22.8V21.2H9.46732V22.8H6.53398Z" stroke="#ADB5BD" strokeWidth="2.4" />
      <path d="M14.534 22.8V21.2H17.4673V22.8H14.534Z" stroke="#ADB5BD" strokeWidth="2.4" />
      <path d="M22.534 16.132V14.532H25.4673V16.132H22.534Z" stroke="#ADB5BD" strokeWidth="2.4" />
      <path
        d="M22.8 4V5.2H24H30.6667C30.702 5.2 30.7359 5.21405 30.7609 5.23905C30.786 5.26405 30.8 5.29797 30.8 5.33333V29.3333C30.8 29.3687 30.786 29.4026 30.761 29.4276C30.7359 29.4526 30.702 29.4667 30.6667 29.4667H1.33333C1.29797 29.4667 1.26405 29.4526 1.23905 29.4276C1.21405 29.4026 1.2 29.3687 1.2 29.3333V5.33333C1.2 5.29797 1.21405 5.26406 1.23905 5.23905C1.26406 5.21405 1.29797 5.2 1.33333 5.2H8H9.2V4V1.33333C9.2 1.29797 9.21405 1.26406 9.23905 1.23905C9.26406 1.21405 9.29797 1.2 9.33333 1.2C9.3687 1.2 9.40261 1.21405 9.42761 1.23905C9.45262 1.26406 9.46667 1.29797 9.46667 1.33333V4V5.2H10.6667H21.3333H22.5333V4V1.33333C22.5333 1.29797 22.5474 1.26406 22.5724 1.23905L21.7274 0.394105L22.5724 1.23905C22.5974 1.21405 22.6313 1.2 22.6667 1.2C22.702 1.2 22.7359 1.21405 22.7609 1.23905C22.786 1.26405 22.8 1.29797 22.8 1.33333V4ZM29.3333 29.2H30.5333V28V9.33333V8.13333H29.3333H2.66667H1.46667V9.33333V28V29.2H2.66667H29.3333Z"
        stroke="#ADB5BD"
        strokeWidth="2.4"
      />
    </svg>
  );
};
