import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

import {
  titleFormStyle,
  titleDownload,
  itemList,
  titleItem,
  subMail,
  boxNotiContent,
  container,
  groupList,
  boxNotiIcon,
  boxButton,
  groupBoxContent,
} from "./style";

import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDEditor from "components/MDEditor";
import MDSnackbar from "components/MDSnackbar";
import { SVGIcon } from "components/icons";
import MDButton from "components/MDButton";
import {
  useCreatePushNotiScheduleMutation,
  useUploadFileSendNotiMutation,
} from "shared/redux/endpoints/notifications";
import MDBox from "components/MDBox";
import { useCreatePushNotiMutation } from "shared/redux/endpoints/notifications";
import { useSearchParams } from "react-router-dom";

const FormNotification = (props) => {
  // eslint-disable-next-line react/prop-types
  const { dataTemplate } = props;
  const [errors, setErrors] = useState({});
  const [listIdSend, setListIdSend] = useState([]);
  const [openSuccessSB, setOpenSuccessSB] = useState(false);
  const [bodyEnglish, setBodyEnglish] = useState("");
  const [bodyVietnamese, setBodyVietnamese] = useState("");
  const [isSchedule, setIsSchedule] = useState(false);
  const [keyRender, setKeyRender] = useState(0);
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    englishTitle: "",
    englishBody: "",
    vietnameseTitle: "",
    vietnameseBody: "",
  });

  useEffect(() => {
    if (dataTemplate) {
      const dataTemplateDetail = dataTemplate?.data;

      setFormData({
        ...formData,
        englishTitle: dataTemplateDetail?.titleEn,
        englishBody: dataTemplateDetail?.contentEn,
        vietnameseTitle: dataTemplateDetail?.titleVi,
        vietnameseBody: dataTemplateDetail?.contentVi,
      });

      setBodyEnglish(dataTemplateDetail?.contentEn);
      setBodyVietnamese(dataTemplateDetail?.contentVi);
    }
  }, [dataTemplate]);

  useEffect(() => {
    setTimeout(() => {
      setKeyRender(1);
    }, 1000);
  }, []);

  const [createNotificationSchedule, { isLoading, data, error, isSuccess }] =
    useCreatePushNotiScheduleMutation();

  const [createNotifications] = useCreatePushNotiMutation();

  const [
    uploadFileSendNoti,
    { isLoading: loadingUpload, isSuccess: isSuccessUpload, data: dataUpload = {} },
  ] = useUploadFileSendNotiMutation();

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const validateFields = (data) => {
    const errors = {};

    // Validate DatePickers
    const startDate = data.startDate;

    if (isSchedule) {
      if (!startDate) {
        errors.startDate = "Field is required";
      } else if (dayjs(startDate).isBefore(dayjs(), "day")) {
        errors.startDate = "Start date must be today or later";
      }
    }

    if (listIdSend.length === 0) {
      errors.listIdSend = "Please upload file to send";
    }

    // Validate tiêu đề và nội dung cho tiếng Anh
    if ((!data.englishTitle || data.englishTitle.trim() === "") && !id) {
      errors.englishTitle = "Field is required";
    }

    if ((!bodyEnglish || bodyEnglish.trim() === "") && !id) {
      errors.englishBody = "Field is required";
    }

    // Validate tiêu đề và nội dung cho tiếng Việt
    if ((!data.vietnameseTitle || data.vietnameseTitle.trim() === "") && !id) {
      errors.vietnameseTitle = "Field is required";
    }

    if ((!bodyVietnamese || bodyVietnamese.trim() === "") && !id) {
      errors.vietnameseBody = "Field is required";
    }

    return errors;
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleOpenSuccess = () => setOpenSuccessSB(true);
  const handleCloseSuccess = () => setOpenSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Notification"
      content="Data has been successfully sent! You have successfully pushed the notification."
      open={openSuccessSB}
      onClose={handleCloseSuccess}
      close={handleCloseSuccess}
      bgWhite
    />
  );

  const handleSubmit = () => {
    const validationErrors = validateFields(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formSubmit = {
      teacherIds: listIdSend,
      ...(isSchedule
        ? { scheduleTime: dayjs(formData.startDate).format("YYYY-MM-DD HH:mm:ss.SSS") }
        : {}),
      centerIds: [],
      sendAll: false,
      ...(id
        ? { useTemplate: true, templateId: id }
        : {
            notification: {
              status: "UNREAD",
              notificationContentEntity: {
                target: "TEACHERS",
                titleVi: formData.vietnameseTitle,
                titleEn: formData.englishTitle,
                contentVi: bodyVietnamese,
                contentEn: bodyEnglish,
                template: false,
                coverUrl:
                  "https://tudylab.s3.ap-southeast-1.amazonaws.com/static/jenkins_start.png",
                notificationType: "ANNOUNCEMENT",
              },
            },
          }),
    };

    if (isSchedule) {
      createNotificationSchedule(formSubmit).then((data) => {
        if (data?.data?.status) {
          handleOpenSuccess();
          setErrors({});
        }
      });
    } else {
      createNotifications(formSubmit).then((data) => {
        handleOpenSuccess();
        setErrors({});
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    uploadFileSendNoti(formData).then((data) => {
      const listUser = data?.data?.data || [];
      if (data) {
        setListIdSend(listUser.map((item) => item.hubUserId));
      }
    });
  };

  const handleFileUpload = () => {
    document.getElementById("file-upload").click();
  };

  return (
    <MDBox sx={container}>
      <MDTypography sx={titleFormStyle} mb={2}>
        Template selected: {id}
      </MDTypography>
      <MDTypography sx={titleFormStyle}>Time range to send this notification</MDTypography>
      <MDBox>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker"]}>
            <MDBox display="flex" alignItems="center">
              <MDTypography sx={titleFormStyle}>Set schedule:</MDTypography>
              <Switch checked={isSchedule} onChange={(e) => setIsSchedule(e.target.checked)} />
            </MDBox>
            {isSchedule ? (
              <MDBox>
                <DateTimePicker
                  label="With Time Clock"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  minDateTime={dayjs().add(1, "hour")}
                  onChange={(newValue) => handleInputChange("startDate", newValue)}
                />
                {errors.startDate && (
                  <MDTypography fontSize="12px" color="error" mt={1}>
                    {errors.startDate}
                  </MDTypography>
                )}
              </MDBox>
            ) : null}
          </DemoContainer>
        </LocalizationProvider>
      </MDBox>

      <MDBox mt={2}>
        <input accept=".csv" id="file-upload" type="file" onChange={handleFileChange} hidden />
        <MDTypography
          mt={2}
          variant="label"
          htmlFor="file-upload"
          sx={titleFormStyle}
          onClick={handleFileUpload}
          cursor="pointer"
        >
          List of recipient <SVGIcon name="upload" />
        </MDTypography>
      </MDBox>
      <MDTypography mt={1} sx={titleDownload}>
        Download sample file
      </MDTypography>

      {errors.listIdSend && (
        <MDTypography fontSize="12px" color="error">
          {errors.listIdSend}
        </MDTypography>
      )}

      <MDBox sx={groupList}>
        {dataUpload?.data?.map((item) => {
          return (
            <MDBox sx={itemList} key={item.hubUserId}>
              <MDTypography sx={titleItem}>{item.username}</MDTypography>
              <MDTypography sx={subMail}>{item.hubUserId}</MDTypography>
            </MDBox>
          );
        })}
      </MDBox>
      <MDTypography sx={titleFormStyle} mt={3}>
        Notification content
      </MDTypography>
      <MDBox sx={boxNotiContent} position="relative">
        <MDBox sx={groupBoxContent}>
          <MDTypography sx={titleFormStyle} mt={2}>
            English title
          </MDTypography>
          <MDBox mt={1}>
            <MDInput
              error={!!errors.englishTitle}
              value={formData.englishTitle}
              onChange={(e) => handleInputChange("englishTitle", e.target.value)}
              placeholder="Set an English title here"
              size="large"
              sx={{
                width: "100%",
              }}
              success={!!formData.englishTitle}
              defaultValue={formData.englishTitle}
              disabled={!!id}
            />
          </MDBox>

          <MDBox mt={2}>
            <MDTypography sx={titleFormStyle}>Body </MDTypography>
            <MDEditor
              setValue={setBodyEnglish}
              defaultValue={formData?.englishBody}
              value={bodyEnglish}
              key={keyRender}
              disabled={!!id}
            />
            {errors.englishBody && (
              <MDTypography fontSize="12px" color="error">
                {errors.englishBody}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
        <MDBox sx={boxNotiIcon}>
          <SVGIcon name="arrow-left" />
          <SVGIcon name="arrow-right" />
        </MDBox>
        <MDBox sx={groupBoxContent}>
          <MDTypography sx={titleFormStyle} mt={2}>
            Vietnamese title
          </MDTypography>
          <MDBox mt={1}>
            <MDInput
              error={!!errors.vietnameseTitle}
              value={formData.vietnameseTitle}
              onChange={(e) => handleInputChange("vietnameseTitle", e.target.value)}
              placeholder="Set an Vietnamese title here"
              size="large"
              sx={{ width: "100%" }}
              success={!!formData.vietnameseTitle}
              defaultValue={formData?.vietnameseTitle}
              disabled={!!id}
            />
          </MDBox>
          <MDBox mt={2}>
            <MDTypography sx={titleFormStyle}>Body </MDTypography>
            <MDEditor
              setValue={setBodyVietnamese}
              defaultValue={formData?.vietnameseBody}
              value={bodyVietnamese}
              key={keyRender}
              disabled={!!id}
            />
            {errors.vietnameseBody && (
              <MDTypography fontSize="12px" color="error">
                {errors.vietnameseBody}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mt={2} sx={boxButton}>
        <MDButton color="success" size="small" onClick={handleSubmit}>
          Send
        </MDButton>
        <MDButton color="info" size="small">
          Preview
        </MDButton>
      </MDBox>

      {renderSuccessSB}
    </MDBox>
  );
};

FormNotification.defaultProps = {
  dataTemplate: {},
};

// Typechecking props for the FormNotification
FormNotification.propTypes = {
  dataTemplate: PropTypes.object,
};

export default FormNotification;
