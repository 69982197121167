import * as React from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import MDInput from "components/MDInput";
import { titleInputStyle, titleStyle } from "./style";
import { CustomSelect } from "shared/components/ui/CustomSelect";
import { useCreateBannerMutation } from "shared/redux/endpoints/banner";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "min(90%, 80vh)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
};

export default function CreateBanner({ open, handleClose, refetch }) {
  const [errors, setErrors] = React.useState({});
  const [create, { isLoading }] = useCreateBannerMutation();
  const [title, setTitle] = React.useState("");
  const [type, setType] = React.useState("none");

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const validateFields = (data) => {
    const errors = {};

    // Validate tiêu đề và nội dung cho tiếng Anh
    if (!data.title || data.title.trim() === "") {
      errors.title = "Field title is required";
    }

    if (data.type === "none") {
      errors.type = "Field type is required";
    }

    return errors;
  };

  React.useEffect(() => {
    const errors = {};
    if (title) errors.title = "";
    if (type !== "none") errors.type = "";
    setErrors(errors);
  }, [title, type]);

  const handleReset = () => {
    setTitle("");
    setType("none");
    setErrors({});
  };

  const handleSubmit = async () => {
    const validationErrors = validateFields({ title, type });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const formData = {
        title,
        type: type.toUpperCase(),
        imgUrl: "",
        linkUrl: "",
      };
      const response = await create(formData);

      if (response?.data?.status) {
        handleClose();
        toast.success(`Create new ${type} successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            fontFamily: "Lexend",
            fontSize: 14,
          },
        });
        setErrors({});
        handleReset();
        refetch();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        handleReset();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox component="form" sx={style}>
        <MDTypography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ p: 3, pb: 2, fontFamily: "Lexend, san-serif" }}
        >
          Create new banner/announcement
        </MDTypography>
        <MDBox
          sx={({ breakpoints }) => ({
            display: "flex",
            px: 3,
            justifyContent: "space-between",
            alignItems: "center",

            [breakpoints.down("md")]: {
              flexDirection: "column",
            },
          })}
        >
          <MDBox sx={{ flexGrow: 1 }}>
            <MDTypography sx={(theme) => titleStyle(theme)}>Item</MDTypography>
            <MDInput
              error={!!errors.title}
              success={!!title}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={(theme) => titleInputStyle(theme)}
              placeholder="Set a title here"
            ></MDInput>
            {errors.title && (
              <MDTypography fontSize="12px" color="error">
                {errors.title}
              </MDTypography>
            )}
            <MDTypography sx={(theme) => titleStyle(theme)}>Type</MDTypography>

            <CustomSelect
              options={[
                { value: "none", label: "All" },
                { value: "banner", label: "Banner" },
                { value: "announcement", label: "Announcement" },
              ]}
              defaultValue="none"
              setOuterValue={setType}
              iconSize={12}
            />

            {errors.type && (
              <MDTypography fontSize="12px" color="error">
                {errors.type}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="flex-end" sx={{ p: 3, pt: 2, width: "100%" }}>
          <Button
            variant="contained"
            sx={({ functions: { pxToRem } }) => ({
              mr: 2,
              color: "#FFFFFF",
              backgroundColor: "#5E72E4",
              fontFamily: "Lexend, san-serif",
              height: pxToRem(30),
              minHeight: pxToRem(30),
              textTransform: "unset",
            })}
            component="label"
            role={undefined}
            tabIndex={-1}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={({ palette, functions: { pxToRem } }) => ({
              color: palette.textColors.primary,
              borderColor: `${palette.textColors.primary}!important`,
              fontFamily: "Lexend, san-serif",
              height: pxToRem(30),
              minHeight: pxToRem(30),
              textTransform: "unset",
            })}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

CreateBanner.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  template: PropTypes.any,
  refetch: PropTypes.any,
};
