import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClass: build.query({
      query: (arg) => {
        const { page, size, sort, startDate, endDate, groupKOC, centerName, KOC } = arg;
        return {
          url: `/private/class/v2/filter?page=${page}&size=${size}&sort=${sort}&startDate=${startDate}&endDate=${endDate}&groupKOC=${groupKOC}&centerName=${centerName}&KOC=${KOC}`,
        };
      },
    }),
    getClassByCode: build.query({
      query: (arg) => {
        const { code, page, size, sort, startDate, endDate } = arg;
        return {
          url: `/private/class/v3/classCode?code=${code}&page=${page}&size=${size}&sort=${sort}&start=${startDate}&end=${endDate}`,
        };
      },
    }),
    getStudent: build.query({
      query: (arg) => {
        const { page, size, sort, startDate, endDate, groupKOC, centerName, KOC } = arg;
        return {
          url: `/private/student/v1/filter?page=${page}&size=${size}&sort=${sort}&startDate=${startDate}&endDate=${endDate}&groupKOC=${groupKOC}&centerName=${centerName}&KOC=${KOC}`,
        };
      },
    }),
    getStudentByCode: build.query({
      query: (arg) => {
        const { code, startDate, endDate } = arg;
        return {
          url: `/private/student/v3/studentCode?code=${code}&start=${startDate}&end=${endDate}`,
        };
      },
    }),
    getStudentByClassCode: build.query({
      query: (arg) => {
        const { code, startDate, endDate, page, size } = arg;
        return {
          url: `/private/student/v3/classCode?code=${code}&page=${page}&size=${size}&sort=classId%2Cdesc&start=${startDate}&end=${endDate}`,
        };
      },
    }),
    getSuggestions: build.query({
      query: (arg) => {
        const { centerName, groupKOC } = arg;
        return {
          url: `/private/class/v1/block/data?centerName=${centerName}&groupKOC=${groupKOC}`,
        };
      },
    }),
    getStudentDetail: build.query({
      query: (arg) => {
        const { id } = arg;
        return {
          url: `/private/student/v2/details/${id}`,
        };
      },
    }),
    getReportPercent: build.query({
      query: () => ({
        url: "/private/report/v1/percent",
      }),
    }),
    getTodo: build.query({
      query: (arg) => {
        const { page, size, start, end } = arg;
        return {
          url: `/private/report/v2/todo?page=${page}&size=${size}&sort=startTime%2Cdesc&start=${start}&end=${end}`,
        };
      },
    }),
    submitFeedback: build.mutation({
      query: (arg) => {
        return {
          url: `/private/report/v1/submit/feedbackComment`,
          method: "POST",
          body: arg,
        };
      },
    }),
    submitFeedbackHub: build.mutation({
      query: (arg) => {
        return {
          url: `https://hub24-qc.apollo.vn:1111/SISApi/create-teacher-response`,
          method: "POST",
          body: arg,
        };
      },
    }),
    getEpp: build.query({
      query: (arg) => {
        const { page, size, start, end, centerCode } = arg;
        return {
          url: `/private/epp/v1?page=${page}&size=${size}&sort=lastLessonDate%2Casc&start=${start}&end=${end}&centerCode=${centerCode}`,
        };
      },
    }),
    getEppCenter: build.query({
      query: () => {
        return {
          url: `/private/epp/v1/center`,
        };
      },
    }),
    getTodoV2: build.query({
      query: (arg) => {
        const { page, size, start, end } = arg;
        return {
          url: `/private/report/v2/todo?page=${page}&size=${size}&sort=startTime%2Cdesc&start=${start}&end=${end}`,
        };
      },
    }),
    getClassSuggession: build.query({
      query: (arg) => {
        const { startDate, endDate, centerName, groupKOC, KOC } = arg;
        return {
          url: `/private/class/v1/block/suggestClass?startDate=${startDate}&endDate=${endDate}&centerName=${centerName}&groupKOC=${groupKOC}&KOC=${KOC}`,
        };
      },
    }),
    getClassSuggessionForStudent: build.query({
      query: (arg) => {
        const { startDate, endDate, centerName, groupKOC, KOC } = arg;
        return {
          url: `/private/student/v1/block/suggestClass?startDate=${startDate}&endDate=${endDate}&centerName=${centerName}&groupKOC=${groupKOC}&KOC=${KOC}`,
        };
      },
    }),
    getStudentSuggessionForStudent: build.query({
      query: (arg) => {
        const { classCode, startDate, endDate } = arg;
        return {
          url: `/private/student/v2/block/suggestStudent?classCode=${classCode}&start=${startDate}&end=${endDate}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClassQuery,
  useLazyGetClassQuery,
  useGetSuggestionsQuery,
  useLazyGetSuggestionsQuery,
  useGetStudentQuery,
  useLazyGetStudentQuery,
  useLazyGetClassByCodeQuery,
  useLazyGetStudentByCodeQuery,
  useLazyGetStudentByClassCodeQuery,
  useGetStudentDetailQuery,
  useGetReportPercentQuery,
  useLazyGetReportPercentQuery,
  useLazyGetTodoQuery,
  useSubmitFeedbackMutation,
  useGetEppQuery,
  useLazyGetEppQuery,
  useGetEppCenterQuery,
  useSubmitFeedbackHubMutation,
  useLazyGetToToV2Query,
  useLazyGetClassSuggessionQuery,
  useLazyGetClassSuggessionForStudentQuery,
  useLazyGetStudentSuggessionForStudentQuery,
} = injectedRtkApi;
