import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDashboardTeacher: build.query({
      query: () => ({
        url: "/private/sso/integration/v1/ooolab/teacherDashboard",
        method: "GET",
      }),
    }),
    getDashboardClass: build.query({
      query: (arg) => ({
        url: `/private/sso/integration/v1/ooolab/class/${arg.classCode}`,
        method: "GET",
      }),
    }),
    getDashboardStudent: build.query({
      query: (arg) => ({
        url: `/private/sso/integration/v1/ooolab/studentDashboard/${arg.studentCode}`,
        method: "GET",
      }),
    }),
    getOoolabStudentDetail: build.query({
      query: (arg) => ({
        url: `/private/sso/integration/v1/ooolab/studentDetail/${arg.studentCode}`,
        method: "GET",
      }),
    }),
    getOoolabPendingReport: build.query({
      query: () => ({
        url: `/private/sso/integration/v1/ooolab/pendingReport`,
        method: "GET",
      }),
    }),
    getOoolabLesson: build.query({
      query: (arg) => ({
        url: `/private/sso/integration/v1/ooolab/lessonDetail/${arg.lessonCode}`,
        method: "GET",
      }),
    }),
    getHub: build.mutation({
      query: (arg) => ({
        url: `/private/sso/integration/v1/hub/redirect`,
        method: "POST",
        body: arg,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetDashboardTeacherQuery,
  useLazyGetDashboardClassQuery,
  useLazyGetDashboardStudentQuery,
  useLazyGetOoolabStudentDetailQuery,
  useLazyGetOoolabPendingReportQuery,
  useLazyGetOoolabLessonQuery,
  useGetHubMutation,
} = injectedRtkApi;
