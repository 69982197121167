import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import { Loading } from "shared/components/ui/Loading";
import { WarningAmber } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import { descriptionTypography } from "shared/styles/style";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import Stack from "@mui/material/Stack";
import { Button, MenuItem, Select } from "@mui/material";
import NotFound from "assets/images/apollo-english/not-found.png";
import MDInput from "components/MDInput";
import location from "assets/images/apollo-english/location.svg";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  setCurrentPage,
  totalPage,
  handlePrev,
  handleNext,
  currentPage,
  isLoading,
  isEmpty,
  classPageSize,
  startDateClass,
  endDateClass,
  setClassPageSize,
  hasSchedule,
  showDropdown,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const customStyle = (disable) => ({
    minWidth: "150px",
    ...(disable && { backgroundColor: "#FAFAFA!important" }),
    borderRadius: "0.375rem",
    "& *": {
      fontFamily: "'Lexend', san-serif !important",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    "& input, & input::placeholder": {
      fontSize: "12px !important",
      lineHeight: "1.5!important",
      fontWeight: 400,
    },

    "& input": {
      paddingLeft: `14px !important`,
    },
  });

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(classPageSize), [classPageSize]);

  // Render the paginations
  const renderPagination = Array.from({ length: totalPage }, (_, i) => i + 1).map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => setCurrentPage(option - 1)}
      active={currentPage === option - 1}
    >
      {option}
    </MDPagination>
  ));

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  //useHorizontalSroll();

  return (
    <>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          height: "fit-content",
        }}
      >
        <MDBox display="flex" alignItems="center">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={classPageSize}
            onChange={(e) => {
              setClassPageSize(e.target.value);
            }}
            sx={{
              ...customStyle,
              width: "100px",
              mr: 2,
              "& input.MuiSelect-select": {
                padding: "10px !important",
              },
              "& .MuiInputBase-input": {
                paddingLeft: `14px !important`,
                padding: "10px !important",
                // minHeight: "40.25px !important",
              },
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
            })}
          >
            Entries per page
          </MDTypography>
        </MDBox>
        <MDBox
          sx={({ functions: { pxToRem } }) => ({
            width: pxToRem(170),
            maxWidth: "100%",
          })}
        >
          <MDInput
            placeholder="Search here"
            sx={({ breakpoints, typography, functions: { pxToRem } }) => ({
              width: "100%",
              height: pxToRem(40),

              "& > div": {
                overflow: pxToRem(4),
              },

              "& input": {
                borderRadius: pxToRem(4),
                background: "#fff",
                width: "100%",
                height: pxToRem(40),
                padding: `0 ${pxToRem(16)} 0px ${pxToRem(14)}`,
                alignItems: "center",
                fontFamily: typography.lexendFont,
                outline: "none",
                backgroundPosition: `right ${pxToRem(13)} center`,
              },

              "& input::-ms-input-placeholder, & input::placeholder": {
                fontFamily: typography.lexendFont,
                color: "#8a8a8a",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "140%",
                letterSpacing: pxToRem(0.175),
              },

              [breakpoints.down(1367)]: {
                height: pxToRem(28),

                "& input": {
                  height: pxToRem(33),
                  fontSize: 12,
                },

                "& input::-ms-input-placeholder, & input::placeholder": {
                  fontSize: 12,
                },
              },
            })}
          />
        </MDBox>
      </MDBox>
      <TableContainer
        data-horizontal-scroll
        sx={{
          boxShadow: "none",
          overflowX: "scroll",
          position: "relative",
          "&::-webkit-scrollbar": {
            width: 6,
            height: 6,
          },

          "&::-webkit-scrollbar-thumb": {
            background: "#8898aa",
            borderRadius: 3,
          },
        }}
      >
        {isLoading ? (
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              height: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              [breakpoints.down(1600)]: {
                height: 550,
              },
            })}
          >
            <Loading style={{ width: 300, height: 200 }} />
          </MDBox>
        ) : isEmpty ? (
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              height: 715,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 48,
              objectFit: "contain",

              [breakpoints.down(1600)]: {
                height: 550,
              },
            })}
            component="img"
            src={NotFound}
          >
            {/* <WarningAmber fontSize="inherit" style={{ color: "#9999" }} />
            <MDTypography sx={{ fontSize: 16, color: "#999999", mt: 2 }}>
              No data found
            </MDTypography> */}
          </MDBox>
        ) : (
          <Table {...getTableProps()} sx={{ maxWidth: "100%" }}>
            <MDBox component="thead">
              {headerGroups.map((headerGroup, key) => (
                <TableRow
                  key={key}
                  {...headerGroup.getHeaderGroupProps()}
                  sx={{ "& .MuiBox-root": { color: "#000000!important", fontSize: 14 } }}
                >
                  {headerGroup.headers.map((column, idx) => (
                    <DataTableHeadCell
                      key={idx}
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </MDBox>
            <TableBody
              {...getTableBodyProps()}
              sx={{ height: 500, "& *": { color: "#000000!important" } }}
            >
              {page.map((row, key) => {
                prepareRow(row);

                return (
                  <>
                    <TableRow
                      key={key}
                      {...row.getRowProps()}
                      sx={{ height: 50, cursor: "pointer" }}
                    >
                      {row.cells.map((cell, idx) => {
                        return (
                          <DataTableBodyCell
                            key={idx}
                            noBorder={noEndBorder && rows.length - 1 === key}
                            align={cell.column.align ? cell.column.align : "left"}
                            hasSchedule={hasSchedule}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </DataTableBodyCell>
                        );
                      })}
                    </TableRow>
                    {hasSchedule && key === showDropdown ? (
                      <>
                        <TableRow sx={{ height: 50 }}>
                          <DataTableBodyCell hasSchedule={hasSchedule}></DataTableBodyCell>
                          <DataTableBodyCell hasSchedule={hasSchedule}></DataTableBodyCell>
                          <DataTableBodyCell hasSchedule={hasSchedule}>
                            <MDBox
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                              gap={1}
                            >
                              <MDBox component="img" src={location}></MDBox>
                              <span>
                                58 Pham Ngoc Thach Street, Vo Thi Sau Ward, District 3, Ho Chi Minh
                                City - 26 October 2024, at 13:45 PM by Mark Johnson
                              </span>
                            </MDBox>
                          </DataTableBodyCell>
                          <DataTableBodyCell hasSchedule={hasSchedule}>
                            <Button
                              sx={{
                                background: "#FFFDD8!important",
                                boxShadow: "0px 2px 6px 0px #00000040",
                              }}
                            >
                              Register
                            </Button>
                          </DataTableBodyCell>
                        </TableRow>
                      </>
                    ) : null}
                  </>
                );
              })}
              <MDBox sx={{ height: 1, width: 1 }}></MDBox>
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="flex-end"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        <Stack spacing={2}>
          <Pagination count={10} color="primary" sx={{ display: "none" }} />
          {/* cần hide, đừng xoá, lỗi của material */}
          <Pagination
            count={totalPage}
            onChange={(e, page) => {
              setCurrentPage(page - 1);
            }}
            page={currentPage + 1}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackIcon,
                  next: ArrowForwardIcon,
                }}
                {...item}
              />
            )}
            sx={{
              padding: "0 !important",

              "& .Mui-selected": {
                background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                color: "white !important",

                "& .MuiTouchRipple-root": {
                  color: "white",
                },
              },

              "&:hover, &:focus, &:active": {
                transform: "none",
                opacity: "1 !important",
              },
            }}
          />
        </Stack>
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: true,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  setClassPageSize: () => {},
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  classPageSize: PropTypes.number,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  currentPage: PropTypes.number,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  totalPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  startDateClass: PropTypes.any,
  endDateClass: PropTypes.any,
  setClassPageSize: PropTypes.func,
};

export default DataTable;
