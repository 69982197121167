import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { rootReducer } from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { api } from "./api";
import { toast } from "react-toastify";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],

  /**
   * Log a warning and show a toast!
   */
};

const errorBlackList = ["refreshToken"];

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  const endpoint = action?.meta?.arg?.endpointName ?? "";
  if (!errorBlackList.includes(endpoint)) {
    if (isRejectedWithValue(action)) {
      toast.error("Internal server error", {
        data: {
          title: "Error toast",
          text: "This is an error message",
        },
      });
    }
  }

  return next(action);
};

const loadingWhiteList = [
  "login",
  "getPerformance",
  "getRenewalRate",
  "getCourseProgress",
  //"getStudentAtRisk",
  "getClassSession",
];

const loadingMiddleware = (store) => (next) => (action) => {
  const endpoint = action?.meta?.arg?.endpointName ?? "";
  if (loadingWhiteList.includes(endpoint)) {
    if (action.type.endsWith("/pending")) {
      store.dispatch({ type: "loading/startLoading" });
    } else if (action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected")) {
      setTimeout(() => {
        store.dispatch({ type: "loading/stopLoading" });
      }, 500);
    }
  }
  return next(action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware, loadingMiddleware),
});

store.__persist = persistStore(store);

export { store };
